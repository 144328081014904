import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddingButton from './AddingButton';
import { useTranslation } from 'react-i18next';


const DialogsRemove = ({
                         style, remover, id, companyId = null, tooltip,
                         position_button, icon: Icon = null, negative_answer, positive_answer,
                         question, question_text, disabled, ...props
                       }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    remover(companyId, id);
    handleClose();
  };

  return (
    <React.Fragment>
      <AddingButton onClick={ handleClickOpen }
                    disabled={ disabled }
                    style={ style }
                    position={ position_button }
                    tooltip={ t(tooltip) }
                    icon={ () => <Icon/> }/>

      <Dialog open={ open }
              onClose={ handleClose }
              maxWidth="xs">
        <DialogTitle>{ t(question) }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { typeof question_text === 'string' ? t(question_text) : question_text }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleClose } color="secondary">
            { t(negative_answer) }
          </Button>
          <Button onClick={ handleRemove } color="primary" autoFocus>
            { t(positive_answer) }
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogsRemove;
