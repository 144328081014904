import React, { Fragment, PureComponent } from 'react';
import ChatMessageList from './ChatMessageList';
import ChatForm from './ChatForm';
import { Grid } from '@material-ui/core';
import { downloadChatDocument, fetchChatBookingMessages, maskReadClientMessages } from '../../actions/chatActions';
import Errors from '../Errors';
import { connect } from 'react-redux';
import { fetchCountClientMessage } from '../../actions/drawerActions';
import { BLOB_TYPE, HIDDEN, TIME_INTERVAL_REQUEST_CHAT, VISIBLE } from '../Constants';
import { getFormatTypeDownloadDocument } from '../Tools';
import fileDownload from 'js-file-download';
import ClientSupportChatLinks from '../ClientSupportChatLinks';

class Chat extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      booking_id: props.booking_id || null,
      messages: [],
      errors: new Errors()
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( Number(prevProps.chat_info?.unread_count) !== Number(this.props.chat_info?.unread_count) ) {
      this.handleMaskAsMessageRead(this.state.messages);
    }

    if ( prevProps.booking_id !== this.state.booking_id ) {
      this.handleClearInterval();
    }
  }

  componentDidMount = () => {
    this.loadMessages();
    this.handleMessageFromInterval();
    this.handleWindowActive();
  };

  componentWillUnmount() {
    this.handleClearInterval();
    document.removeEventListener('visibilitychange', this.handleVisibility);
  }

  handleWindowActive = () => {
    document.addEventListener('visibilitychange', this.handleVisibility);
  };

  handleVisibility = () => {
    if ( document.visibilityState === VISIBLE ) {
      this.handleMessageFromInterval();
    }

    if ( document.visibilityState === HIDDEN ) {
      this.handleClearInterval();
    }
  };

  handleMessageFromInterval = () => {
    this.interval = setInterval(() => {
      this.loadMessages();
    }, TIME_INTERVAL_REQUEST_CHAT);
  };


  handleClearInterval = () => {
    clearInterval(this.interval);
  };

  loadMessages = () => {
    const { booking_id } = this.state;

    fetchChatBookingMessages(booking_id)
      .then(messages => {
        this.setState({
          messages: messages,
        });

        this.handleMaskAsMessageRead(messages);
      });
  };

  handleMaskAsMessageRead = (messages) => {
    const { chat_info } = this.props;

    if ( parseInt(chat_info?.unread_count) > 0 ) {
      maskReadClientMessages({ message_ids: [...messages.filter(x => x.is_admin === false).map(x => x.id)] })
        .then(() => {
          // loadChats();
        })
        .catch(errors => {
          this.setState({ errors: new Errors(errors) });
        });

    }
  };

  downloadDocument = (id, name) => {
    downloadChatDocument(id, { responseType: BLOB_TYPE })
      .then(response => {
        let fileFormat = getFormatTypeDownloadDocument(response.type);
        let clearName = name.split('.')[0];

        fileDownload(response, `${ clearName }.${ fileFormat }`);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSubmit = () => {
    this.loadMessages();
  };


  render() {
    const { chat_info } = this.props;
    const { booking_id, messages } = this.state;

    return (
      <Fragment>
        <Grid item
              xs={ 12 }
              sm={ 8 }
              md={ 6 }
              lg={ 4 }
              style={ {
                // margin: '-16px -16px 0 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                maxHeight: '75vh',
                background: '#FFFFFF',
                width: '100%',
              } }>
          <ChatMessageList messages={ messages }
                           downloadDocument={ this.downloadDocument }/>

          <ChatForm booking_id={ booking_id }
                    onSumit={ this.handleSubmit }/>
        </Grid>

        <Grid item
              xs={ 12 }
              lg={ 2 }>
          <ClientSupportChatLinks chat_info={ chat_info }/>
        </Grid>
      </Fragment>
    );
  }
}

export default connect(null, { fetchCountClientMessage })(Chat);
