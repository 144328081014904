import React, { useState } from 'react';
import MuiTextField from '@material-ui/core/TextField';
// import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ( {
  root: {
    '& .MuiInputBase-multiline': {
      padding: `15px 14px`,
    },
  },
} ));


const PasswordField = ({ label, placeholder, name = 'password', ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);

  let handlePassword = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <MuiTextField
      { ...props }
      className={ classes.root }
      fullWidth
      error={ !!props.error }
      helperText={ props.error || '' }
      InputLabelProps={ {
        shrink: true
      } }
      variant="outlined"
      label={ t(label) }
      placeholder={ t(placeholder) }
      name={ name }
      type={ showPass ? 'text' : 'password' }
      InputProps={ {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label={ t('Показать пароль') }
                        onClick={ handlePassword }
                        tabIndex={ -1 }
            >
              { showPass ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/> }
            </IconButton>
          </InputAdornment>
        ),
      } }
    />
  );
};

export default PasswordField;
