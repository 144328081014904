import { uploadBookingDocument } from '../actions/bookingsActions';
import { LOCALES } from './Constants';

export const uploadGoogleCloudDoc = (id, response, type, format) => {

  let sendFile = new File([response], `Document.${ format }`, { type: type });

  const data = new FormData();

  data.append('type', 4);
  data.append('file', sendFile);

  uploadBookingDocument(id, data)
    .then(() => {
    })
    .catch(error => {
      console.log(error, 'Ошибка загрузки документа в облако, обратитесь в ИТ поддержку');
    });

};


export const getLocale = (language) => {
  switch ( language ) {
    case LOCALES.uk: {
      return LOCALES.uk;
    }
    case LOCALES.ru: {
      return LOCALES.ru;
    }
    default: {
      return LOCALES.ru;
    }
  }
};
