import React from 'react';
import Title from '../Title';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const StyledFormControlLabel = withStyles({
  root: {},
  label: {
    fontSize: 12
  },
})(FormControlLabel);

const RealtyTariffPart = ({ sellable, prices, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className="tariff">
      <Title size="md"
             title="Тарифы"/>
      <div className="tariff__wrapper">
        <div className="tariff__fields">
          <StyledFormControlLabel
            control={
              <Switch
                checked={ sellable }
                name="sale"
                color="primary"
              />
            }
            labelPlacement="end"
            label={ t('Возможность продажи') }
          />
        </div>
        <div className="tariff__prices">
          { prices && prices.map((price, index) => (
            <div key={ index }
                 className="tariff__term">
              <Title size="sm"
                     title={ price.text_rent_type }/>
              <span>
                { price.price }
              </span>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};

export default RealtyTariffPart;
