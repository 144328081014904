import React, { memo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import StatisticCompanyFilter from '../../components/Statistic/StatisticCompanyFilter';
import StatisticCompany from '../../components/StatisticCompany';
import StatisticGroupsContainer from '../../components/StatisticGroups/StatisticGroupsContainer';
import StatisticCompanyTitle from '../../components/Statistic/StatisticCompanyTitle';
import { usePageTitle } from '../../hooks';

const StatisticsListPage = ({ ...props }) => {
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle('Статистика');
  });

  return (
    <Grid container
          spacing={ 2 }>

      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <StatisticCompanyTitle/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item
            xs={ 12 }>
        <StatisticCompany/>
      </Grid>

      <Grid item
            xs={ 12 }>
        <StatisticCompanyFilter/>
      </Grid>


      <Grid item
            xs={ 12 }>
        <StatisticGroupsContainer/>
      </Grid>

    </Grid>
  );
};


export default memo(StatisticsListPage);
