import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StatisticApartment from './index';
import Pagination from '../Pagination';
import { Card, CardHeader, Grid } from '@material-ui/core';
import { fetchStatisticCompanyApartments } from '../../actions/statisticsActions';
import queryString from 'query-string';
import { COMPANY_ID } from '../Constants';
import Loader from '../Loader';

const StatisticApartmentContainer = ({ group_id, ...props }) => {
  const { t } = useTranslation();
  const [apartments, setApartments] = useState(null);
  const [apartmentsMeta, setApartmentsMeta] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  let loadApartment = useCallback(() => {
    const query = queryString.parse(location.search);

    setLoading(true);

    const next_query = {
      ...query,
      page: currentPage,
      company_id: COMPANY_ID,
      group_id: group_id,
    };

    fetchStatisticCompanyApartments(next_query)
      .then((response) => {
        setApartments(response.data);
        setApartmentsMeta(response.meta);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [group_id, currentPage, location.search]);

  useEffect(() => {
    loadApartment();
  }, [loadApartment]);

  let handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  if ( loading ) {
    return <Loader/>;
  }

  if ( !apartments?.length ) {
    return (
      <div className="statistic-apartment-container">
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Card>
              <CardHeader style={ { textAlign: 'center' } }
                          title={ t('Объектов по заданным критериям не найдено') }/>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="statistic-apartment-container">
      { apartmentsMeta && apartments.map(apartment => <StatisticApartment key={ apartment.id }
                                                                          { ...apartment }/>) }

      { apartmentsMeta.last_page > 1 && (
        <Pagination onChange={ handlePageChange }
                    meta={ apartmentsMeta }
                    wrapComponent={ () => <Grid item
                                                xs={ 12 }
                                                style={ {
                                                  paddingTop: `24px`,
                                                  display: `flex`,
                                                  justifyContent: 'center'
                                                } }/> }

        />
      ) }

    </div>
  );
};


export default memo(StatisticApartmentContainer);
