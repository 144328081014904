import React from 'react';
import { Grid } from '@material-ui/core';
import { flexAlignCenter } from '../JSStylesConstants';
import ClearFiltersButton from '../ClearFiltersButton';

const FilterLayout = ({ children }) => {
  return (
    <Grid container
          spacing={ 2 }
          style={ { alignItems: 'center' } }>

      { children }

      <Grid item
            style={ flexAlignCenter }>
        <ClearFiltersButton/>
      </Grid>
    </Grid>
  );
};

export default FilterLayout;
