import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { getColorNotification } from '../Tools';
import { Link } from 'react-router-dom';
import { NOTIFICATION_TYPE } from '../Constants';
import { useTranslation } from 'react-i18next';


const NotificationsRow = ({ notification, ...props }) => {
  const {t} = useTranslation();

  switch ( notification.data?.type ) {
    case ( NOTIFICATION_TYPE.newRequestByRealty ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            { t('Создана новая заявка на объект') } { notification.data?.realty_name } { notification.data?.realty_type }
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    case ( NOTIFICATION_TYPE.needSupport ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            <Link to={ `/clients/${ notification.data?.client_id }` }>
              { notification.data?.client_first_name } { notification.data?.client_last_name }
            </Link>&nbsp;
            { t('с заказом') }
            &nbsp;
            <Link to={ `/bookings/${ notification.data?.booking_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.rental_agreement }
            </Link>
            &nbsp;
            { t('просит помощи по объекту') }
            &nbsp;
            <Link to={ `/realty/${ notification.data?.realty_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.realty_name }
            </Link>
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    case ( NOTIFICATION_TYPE.clientEnterAlMeterDataCorrectly ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            { t('Клиент') }
            &nbsp;
            <Link to={ `/clients/${ notification.data?.client_id }` }>
              { notification.data?.client_first_name } { notification.data?.client_last_name }
            </Link>
            &nbsp;
            { t('внёс корректные показания в заказ') }
            &nbsp;
            <Link to={ `/bookings/${ notification.data?.booking_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.rental_agreement }
            </Link>.
            { t('Разница в единицах по счетчикам:') }
            { notification.data?.basic_utility_name } ({ notification.data?.meter_name })
            - { t('разница в') } { notification.data?.meter_data_diff } { t('ед.') }
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    case ( NOTIFICATION_TYPE.clientWriteMetersDataSuspicious ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            <Link to={ `/clients/${ notification.data?.client_id }` }>
              { notification.data?.client_first_name } { notification.data?.client_last_name }
            </Link>
            { t('внёс нулевые показания за') }
            { notification.data?.basic_utility_name }-{ notification.data?.meter_name }
            &nbsp;
            { t('по заказу') }
            &nbsp;
            <Link to={ `/bookings/${ notification.data?.booking_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </Link>.
            <br/>
            <strong>{ t('Нужно проверить менеджеру!') }</strong>
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    case ( NOTIFICATION_TYPE.errorSendMetersDataToMainCompany ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            { t('Произошла ошибка при передаче показаний в УК') } - { notification.data?.management_company_name }.
            { t('Возможно сейчас не период подачи показаний по счетчику') }
            №{ notification.data?.meter_name } { t('либо счетчик не исправен.') }
            { t('В другом случае проверьте правильность номера счетчика') } №{ notification.data?.meter_name }
            { t('по объекту') }
            &nbsp;
            <Link to={ `/realty/${ notification.data?.realty_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.realty_name }
            </Link>&nbsp;
            { t('и совпадает ли он с номером в ЛК УК.') }
            { t('Если совпадают свяжитесь с отделом ИТ.') }
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    case ( NOTIFICATION_TYPE.clientStaysLongerPaidTime ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            { t('Время аренды объекта') }
            &nbsp;
            <Link to={ `/realty/${ notification.data?.realty_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.realty_name }
            </Link>&nbsp;
            { t('больше чем было оплачено в заказе') }
            &nbsp;
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.rental_agreement }
            </a>.
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    case ( NOTIFICATION_TYPE.clientPassportExpires ):
      return (
        <TableRow hover>
          <TableCell>
            { notification.created_at }
          </TableCell>
          <TableCell style={ { maxWidth: 400, ...getColorNotification(notification.data?.type) } }>
            { t('У клиента') }
            &nbsp;
            <Link to={ `/clients/${ notification.data?.client_id }` }
                  style={ { textDecoration: 'none' } }>
              { notification.data?.client_first_name }&nbsp;{ notification.data?.client_last_name }
            </Link>&nbsp;
            { t('заканчивается срок действия паспорта') }
            &nbsp;
            { notification.data?.passport_validity }.
            { t('Нужно перезаключить с ним договор') }
            &nbsp;
            <a href={ `/realty/${ notification.data?.booking_id }` }
               style={ { textDecoration: 'none' } }>
              №&nbsp;{ notification.data?.rental_agreement }
            </a>.
          </TableCell>
          <TableCell style={ getColorNotification(notification.data?.type) }>
            <a href={ notification.data?.url }
               style={ { textDecoration: 'none' } }>
              { notification.data?.url }
            </a>
          </TableCell>
          <TableCell>
            { notification.data?.responsible_manager }
          </TableCell>
        </TableRow>
      );
    default:
      return null;
  }
};

export default NotificationsRow;
