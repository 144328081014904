import React, { useEffect, useState } from 'react';
import Autocomplete from './Autocomplete';
import { fetchRealtyGroups } from '../../actions/realtyGroupsActions';


const RealtyGroupsAutocomplete = ({ value, ...props }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    fetchRealtyGroups()
      .then(response => {
        setGroups(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Autocomplete { ...props }
                  filterOptions={ (options, state) => options }
                  value={ ( typeof value === 'number' ? groups.find(group => group.id === value) : value ) || null }
                  options={ groups }
                  label={ `Група объектов` }
                  placeholder={ `Выберите групу` }
    />
  );
};

export default RealtyGroupsAutocomplete;
