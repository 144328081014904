import React from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { CLIENTS_ROUTE, MEETINGS_ROUTE, REALTY_LIST_ROUTE } from '../RouterConstants';
import moment from 'moment-timezone';
import ButtonWithProgress from '../ButtonWithProgress';
import { DATE_FORMAT } from '../Constants';

const MeetingsTableRow = ({ meeting, ...props }) => {

  return (
    <TableRow hover>
      <TableCell>{ moment(meeting.datetime).format(DATE_FORMAT.DD_MMM_YYYY_HH_mm) }</TableCell>
      <TableCell>{ meeting.manager?.name }</TableCell>
      <TableCell>
        <Box display={ 'flex' }
             style={ { gap: 8 } }
             flexDirection={ 'column' }>
          <RouterLink to={ `${ CLIENTS_ROUTE }/${ meeting.client?.id }` }
                      style={ { color: 'inherit', textDecoration: 'none' } }>
            <b>{ meeting?.client?.name }</b>
          </RouterLink>
          <a href={ `tel:${ meeting.client?.phone }` }>{ meeting?.client?.phone }</a>
        </Box>
      </TableCell>

      <TableCell>{ meeting.text_type }</TableCell>
      <TableCell>
        { meeting?.realty ?
          <RouterLink to={ `${ REALTY_LIST_ROUTE }/${ meeting.realty?.id }` }
                      style={ { color: 'inherit', textDecoration: 'none' } }>
            <b>{ meeting.realty.name }</b>
          </RouterLink>
          : null
        }

      </TableCell>
      <TableCell>{ meeting.text_status }</TableCell>
      <TableCell>{ meeting.comment }</TableCell>
      <TableCell>
        <RouterLink to={ `${ MEETINGS_ROUTE }/${ meeting.id }` }
                    style={ { textDecoration: 'none' } }>
          <ButtonWithProgress label={ 'Редактировать' }/>
        </RouterLink>
      </TableCell>
    </TableRow>
  );
};

export default MeetingsTableRow;
