import MuiPaper from '../MuiPaper';
import { Box, Typography } from '@material-ui/core';
import ErrorResultsIcon from '../../icons/ErrorResultsIcon';
import MuiButtonLink from '../MuiButtonLink';
import { useTranslation } from 'react-i18next';

const ErrorResultMessage = ({ description = 'Произошла ошибка' }) => {
  const { t } = useTranslation();

  return (
    <MuiPaper sx={ { textAlign: 'center', mt: 2, width: '100%' } }>
      <Box style={ { gap: 16 } }
           display={ 'flex' }
           flexDirection={ 'column' }>
        <Box>
          <ErrorResultsIcon/>
        </Box>
        <Typography variant={ 'subtitle1' }>
          { t(description) }
        </Typography>
        <Box>
          <MuiButtonLink to={ '/' }
                         title={ 'На главную' }/>
        </Box>
      </Box>
    </MuiPaper>
  );
};

export default ErrorResultMessage;
