import React from 'react';
import { Grid } from '@material-ui/core';
import SearchField from '../SearchField';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import PickDateField from '../PickDateField';
import NotificationsTypeSelect from './NotificationsTypeSelect';
import moment from 'moment';
import { DATE_FORMAT } from '../Constants';
import FilterLayout from '../FilterLayout';

const NotificationsFilter = ({ ...props }) => {
  const query = queryString.parse(props.location.search);

  let handleChange = (e) => {
    const { value, name } = e.target;

    const nextQuery = {
      ...query,
      [name]: value
    };

    if ( query.page ) nextQuery.page = 1;

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    });
  };

  let handleChangeDate = (key, value) => {

    const nextQuery = {
      ...query,
      [key]: value ? moment(value, 'DD/MM/YYYY').format(DATE_FORMAT.DD_MM_YYYY) : undefined
    };

    if ( query.page ) nextQuery.page = 1;

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    });
  };

  let getParam = (key) => {
    const query = queryString.parse(props.location.search);


    return query[key] || '';
  };

  return (
    <FilterLayout>
      <Grid item>
        <PickDateField disableToolbar={ false }
                       name="date"
                       type="date"
                       value={ moment(getParam('date'), DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY') }
                       onChange={ handleChangeDate }
                       label="Дата"/>
      </Grid>
      <Grid item>
        <SearchField searchField="realty"
                     label="Поиск по объекту"
                     placeholder="Имя объекта"/>
      </Grid>
      <Grid item>
        <SearchField searchField="client"
                     label="Клиент"
                     placeholder="Имя/телефон/email клиента"/>
      </Grid>

      <Grid item>
        <NotificationsTypeSelect onChange={ handleChange }
                                 name="type"
                                 value={ getParam('type') }
                                 style={ { minWidth: 200 } }/>
      </Grid>
    </FilterLayout>
  );
};

export default withRouter(NotificationsFilter);
