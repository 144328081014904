import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { uuidv4 } from '../../components/Tools';
import PageHeader from '../../components/PageHeader';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import { NOT_FOUND } from '../../components/Constants';
import Pagination from '../../components/Pagination';
import RealtyFeedItem from '../../components/Realty/RealtyFeedItem';
import Loader from '../../components/Loader';
import { useFetch, usePageTitle } from '../../hooks';


const RealtyFeedsListPage = ({ ...props }) => {
  const { id } = useParams();
  const { setPageTitle } = usePageTitle();
  const { data: feeds, loading, meta } = useFetch({
    url: `realty/${ id }/feed`,
    oldResponseFormat: true,
    hasMeta: true
  });

  useEffect(() => {
    setPageTitle('Лента Объекта');
  });


  if ( loading ) return <Loader/>;

  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <PageHeader title="Лента объекта"
                    size="md"/>
      </Grid>

      {/*//todo: for feature*/ }

      {/*<Grid item xs={ 12 }>*/ }
      {/*  <RealtyFeedStatistic/>*/ }
      {/*</Grid>*/ }


      <Grid item xs={ 12 }>
        { feeds &&
          <Paper style={ { padding: 16 } }>
            <ul className="realty-feeds-list">
              { feeds && feeds?.map((item) => (
                <RealtyFeedItem key={ uuidv4() }
                                className="realty-feeds-list__item"
                                { ...item }/>
              )) }
            </ul>
          </Paper>
        }

        { feeds?.length === 0 && <ErrorLoadingDataMessage title={ NOT_FOUND }/> }
      </Grid>


      { ( meta && meta.last_page > 1 ) &&
        <Pagination meta={ meta }
                    wrapComponent={ () => <Grid item xs={ 12 }
                                                style={ {
                                                  paddingTop: `24px`,
                                                  display: `flex`,
                                                  justifyContent: 'center'
                                                } }/> }/>
      }
    </Grid>
  );
};

export default RealtyFeedsListPage;
