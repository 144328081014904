import React, { PureComponent } from 'react';
import { Drawer, withStyles } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';


const styles = theme => ( {
  drawerPaper: {
    height: '100vh',
    padding: 24,
  },
  drawerContent: {
    margin: 'auto',
    maxWidth: 640,
  },
  drawerTitle: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 24,
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 16,
  },
} );

class FormDrawerWithPayload extends PureComponent {


  render() {
    const { classes, isOpen, closeModal, children, t } = this.props;

    return (
      <React.Fragment>
        <Drawer open={ isOpen }
                anchor="top"
                onClose={ () => closeModal() }
                classes={ { paper: classes.drawerPaper } }
        >
          <MuiButton
            startIcon={ <CloseIcon/> }
            onClick={ () => closeModal() }
            className={ classes.closeButton }
          >
            { t('Закрыть') }
          </MuiButton>
          <div className={ classes.drawerContent }>
            { children }
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}


export default withStyles(styles)(withTranslation()(FormDrawerWithPayload));
