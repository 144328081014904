import React from 'react';
import './ClientSupportChatLinks.scss';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ClientSupportChatLinks = ({chat_info, ...props}) => {
  const {t} = useTranslation();

  return (
      <div className="chat-links">
        <Button color="primary"
                className="chat-links__button"
                variant="contained">
          <Link to={ `/clients/${ chat_info.client_id }` }
                target="_blank"
                className="chat-links__link"
                rel="noopener noreferrer">
            { chat_info.client_name }
          </Link>
        </Button>
        <Button color="primary"
                className="chat-links__button"
                variant="contained">
          <Link to={ `/bookings/${ chat_info.booking_id }` }
                target="_blank"
                className="chat-links__link"
                rel="noopener noreferrer">
            { t('Заказ') } №{ chat_info.booking_id }
          </Link>
        </Button>

        <Button color="primary"
                className="chat-links__button"
                variant="contained">
          <Link to={ `/realty/${ chat_info.realty_id }` }
                className="chat-links__link"
                target="_blank"
                rel="noopener noreferrer">
            { chat_info.name }
          </Link>
        </Button>
      </div>
  );
};

export default ClientSupportChatLinks;
