import React, { PureComponent } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getFormatNumber } from '../Tools';
import { withTranslation } from 'react-i18next';

class BalanceSelect extends PureComponent {

  render() {
    const { options, label, handleChange, value, name, error, disabled, fullWidth, t, ...props } = this.props;


    return (
      <React.Fragment>
        <FormControl variant="outlined"
                     fullWidth={ fullWidth }
                     style={ props.style }
                     error={ !!error }>
          <InputLabel id={ label }>
            { t(label) }
          </InputLabel>
          <Select name={ name }
                  disabled={ disabled }
                  labelId={ label }
                  value={ value }
                  onChange={ handleChange }
                  label={ t(label) }>
            { options && options.map((option, index) =>
              <MenuItem key={ index }
                        value={ option }>
                { t(option.name) } ({ getFormatNumber(option.balance) })
              </MenuItem>) }

          </Select>
          { error && <FormHelperText>{ t(error) }</FormHelperText> }
        </FormControl>
      </React.Fragment>
    );
  }
}


export default withTranslation()(BalanceSelect);
