import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import SearchField from '../SearchField';
import StatisticAdvancedFilter from './StatisticAdvancedFilter';
import SelectField from '../SelectField';
import { REALTY_TYPES } from '../SelectConstats';


const StatisticCompanyFilter = ({ company_id, ...props }) => {


  let handleChange = (e) => {
    const { name, value } = e.target;
    const search = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        [name]: value,
      }),
    });

  };

  let getParam = (key) => {
    const search = queryString.parse(props.location.search);

    return search[key] || '';
  };

  return (
    <Grid container
          spacing={ 2 }
          style={ { alignItems: 'center' } }>
      <Grid item>
        <SelectField label={ 'Типы объектов' }
                     options={ REALTY_TYPES }
                     style={ { minWidth: 220 } }
                     onChange={ handleChange }
                     value={ getParam('realtiable_type') }
                     name="realtiable_type"
                     fullWidth/>
      </Grid>
      <Grid item>
        <SearchField fulWidth
                     searchField="name"
                     label="Поиск"
                     placeholder="Введите имя"/>
      </Grid>
      <StatisticAdvancedFilter/>
    </Grid>
  );
};

export default withRouter(StatisticCompanyFilter);
