import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelectField from '../../FilterFields/FilterSelectField';
import { CompaniesFilterSelect } from '../../FilterFields';
import { RENT_SORT_TYPE_OPTIONS, RENT_TYPE_OPTIONS } from '../../SelectConstats';
import PickDateField from '../../PickDateField';
import moment from 'moment/moment';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import FilterLayout from '../../FilterLayout';

const RealtyStatisticFilter = () => {
  const location = useLocation();
  const history = useHistory();

  let handleChangeDate = (key, value) => {
    const query = queryString.parse(location.search);

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        [key]: value ? moment(value, 'DD/MM/YYYY').startOf('day').format('X') : undefined
      })
    });
  };

  let getParam = (params) => {
    const query = queryString.parse(location.search);

    return query[params] || '';
  };

  return (
    <FilterLayout>
      <Grid item>
        <CompaniesFilterSelect/>
      </Grid>

      <Grid item>
        <FilterSelectField options={ RENT_TYPE_OPTIONS }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Тип съема"
                           type="rent_type"/>
      </Grid>


      <Grid item>
        <PickDateField name="date_from"
                       type="date_from"
                       views={ ['year', 'month', 'date'] }
                       disableToolbar={ false }
                       value={ moment(getParam('date_from'), 'X').format('DD/MM/YYYY') }
                       onChange={ (key, value) => handleChangeDate(key, value) }
                       label="Дата от"/>
      </Grid>

      <Grid item>
        <PickDateField name="date_to"
                       type="date_to"
                       views={ ['year', 'month', 'date'] }
                       disableToolbar={ false }
                       disableFuture={ true }
                       value={ moment(getParam('date_to'), 'X').format('DD/MM/YYYY') }
                       onChange={ (key, value) => handleChangeDate(key, value) }
                       label="Дата до"/>
      </Grid>

      <Grid item>
        <FilterSelectField options={ RENT_SORT_TYPE_OPTIONS }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Тип показа Дни\Недели"
                           type="type"/>
      </Grid>
    </FilterLayout>
  );
};

export default RealtyStatisticFilter;
