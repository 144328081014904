import React, {useState} from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Button, Divider, makeStyles, Menu, MenuItem} from '@material-ui/core';
import {logout} from '../actions/authActions';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 16,
    padding: '12px 16px',
    color: '#ffffff',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
}));

const ProfileMenu = ({...props}) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  let handleLogout = () => {
    dispatch(logout());
  };

  if ( !user ) {
    return null;
  }

  return (
      <React.Fragment>
        <Button ref={ button => {
                  setAnchorEl(button);
                } }
                startIcon={ <AccountCircle style={ {fontSize: 24} }/> }
                onClick={ () => setOpen(true) }
                className={ classes.button }
        >
          { user.name }
        </Button>
        <Menu anchorEl={ anchorEl }
              anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'right'
              } }
              getContentAnchorEl={ null }
              open={ open }
              onClose={ () => setOpen(false) }
              keepMounted>
          <MenuItem dense
                    component={ React.forwardRef((props, ref) => <RouterLink
                        to={ `/${ user?.userable_type?.toLowerCase() }s/${ user?.userable_id }` } { ...props }
                        ref={ ref }/>) }>
            { t('Профиль') }
          </MenuItem>
          <Divider/>
          <MenuItem dense
                    onClick={ handleLogout }>
            { t('Выйти') }
          </MenuItem>
        </Menu>
      </React.Fragment>
  );
};


export default ProfileMenu;
