import React, { PureComponent } from 'react';
import SelectField from '../SelectField';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { PERIOD_SELECT_OPTIONS } from '../SelectConstats';


class PeriodSelectField extends PureComponent {

  handleChange = (e) => {
    const { value } = e.target;
    const query = queryString.parse(this.props.location.search);


    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        'date_from': moment(value, 'DD/MM/YYYY').format('X'),
      })
    });

  };

  render() {
    const { date_from } = queryString.parse(this.props.location.search);

    return (
      <SelectField { ...this.props }
                   value={ moment(date_from, 'X').format('DD/MM/YYYY') }
                   label="Период"
                   options={ PERIOD_SELECT_OPTIONS }
                   onChange={ this.handleChange }
      />

    );
  }
}

export default withRouter(PeriodSelectField);
