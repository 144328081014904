import React, { useState } from 'react';
import './BookingDocumentsRow.scss';
import { TableCell, TableRow } from '@material-ui/core';
import { ApproveIcon, DeleteIcon, DownloadIcon, ExclamationIcon, FileIcon, UploadIcon } from '../../icons';
import AddingButton from '../AddingButton';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import { downloadBookingDocument, uploadBookingDocument } from '../../actions/bookingsActions';
import ProgressBar from '../ProgressBar';
import { getFormatTypeDownloadDocument } from '../Tools';
import DialogsRemove from '../DialogsRemove';
import fileDownload from 'js-file-download';
import { ERROR, ONE_HUNDRED_PERCENT, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { useTranslation } from 'react-i18next';

const BookingDocumentsRow = ({
                               booking_name,
                               booking_id,
                               type,
                               title,
                               upload,
                               document,
                               handleRemoveDocument,
                               handlerErrorUpload,
                               ...props
                             }) => {
  const { t } = useTranslation();
  const [percentLoading, setPercent] = useState(null);

  let handleDownloadDocument = (docId, doc_type) => {

    let config = {
      onDownloadProgress: progressEvent => {
        let percentCompleted = Math.round(( progressEvent.loaded * ONE_HUNDRED_PERCENT ) / progressEvent.total);

        if ( percentCompleted < ONE_HUNDRED_PERCENT ) {
          setPercent(percentCompleted);
        } else {
          setPercent(null);
        }
      }
    };

    downloadBookingDocument(booking_id, docId, config)
      .then(response => {
        let fileFormat = getFormatTypeDownloadDocument(response.data.type);
        fileDownload(response.data, `${ booking_name }-${ doc_type }.${ fileFormat }`);
      });
  };


  let handleUploadFile = (file, type) => {
    const data = new FormData();

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(( progressEvent.loaded * ONE_HUNDRED_PERCENT ) / progressEvent.total);


        if ( percentCompleted < ONE_HUNDRED_PERCENT ) {
          setPercent(percentCompleted);
        } else {
          setPercent(null);
        }
      }
    };

    if ( type ) {
      data.append('type', type.replace('type_', ''));
    }

    data.append('file', file[0].src.file);

    uploadBookingDocument(booking_id, data, config)
      .then(() => {
        props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.documents.upload.success);
        props.loadDocuments();
      }).catch(errors => {
      props.openSnackbar(ERROR, errors.file || errors);
    });
  };


  return (
    <TableRow className="booking-document">

      { upload &&
        document.map(doc => (
            <React.Fragment key={ doc.id }>
              <TableCell>
                <div className="booking-document__table-cell">
                  <div className="booking-document__icon booking-document__icon--success">
                    <ApproveIcon/>
                  </div>
                  <div className="booking-document__type booking-document__type--success">
                    { t(title) }
                  </div>
                </div>

              </TableCell>
              <TableCell>
                <div className="booking-document__table-cell">
                  <div className="booking-document__name-icon">
                    <FileIcon/>
                  </div>
                  <div className="booking-document__name">
                    { t(doc.name) }
                  </div>
                </div>
              </TableCell>
              <TableCell>{ doc.user.name }</TableCell>
              <TableCell>{ doc.created_at }</TableCell>
              <TableCell>
                <div className="booking-document__table-cell">
                  <AddingButton icon={ () => <DownloadIcon/> }
                                tooltip={ `${ t('Скачать') } ${ title }` }
                                disabled={ !doc.can_be_downloaded || ( percentLoading > 1 && percentLoading < ONE_HUNDRED_PERCENT ) }
                                style={ {
                                  marginRight: 10,
                                  cursor: !doc.can_be_downloaded ? 'default' : 'pointer'
                                } }
                                onClick={ () => handleDownloadDocument(doc.id, title) }/>

                  <DialogsRemove icon={ () => <DeleteIcon/> }
                                 remover={ handleRemoveDocument }
                                 negative_answer={ 'Нет' }
                                 positive_answer={ 'Да' }
                                 question={ 'Удалить документ?' }
                                 question_text={ `${ t( 'Действительно удалить' ) } ${ t(title) }?` }
                                 tooltip={ `${ t('Удалить') } ${ title }` }
                                 id={ doc.id }/>
                </div>
                { ( percentLoading && percentLoading < ONE_HUNDRED_PERCENT ) && (
                  <ProgressBar percent={ percentLoading }
                               style={ {
                                 minWidth: '75px',
                                 marginTop: 16
                               } }
                  />
                ) }
              </TableCell>
            </React.Fragment>
          )
        )
      }

      { !upload &&
        <React.Fragment>
          <TableCell>
            <div className="booking-document__table-cell">
              <div className="booking-document__icon booking-document__icon--error">
                <ExclamationIcon/>
              </div>
              <div className="booking-document__type">
                { t(title) }
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className="booking-document--empty">
              { t('Нет документа') }
            </div>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <UploadFilesButton handleChange={ file => handleUploadFile(file, type) }
                               handleError={ handlerErrorUpload }
                               component={ props => <AddingButton { ...props }
                                                                  tooltip={ `${ t('Загрузить') } ${ title }` }
                                                                  icon={ () => <UploadIcon/> }/> }/>
            { ( percentLoading && percentLoading < ONE_HUNDRED_PERCENT ) && (
              <ProgressBar percent={ percentLoading }
                           style={ {
                             minWidth: '75px',
                             marginTop: 16
                           } }/>
            ) }
          </TableCell>
        </React.Fragment>

      }


    </TableRow>
  );
};

export default connect(null, { openSnackbar })(BookingDocumentsRow);
