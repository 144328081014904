import React from 'react';
import FilterSelectField from '../FilterFields/FilterSelectField';
import {MEETINGS_TYPES} from '../SelectConstats';

const MeetingTypeFilterSelectField = ({label, ...props}) => {
  return (
      <FilterSelectField options={ MEETINGS_TYPES }
                         fullWidth
                         style={ {minWidth: 220} }
                         label="Тип встречи"
                         type="type"/>
  );
};

export default MeetingTypeFilterSelectField;
