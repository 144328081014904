import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ( {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
} ));
const ButtonWithProgress = ({
                              loading,
                              disabled,
                              label,
                              onClick,
                              color = 'primary',
                              variant = 'contained',
                              type = 'submit',
                              ...props
                            }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={ classes.wrapper }
         style={ { width: props.fullWidth ? '100%' : 'auto' } }>
      <Button type={ type }
              variant={ variant }
              color={ color }
              disabled={ disabled || loading }
              { ...props }
              onClick={ disabled ? null : onClick }>
        { t(label) }
      </Button>
      { loading && <CircularProgress size={ 24 }
                                     className={ classes.buttonProgress }/> }
    </div>
  );
};

export default ButtonWithProgress;
