import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ClientEditForm from '../../components/Client/ClientEditForm';
import { Card, CardHeader, Divider, Tab, Tabs } from '@material-ui/core';
import { fetchClient } from '../../actions/clientsActions';
import { TabContext, TabPanel } from '@material-ui/lab';
import ClientDetailsEditForm from '../../components/Client/ClientDetailsEditForm';
import { setPageTitle } from '../../components/Tools';
import { BOOKING_REQUESTS_ROUTE } from '../../components/RouterConstants';

class ClientEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      client: '',
      active_tab: 0
    };
  }

  componentDidMount() {
    setPageTitle('Редактирование клиента', this.props.t);
    this.getClient();
  }

  handleChangeTab = (tabNumber) => {

    this.setState({
      active_tab: tabNumber
    });
  };


  getClient = () => {
    const { match: { params } } = this.props;

    fetchClient(params.id)
      .then((client) => {
        this.setState({
          client: client
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { client, active_tab } = this.state;
    const { t } = this.props;

    if ( !client ) {
      return null;
    }

    return (
      <React.Fragment>
        <Card>
          <CardHeader
            title={ t('Редактирование клиента') }
            subheader={ `ID: ${ client.id }` }
          />

          <Divider/>

          <Tabs textColor="primary"
                indicatorColor="primary"
                value={ active_tab }>
            <Tab label={ t('Общая информация') }
                 onClick={ () => this.handleChangeTab(0) }/>

            <Tab label={ t('Список заказов') }
                 component={ Link }
                 to={ {
                   pathname: '/bookings',
                   search: `client=${ client.first_name } ${ client.last_name }`
                 } }/>

            <Tab label={ t('Реквизиты') }
                 onClick={ () => this.handleChangeTab(2) }/>


            <Tab label={ t('Встречи') }
                 component={ Link }
                 to={ {
                   pathname: '/meetings',
                   search: `client=${ client.first_name } ${ client.last_name }`
                 } }
                 style={ { textDecoration: 'none' } }
                 target="_blank"
                 textColor="primary"/>


            <Tab label={ t('Заявки') }
                 component={ Link }
                 to={ {
                   pathname: BOOKING_REQUESTS_ROUTE,
                   search: `name=${ client.first_name } ${ client.last_name }`
                 } }
                 style={ { textDecoration: 'none' } }
                 target="_blank" textColor="primary"/>


          </Tabs>

          <Divider style={ { marginTop: -1 } }/>


          <div style={ { paddingTop: 24, paddingBottom: 24 } }>
            <TabContext value={ active_tab.toString() }>
              <TabPanel value={ active_tab.toString() }
                        hidden={ active_tab !== 0 }
                        index={ 0 }>
                <ClientEditForm client={ client }/>
              </TabPanel>
              <TabPanel value={ active_tab.toString() }
                        hidden={ active_tab !== 2 }
                        index={ 2 }>
                <ClientDetailsEditForm client={ client }/>
              </TabPanel>
            </TabContext>

          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ClientEditPage);
