import React from 'react';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import PhoneInput from '../PhoneInput';


const ClientDetailsEntityFields = ({ entity, errors, handleEntityChange, ...props }) => {
  return (
    <>
      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('title') }
                     name="title"
                     value={ entity.title }
                     onChange={ handleEntityChange }
                     label="Название"
                     placeholder="Введите название"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('inn') }
                     name="inn"
                     value={ entity.inn }
                     onChange={ handleEntityChange }
                     label="ИНН"
                     placeholder="Введите ИНН"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('kpp') }
                     name="kpp"
                     value={ entity.kpp }
                     onChange={ handleEntityChange }
                     label="КПП"
                     placeholder="Введите КПП"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('ogrn') }
                     name="ogrn"
                     value={ entity.ogrn }
                     onChange={ handleEntityChange }
                     label="ОГРН"
                     placeholder="Введите ОГРН"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('place_of_registration') }
                     name="place_of_registration"
                     value={ entity.place_of_registration }
                     onChange={ handleEntityChange }
                     label="Адрес регистрации"
                     placeholder="Введите адрес регистрации"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('mailing_address') }
                     name="mailing_address"
                     value={ entity.mailing_address }
                     onChange={ handleEntityChange }
                     label="Почтовый адрес"
                     placeholder="Введите почтовый адрес"
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('bank_account') }
                     name="bank_account"
                     value={ entity.bank_account }
                     onChange={ handleEntityChange }
                     label="Расчетный счет"
                     placeholder="Введите расчетный счет"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('bank_title') }
                     name="bank_title"
                     value={ entity.bank_title }
                     onChange={ handleEntityChange }
                     label="Наименование банка"
                     placeholder="Введите наименование банка"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('bank_bic') }
                     name="bank_bic"
                     value={ entity.bank_bic }
                     onChange={ handleEntityChange }
                     label="БИК банка"
                     placeholder="Введите БИК банка"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('correspondent_account') }
                     name="correspondent_account"
                     value={ entity.correspondent_account }
                     onChange={ handleEntityChange }
                     label="Корр счет"
                     placeholder="Введите корр счет"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('head_position') }
                     name="head_position"
                     value={ entity.head_position }
                     onChange={ handleEntityChange }
                     label="Должность руководителя"
                     placeholder="Введите должность руководителя"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('head_full_name') }
                     name="head_full_name"
                     value={ entity.head_full_name }
                     onChange={ handleEntityChange }
                     label="ФИО руководителя"
                     placeholder="Введите ФИО руководителя"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('acts_on') }
                     name="acts_on"
                     value={ entity.acts_on }
                     onChange={ handleEntityChange }
                     label="Действует на основании"
                     placeholder="Введите номер документа"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('email') }
                     name="email"
                     value={ entity.email }
                     onChange={ handleEntityChange }
                     label="Электронная почта"
                     placeholder="Введите email"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <PhoneInput error={ errors.getError('phone') }
                      name="phone"
                      type="phone"
                      onChange={ handleEntityChange }
                      value={ entity.phone }
                      label="Номер телефона"
                      placeholder="Введите номер телефона"/>
        </FormColumn>
      </FormRow>
    </>
  );
};

export default ClientDetailsEntityFields;
