import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import { openSnackbar } from '../../actions/snackbarActions';
import { fetchBookings, getBookingsTotalDeposit } from '../../actions/bookingsActions';
import { Link as RouterLink } from 'react-router-dom';
import BookingFilter from '../../components/Booking/BookingFilter';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { PlusIcon } from '../../icons';
import AddingButton from '../../components/AddingButton';
import { MODERATOR } from '../../components/Constants';
import { getFormatNumber, setPageTitle } from '../../components/Tools';
import Errors from '../../components/Errors';
import BookingTableRow from '../../components/Booking/BookingTableRow';
import { withTranslation } from 'react-i18next';
import TableTemplate from '../../components/TableTemplate';
import { BOOKINGS_HEADERS } from '../../components/TableConstants';


class BookingsListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      meta: null,
      bookings: null,
      total_deposit: null,
      errors: new Errors(),
      loading: false
    };
  }

  componentDidMount = () => {
    setPageTitle('Заказы', this.props.t);
    this.setDefaultFilter();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadBookings();
    }
  }

  getTotalDeposits = () => {
    getBookingsTotalDeposit()
      .then(response => {
        this.setState(prevState => ( {
          ...prevState,
          total_deposit: response.total_deposit
        } ));
      })
      .catch(errors => {
        this.setState(prevState => ( {
          ...prevState,
          errors: new Errors(errors)
        } ));
      });
  };


  loadBookings = () => {
    const { perPage } = this.state;
    const query = queryString.parse(this.props.location.search);


    this.setState((prev) => ( {
      ...prev,
      loading: true
    } ));
    fetchBookings({ ...query, limit: perPage })
      .then((response) => {
        this.setState({
          bookings: response.data,
          meta: response.meta,
        });
      })
      .finally(() => {
        this.setState((prev) => ( {
          ...prev,
          loading: false
        } ));
      });
  };

  setDefaultFilter = () => {
    const search = queryString.parse(this.props.location.search);

    if ( Object.keys(search).length > 0 ) {
      this.loadBookings();
    } else {
      this.props.history.push({
        pathname: window.location.pathname,
        search: queryString.stringify({
          ...search,
          sort: 'departure_date',
          direction: 'desc',
          status: 1
        }),
      });
    }

    this.getTotalDeposits();

  };


  setTotalDepositInTableHeaders = () => {
    return BOOKINGS_HEADERS.map((x) => x.name === 'deposit' ? {
      label: [this.props.t('Депозит'), <br key={ 'total-deposit' }/>, getFormatNumber(this.state.total_deposit)]
    } : x);
  };


  render() {
    const { bookings, meta, loading } = this.state;
    const { user } = this.props;


    return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <Grid container
                spacing={ 2 }
                style={ { alignItems: 'center' } }>
            <Grid item>
              <PageHeader title="Заказы"
                          size="md"/>
            </Grid>
            { ( user.userable_type !== MODERATOR ) &&
              <Grid item>
                <RouterLink to={ '/bookings/new' }
                            style={ { textDecoration: 'none' } }>
                  <AddingButton tooltip="Создать заказ"
                                icon={ () => <PlusIcon/> }/>
                </RouterLink>
              </Grid>
            }

          </Grid>
        </Grid>

        <Grid item
              xs={ 12 }>
          <BookingFilter/>
        </Grid>

        <Grid item
              xs={ 12 }>
          <TableTemplate data={ bookings }
                         meta={ meta }
                         loading={ loading }
                         headers={ this.setTotalDepositInTableHeaders() }>
            { bookings && bookings.map((booking) => <BookingTableRow key={ booking.id } booking={ booking }/>) }
          </TableTemplate>
        </Grid>

      </Grid>
    );
  }
}

let mapStateToProps = (state) => ( {
  user: state.auth.user
} );


export default connect(mapStateToProps, { openSnackbar })(withTranslation()(BookingsListPage));
