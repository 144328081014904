import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

const Autocomplete = (props) => {
  const { t } = useTranslation();

  let optionLabel = (option, doubleLabel = false) => {
    if ( doubleLabel ) {
      return `${ option.name } (${ option.phone })`;
    } else {
      return option.name;
    }
  };

  return (
    <MuiAutocomplete style={ props.style }
                     disabled={ props.disabled }
                     multiple={ props.multiple }
                     filterOptions={ props.filterOptions }
                     filterSelectedOptions={ props.filterSelectedOptions }
                     limitTags={ props.limitTags }
                     options={ props.options }
                     value={ props.value }
                     onInputChange={ props.onInputChange }
                     inputValue={ props.inputValue }
                     onChange={ props.onChange }
                     noOptionsText={ t('Не найдено') }
                     getOptionLabel={ option => optionLabel(option, props.doubleLabel) || '' }
                     renderOption={ option => <Typography style={ { fontSize: 14 } }>
                       { optionLabel(option, props.doubleLabel) }
                     </Typography> }

                     renderInput={ params => (
                       <TextField
                         { ...params }
                         fullWidth
                         error={ !!props.error }
                         helperText={ props.error || '' }

                         label={ t(props.label) }
                         placeholder={ t(props.placeholder) }

                         InputLabelProps={ {
                           shrink: true
                         } }
                         variant="outlined"
                       />
                     ) }
    />
  );
};

export default Autocomplete;
