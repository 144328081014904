import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SelectWithCategoriesField = ({
                                     subMenuName, label, onChange, value, options, name, error, disabled, fullWidth,
                                     disabledMainOption = false, mergedNestedValue = false, ...props
                                   }) => {
  const {t} = useTranslation();

  const renderSelectGroup = (product) => {
    const items = product[subMenuName]?.map((p) => {

      return (
        <MenuItem key={ p.label }
                  style={ { paddingLeft: '24px' } }
                  value={ mergedNestedValue ? `${ p.value },${ product.value }` : p.value }>
          { t(p.label) }
        </MenuItem>
      );
    });

    return [<MenuItem key={ product.label }
                      disabled={ disabledMainOption }
                      style={ { fontWeight: 'bold' } }
                      value={ product.value }>
      { t(product.label) }
    </MenuItem>, items];
  };

  return (
    <React.Fragment>
      <FormControl variant="outlined"
                   fullWidth={ fullWidth }
                   style={ props.style }
                   error={ !!error }>
        <InputLabel id={ label }>
          { t(label) }
        </InputLabel>
        <Select name={ name }
                disabled={ disabled }
                labelId={ label }
                value={ value ?? '' }
                onChange={ onChange }
                label={ t(label) }
                { ...props }>
          { options && options?.map((option) => renderSelectGroup(option)) }

        </Select>
        { error && <FormHelperText>{ error }</FormHelperText> }
      </FormControl>
    </React.Fragment>
  );
};

export default SelectWithCategoriesField;
