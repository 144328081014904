import React, { PureComponent } from 'react';
import Autocomplete from './Autocomplete';
import { fetchCompanies } from '../../actions/autocompleteActions';

class CompaniesAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      companies: []
    };
  }

  componentDidMount() {
    fetchCompanies().then(companies => {
      this.setState({
        companies: companies
      });
    });
  }

  render() {
    const { companies } = this.state;
    const { value, ...props } = this.props;

    return (
      <Autocomplete
        { ...props }
        filterOptions={ (options, state) => options }
        value={ ( typeof value === 'string' ? companies.find(company => company.id === value) : value ) || null }
        options={ companies }
        label={ `Компания` }
        placeholder={ `Выберите компанию` }
      />
    );
  }
}

// CampaignAutocomplete.propTypes = {
//   error: PropTypes.array,
//   onChange: PropTypes.func.isRequired,
// };

export default CompaniesAutocomplete;
