import React from 'react';
import MomentFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PickPeriodField = ({
                           defaultDateFrom, value, onChange, name, label,
                           type, disabled, views, minDate, maxDate, disableToolbar = true, openTo, ...props
                         }) => {
  const { t } = useTranslation();
  let dateFrom;

  if ( /\d{2}\.\d{4}/.test(value) ) {
    [dateFrom] = value.split('-');

    dateFrom = moment(dateFrom, 'MM.YYYY');
  } else {
    dateFrom = defaultDateFrom;
  }

  return (
    <MuiPickersUtilsProvider utils={ MomentFnsUtils }>
      <Grid item xs={ 12 }>
        <DatePicker views={ views }
                    openTo={ openTo }
                    autoOk
                    fullWidth
                    disabled={ disabled }
                    disableToolbar={ disableToolbar }
                    minDate={ minDate || moment().subtract(2, 'year').startOf('day').toDate() }
                    maxDate={ maxDate || moment().add(2, 'year').startOf('day').toDate() }
                    variant="inline"
                    inputVariant="outlined"
                    format="MM.YYYY"
                    value={ dateFrom ? dateFrom.toDate() : null }
                    onChange={ value => onChange(type, moment(value).format('MM.YYYY')) }
                    name={ name }
                    label={ t(label) }
                    error={ !!props.error }
                    helperText={ props.error || '' }
                    InputProps={ {
                      endAdornment: <InsertInvitationIcon/>
                    } }
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default PickPeriodField;
