import axios from 'axios';

export const createMeeting = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/meetings`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const getMeeting = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/meetings/${ id }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const updateMeeting = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/meetings/${ id }`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};
