import React from 'react';
import { Paper } from '@material-ui/core';

const MuiPaper = ({sx, children, ...props }) => {
  return (
    <Paper sx={ { p: '20px', ...sx } } { ...props }>
      { children }
    </Paper>
  );
};

export default MuiPaper;
