import React from 'react';
import SelectField from '../SelectField';
import { requestType } from '../SelectConstats';


const RequestTypeSelect = ({ ...props }) => {
  return (
    <React.Fragment>
      <SelectField options={ requestType }
                   fullWidth
                   label="Тип заявки"
                   { ...props }/>
    </React.Fragment>
  );
};

export default RequestTypeSelect;
