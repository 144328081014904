import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import Errors from '../Errors';
import PageHeader from '../PageHeader';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import TextField from '../TextField';
import AddingButton from '../AddingButton';
import { MinusIcon, PlusIcon } from '../../icons';
import { createBookingsDeposit, createBookingsPayments, payBookingsPayments } from '../../actions/bookingsActions';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import PaydeskPaymentSelect from '../Paydesk/PaydeskBalanceSelect';
import SwitchField from '../SwitchField';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import { BALANCE_TYPE, BOOKING_STATUS, DEPOSIT_STATUS, MANAGER, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { flex } from '../JSStylesConstants';
import ButtonWithProgress from '../ButtonWithProgress';
import { withTranslation } from 'react-i18next';


class BookingMoneyForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: '',
        amount: '',
        comment: '',
        debts: [],
        paid: false,
        counter: '',
        manager: '',


        depositComment: '',
        depositAmount: '',
        depositBackComment: '',
        depositBackAmount: '',
        deposits: [],
        balance: ''
      },
      addDeposit: false,
      removeDeposit: false,
      addDebt: false,
      loadingDebts: false,
      loadingDeposit: false,
      errors: new Errors()
    };
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.booking !== this.props.booking ) {
      this.setBooking(this.props.booking);
    }
  }

  componentDidMount() {
    this.setBooking(this.props.booking);
    this.setDefaultManager();
  }

  setBooking = ({ id, deposit_actions, debt_payments, status }) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        id: id,
        deposits: deposit_actions,
        debts: debt_payments,
        status: status,
      },
    } ));
  };

  setDefaultManager = () => {
    const { user } = this.props;

    if ( user.userable_type === MANAGER ) {
      this.setState(prevState => ( {
        booking: {
          ...prevState.booking,
          manager: { id: user.userable_id, name: user.name }
        }
      } ));
    }
  };

  handleChangeManager = (e, value) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        manager: value
      }
    } ));
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };


  handleDebts = () => {
    const { booking } = this.state;

    this.setState({ loadingDebts: true });

    const data = {
      booking_id: booking.id,
      amount: booking.amount,
      action: DEPOSIT_STATUS.fine,//todo:  штраф = создание долга ?
      paid: booking.paid,
      balance_id: booking.balance.id,
      manager_id: booking.manager.id
    };

    createBookingsPayments(booking.id, data)
      .then(() => {
        this.setState(prevState => ( {
          booking: {
            ...prevState.booking,
            comment: '',
            amount: '',
          }
        } ));
        this.props.loadBooking();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.debt.create.success);
        this.setState({ errors: new Errors() });
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      })
      .finally(() => {
        this.setState({ loadingDebts: false });
      });
  };


  handleDeposit = () => {
    const { booking } = this.state;

    this.setState({ loadingDeposit: true });

    const data = {
      balance_id: booking.balance.id,
      amount: booking.depositAmount,
      action: DEPOSIT_STATUS.replenishment,
      comment: booking.depositComment,
    };


    createBookingsDeposit(booking.id, data)
      .then(deposit => {
        this.setState(prevState => ( {
          booking: {
            ...prevState.booking,
            deposits: [...prevState.booking.deposits, deposit],
            depositComment: '',
            depositAmount: '',
          },
          addDeposit: false
        } ));
        this.props.loadBooking();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.deposit.create.success);
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      })
      .finally(() => {
        this.setState({ loadingDeposit: false });
      });
  };


  handleDepositRemove = () => {
    const { booking } = this.state;

    const data = {
      balance_id: booking.balance.id,
      amount: booking.depositBackAmount,
      action: DEPOSIT_STATUS.refund,
      comment: booking.depositBackComment
    };

    createBookingsDeposit(booking.id, data)
      .then(deposit => {
        this.setState(prevState => ( {
          booking: {
            ...prevState.booking,
            deposits: [...prevState.booking.deposits, deposit],
            depositBackComment: '',
            depositBackAmount: '',
          },
          removeDeposit: false
        } ));
        this.props.loadBooking();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.deposit.create.off);
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      });
  };


  handleAddDeposit = () => {
    this.setState({
      addDeposit: !this.state.addDeposit,
      removeDeposit: false
    });
  };

  handleAddDebt = () => {
    this.setState({
      addDebt: !this.state.addDebt,
    });
  };

  handleRemoveDeposit = () => {
    this.setState({
      addDeposit: false,
      removeDeposit: !this.state.removeDeposit
    });
  };

  handleRemoveDebts = (debts) => {
    const { booking } = this.state;

    payBookingsPayments(booking.id, debts.id, { balance_id: BALANCE_TYPE.banking })
      .then(() => {
        this.props.loadBooking();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.debt.create.repaid);
      });
  };


  render() {
    const { booking, errors, addDeposit, removeDeposit, addDebt } = this.state;
    const { t } = this.props;

    return (
      <Grid container
            spacing={ 2 }>
        <Grid item xs={ 12 }>
          <PageHeader title="Деньги заказа"
                      size="md"/>
        </Grid>
        <Grid item xs={ 12 }>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 4 }
                          mg={ 3 }
                          lg={ 2 }
                          style={ {
                            display: 'flex',
                            alignItems: 'center'
                          } }>
                <Typography style={ { fontSize: '20px' } }>
                  { t('Долги') }
                </Typography>

                <AddingButton onClick={ this.handleAddDebt }
                              disabled={ booking.status === BOOKING_STATUS.close }
                              position="right"
                              icon={ () => <PlusIcon/> }/>
              </FormColumn>
            </FormRow>

            { addDebt &&
              <FormRow>
                <FormColumn sm={ 2 }>
                  <ManagersAutocomplete label={ `Менеджер` }
                                        value={ booking.manager }
                                        onChange={ this.handleChangeManager }/>
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <TextField
                    error={ errors.getError('amount') }
                    name="amount"
                    type="number"
                    value={ booking.amount }
                    onChange={ this.handleChange }
                    label="Сумма задолженности"
                    placeholder="Введите сумму"
                  />
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <TextField
                    error={ errors.getError('comment') }
                    name="comment"
                    value={ booking.comment }
                    onChange={ this.handleChange }
                    label="Комментарий"
                    placeholder="Введите комментарий"
                  />
                </FormColumn>

                { booking.paid &&
                  <FormColumn sm={ 2 }>
                    <PaydeskPaymentSelect handleChange={ this.handleChange }
                                          name="balance"
                                          label="Баланс"
                                          fullWidth
                                          error={ errors.getError('balance_id') }
                                          value={ booking.balance }/>
                  </FormColumn>
                }

                <FormColumn sm={ 2 }
                            style={ flex }>
                  <SwitchField error={ errors.getError('paid') }
                               name="paid"
                               onChange={ this.handleChange }
                               value={ booking.paid }
                               label="Оплачен"
                  />
                </FormColumn>


                <FormColumn sm={ 2 }
                            style={ {
                              display: 'flex',
                              alignItems: 'center'
                            } }>
                  <ButtonWithProgress
                    label={ 'Добавить' }
                    loading={ this.state.loadingDebts }
                    onClick={ this.handleDebts }/>
                </FormColumn>
              </FormRow>
            }

            { booking.debts.length > 0 &&
              <FormRow>
                <FormColumn sm={ 12 }>
                  <Paper variant="outlined"
                         square>
                    <TableContainer style={ {
                      maxWidth: '100%',
                      overflow: 'auto'
                    } }>
                      <Table>
                        <TableBody>
                          { booking.debts.map(debt => (
                            <TableRow key={ debt.id }>
                              <TableCell>{ debt.created_at }</TableCell>
                              <TableCell>{ debt.amount }</TableCell>
                              <TableCell>{ debt.comment }</TableCell>
                              <TableCell>
                                { !debt.paid
                                  ? <Button color="primary"
                                            style={ { marginLeft: 'auto' } }
                                            variant="contained"
                                            onClick={ () => this.handleRemoveDebts(debt) }>
                                    { t('Погасить') }
                                  </Button>
                                  : <Typography color="primary">{ t('Оплачен') }</Typography>
                                }
                              </TableCell>

                            </TableRow>
                          )) }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </FormColumn>
              </FormRow>
            }

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }
                          style={ {
                            display: 'flex',
                            alignItems: 'center'
                          } }>

                <Typography style={ { fontSize: '20px' } }>
                  { t('Депозит') }
                </Typography>
                <AddingButton onClick={ this.handleAddDeposit }
                              position="right"
                              disabled={ booking.status === BOOKING_STATUS.close }
                              icon={ () => <PlusIcon/> }/>
                <AddingButton onClick={ this.handleRemoveDeposit }
                              position="right"
                              disabled={ booking.status === BOOKING_STATUS.close }
                              icon={ () => <MinusIcon/> }/>
              </FormColumn>
            </FormRow>

            { addDeposit &&
              <FormRow>
                <FormColumn sm={ 2 }>
                  <TextField
                    error={ errors.getError('depositAmount') }
                    name="depositAmount"
                    type="number"
                    value={ booking.depositAmount }
                    onChange={ this.handleChange }
                    label="Сумма"
                    placeholder="Введите сумму"
                  />
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <PaydeskPaymentSelect handleChange={ this.handleChange }
                                        name="balance"
                                        label="Баланс"
                                        fullWidth
                                        error={ errors.getError('balance_id') }
                                        value={ booking.balance }/>
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <TextField
                    error={ errors.getError('depositComment') }
                    name="depositComment"
                    value={ booking.depositComment }
                    onChange={ this.handleChange }
                    label="Комментарий"
                    placeholder="Введите комментарий"
                  />
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <ButtonWithProgress
                    label={ 'Добавить' }
                    loading={ this.state.loadingDeposit }
                    onClick={ this.handleDeposit }/>

                </FormColumn>
              </FormRow>
            }

            { removeDeposit &&
              <FormRow>
                <FormColumn sm={ 2 }>
                  <TextField error={ errors.getError('amount') }
                             type="number"
                             name="depositBackAmount"
                             value={ booking.depositBackAmount }
                             onChange={ this.handleChange }
                             label="Сумма"
                             placeholder="Введите сумму"
                  />
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <PaydeskPaymentSelect handleChange={ this.handleChange }
                                        name="balance"
                                        label="Баланс"
                                        fullWidth
                                        error={ errors.getError('balance_id') }
                                        value={ booking.balance }/>
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <TextField error={ errors.getError('comment') }
                             name="depositBackComment"
                             value={ booking.depositBackComment }
                             onChange={ this.handleChange }
                             label="Комментарий"
                             placeholder="Введите комментарий"
                  />
                </FormColumn>
                <FormColumn sm={ 2 }>
                  <ButtonWithProgress
                    label={ 'Списать' }
                    onClick={ this.handleDepositRemove }/>
                </FormColumn>
              </FormRow>
            }

            <FormRow>
              <FormColumn sm={ 12 }>

                { booking.deposits.length > 0 &&
                  <Paper variant="outlined"
                         square>
                    <TableContainer style={ {
                      maxWidth: '100%',
                      overflow: 'auto'
                    } }>
                      <Table>
                        <TableBody>
                          { booking.deposits.map((deposit) => (
                            <TableRow key={ deposit.id }>
                              <TableCell>{ deposit.created_at }</TableCell>
                              <TableCell>{ deposit.amount }</TableCell>
                              <TableCell>{ deposit?.payment?.balance?.name }</TableCell>
                              <TableCell>{ deposit.action }</TableCell>
                              <TableCell>{ deposit.comment }</TableCell>
                            </TableRow>
                          )) }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                }


              </FormColumn>
            </FormRow>

          </FormContainer>
        </Grid>
      </Grid>

    );
  }
}

let mapStateToProps = (state) => ( {
  user: state.auth.user
} );

export default connect(mapStateToProps, { openSnackbar })(withTranslation()(BookingMoneyForm));
