import React from 'react';
import SelectField from '../SelectField';
import { REALTY_STATUS } from '../Constants';

const statusOptions = [
  { value: REALTY_STATUS.free, name: 'Свободно' },
  { value: REALTY_STATUS.booked, name: 'Забронировано' },
  { value: REALTY_STATUS.engaged, name: 'Занято' },
  { value: REALTY_STATUS.cleaning, name: 'Требуется уборка' },
  { value: REALTY_STATUS.partialRent, name: 'Частичная занятость' },
  { value: REALTY_STATUS.sold, name: 'Продан' },
];

const RealtyStatusField = ({ value, handleChange, ...props }) => {

  return (
    <SelectField { ...props }
                 onChange={ handleChange }
                 options={ statusOptions }
                 label="Статус"
                 value={ value ?? '' }/>
  );
};

export default RealtyStatusField;
