import React, { useEffect, useState } from 'react';
import Autocomplete from './Autocomplete';
import { fetchCountries } from '../../actions/autocompleteActions';


const CountriesAutocomplete = ({ value, ...props }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    loadCountries();
  }, []);

  let loadCountries = () => {
    fetchCountries()
      .then(countries => {
        setCountries(countries.map(c => ( { iso: c.iso, name: c.title } )));
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <Autocomplete { ...props }
                  value={ ( typeof value === 'string' ? countries.find(country => country.iso === value) : value ) || null }
                  options={ countries }
                  label={ `Страна` }
                  placeholder={ `Выберите страну` }
    />
  );
};

export default CountriesAutocomplete;
