import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import SearchField from '../../SearchField';
import ManagementCompaniesSelect from '../../ManagementCompany/ManagementCompaniesSelect';
import { REALTY_TYPES } from '../../SelectConstats';
import SelectField from '../../SelectField';
import FilterLayout from '../../FilterLayout';


const PaymentOrdersFilters = ({ ...props }) => {

  let handleFilterSelectChange = (e) => {
    const { name, value } = e.target;
    const search = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        [name]: value,
      }),
    });
  };


  let getParam = (key) => {
    const search = queryString.parse(props.location.search);

    return search[key] || '';
  };


  return (
    <FilterLayout>
      <Grid item>
        <SelectField label={ 'Типы объектов' }
                     options={ REALTY_TYPES }
                     style={ { minWidth: 220 } }
                     onChange={ handleFilterSelectChange }
                     value={ getParam('realty_type') }
                     name="realty_type"
                     fullWidth/>
      </Grid>
      <Grid item>
        <ManagementCompaniesSelect onChange={ handleFilterSelectChange }
                                   style={ { minWidth: 220 } }
                                   value={ getParam('management_company_id') }
                                   name="management_company_id"/>
      </Grid>
      <Grid item>
        <SearchField searchField="realty_name"
                     label="Поиск по объекту"
                     placeholder="Введите имя объекта"/>
      </Grid>
    </FilterLayout>
  );
};

export default withRouter(PaymentOrdersFilters);
