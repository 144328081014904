import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import PickDateField from '../PickDateField';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import SelectField from '../SelectField';
import FilterLayout from '../FilterLayout';

const paymentType = [
  { value: 'income', name: 'Приход' },
  { value: 'outcome', name: 'Расход' }
];


class TochkaFilter extends PureComponent {

  handleChange = (e) => {
    const { name, value } = e.target;
    const query = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        page: 1,
        [name]: value
      })
    });
  };


  handleChangeDate = (key, value) => {
    const query = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        page: 1,
        [key]: moment(value, 'DD/MM/YYYY').format('X')
      })
    });
  };

  getParam = (params) => {
    const query = queryString.parse(this.props.location.search);

    return query[params] || '';
  };


  render() {
    return (
      <FilterLayout>
        <Grid item>
          <SelectField label="Приход или расход"
                       style={ { minWidth: 200 } }
                       value={ this.getParam('type') }
                       fullWidth
                       name="type"
                       onChange={ this.handleChange }
                       options={ paymentType }/>
        </Grid>
        <Grid item>
          <PickDateField name="period[from]"
                         type="period[from]"
                         value={ moment(this.getParam('period[from]'), 'X').format('DD/MM/YYYY') }
                         onChange={ this.handleChangeDate }
                         label="Дата от"/>
        </Grid>
        <Grid item>
          <PickDateField name="period[to]"
                         type="period[to]"
                         value={ moment(this.getParam('period[to]'), 'X').format('DD/MM/YYYY') }
                         onChange={ this.handleChangeDate }
                         label="Дата до"/>
        </Grid>
      </FilterLayout>
    );
  }
}

export default withRouter(TochkaFilter);
