import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import { openSnackbar } from '../../actions/snackbarActions';
import SwitchField from '../SwitchField';
import FormContainer from '../FormContainer';
import { createManager } from '../../actions/managersActions';
import PhoneInput from '../PhoneInput';
import CompaniesAutocomplete from '../Autocomplete/CompaniesAutocomplete';
import PasswordField from '../PasswordField';
import { withRouter } from 'react-router-dom';
import { flex } from '../JSStylesConstants';
import { SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { reformatPhoneNumber } from '../Tools';
import ButtonWithProgress from '../ButtonWithProgress';

const initialState = {
  manager: {
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    active: false,
    chief: false,
    company: '',
    phone: ''
  },
  errors: new Errors(),
};


class ManagerCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  };


  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      manager: {
        ...prevState.manager,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };

  handleChangeCompanies = (e, value) => {
    this.setState(prevState => ( {
      manager: {
        ...prevState.manager,
        company: value
      }
    } ));
  };


  handleSubmit = (event) => {
    event.preventDefault();

    const { manager } = this.state;

    createManager({
      name: manager.name,
      email: manager.email,
      password: manager.password,
      password_confirmation: manager.passwordConfirmation,
      active: manager.active,
      chief: manager.chief,
      company_id: manager.company.id,
      phone: reformatPhoneNumber(manager.phone)
    }).then(() => {
      this.setState(initialState);
      this.props.loadManagers();
      this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.manager.create.success);
      this.props.location.state = { open: false };
    }).catch(errors =>
      this.setState({
        errors: new Errors(errors)
      })
    );
  };

  render() {
    const { manager, errors } = this.state;

    return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField error={ errors.getError('name') }
                         fullWidth
                         name="name"
                         value={ manager.name }
                         onChange={ this.handleChange }
                         label="Имя"
                         placeholder="Введите имя"
              />
            </FormColumn>
            <FormColumn sm={ 6 }>
              <TextField error={ errors.getError('email') }
                         fullWidth
                         name="email"
                         value={ manager.email }
                         onChange={ this.handleChange }
                         label="Электронная почта"
                         placeholder="Введите электронную почту"
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }>
              <PhoneInput error={ errors.getError('phone') }
                          name="phone"
                          type="phone"
                          onChange={ this.handleChange }
                          value={ manager.phone }
                          label="Телефон"
                          placeholder="Введите телефон"/>
            </FormColumn>
            <FormColumn sm={ 6 }>
              <CompaniesAutocomplete error={ errors.getError('company_id') }
                                     onChange={ this.handleChangeCompanies }
                                     value={ manager.company }/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }>
              <PasswordField
                error={ errors.getError('password') }
                name="password"
                value={ manager.password }
                onChange={ this.handleChange }
                label="Пароль"
                placeholder="Введите пароль"
              />
            </FormColumn>
            <FormColumn sm={ 6 }>
              <PasswordField
                error={ errors.getError('password') }
                name="passwordConfirmation"
                value={ manager.passwordConfirmation }
                onChange={ this.handleChange }
                label="Пароль"
                placeholder="Введите пароль"
              />
            </FormColumn>

          </FormRow>
          <FormRow>
            <FormColumn sm={ 6 }
                        style={ flex }>
              <SwitchField
                error={ errors.getError('chief') }
                name="chief"
                value={ manager.chief }
                onChange={ this.handleChange }
                label="Главный менеджер"
              />
            </FormColumn>

            <FormColumn sm={ 6 }
                        style={ flex }>
              <SwitchField
                error={ errors.getError('active') }
                name="active"
                value={ manager.active }
                onChange={ this.handleChange }
                label="Активность"
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn xs="auto" sm="auto" style={ { marginLeft: 'auto' } }>
              <ButtonWithProgress label={ 'Добавить' }

                                  onClick={ this.handleSubmit }
              />

            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>
    );
  }
}

export default connect(null, { openSnackbar })(withRouter(ManagerCreateForm));
