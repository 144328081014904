import React, { useEffect } from 'react';
import { Grid, Link, TableCell, TableRow } from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { Link as RouterLink } from 'react-router-dom';
import ManagementCompanyCreateForm from '../../components/ManagementCompany/ManagementCompanyCreateForm';
import PageHeader from '../../components/PageHeader';
import AddingButton from '../../components/AddingButton';
import { PlusIcon } from '../../icons';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { MANAGEMENT_COMPANIES_HEADERS } from '../../components/TableConstants';


const ManagementCompaniesListPage = () => {
  const { setPageTitle } = usePageTitle();
  const { data, loading, refetch: loadManagementCompanies } = useFetch({
    url: API_ROUTE.managementCompanies
  });

  useEffect(() => {
    setPageTitle('Управляющие компании');
  });


  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Управляющие компании"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление управляющей компании"
                        button={ props => <AddingButton { ...props }
                                                        tooltip="Добавить управляющую компанию"
                                                        icon={ () => <PlusIcon/> }/> }>
              <ManagementCompanyCreateForm loadManagementCompanies={ loadManagementCompanies }/>
            </FormDrawer>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate loading={ loading }
                       data={ data?.results }
                       headers={ MANAGEMENT_COMPANIES_HEADERS }>
          { data?.results && data?.results.map(company => (
            <TableRow key={ company.id } hover>
              <TableCell>
                <Link
                  color="inherit"
                  component={ React.forwardRef((props, ref) => (
                    <RouterLink
                      { ...props }
                      ref={ ref }
                      to={ `/management-companies/${ company.id }` }
                    />
                  )) }
                >
                  <b>{ company.name }</b>
                </Link>
              </TableCell>
              <TableCell>{ company.url }</TableCell>
              <TableCell>{ company.counterparty_account_number }</TableCell>
              <TableCell>{ company.counterparty_bank_bic }</TableCell>
              <TableCell>{ company.counterparty_inn }</TableCell>
              <TableCell>{ company.counterparty_kpp }</TableCell>
              <TableCell>{ company.counterparty_name }</TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>


    </Grid>
  );
};

export default connect(null, { openSnackbar })(ManagementCompaniesListPage);
