import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { transferMoneyBalance } from '../../actions/paydeskActions';
import FormColumn from '../FormColumn';
import Errors from '../Errors';
import TextField from '../TextField';
import { withRouter } from 'react-router-dom';
import PaydeskPaymentSelect from './PaydeskBalanceSelect';
import { SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';


const initialState = {
  payment: {
    from_balance: '',
    to_balance: '',
    amount: '',
    comment: ''
  },
  loading: false,
  errors: new Errors()
};

class PaydeskPaymentTransferForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      payment: {
        ...prevState.payment,
        [name]: type === 'checkbox' ? checked : value
      }
    } ));
  };


  handleSubmit = () => {
    const { payment } = this.state;

    this.setState({ loading: true });

    const data = {
      company_id: payment.from_balance.company?.id,
      from_balance_id: payment.from_balance?.id,
      to_balance_id: payment.to_balance?.id,
      amount: payment.amount,
      comment: payment.comment
    };

    transferMoneyBalance(data)
      .then(() => {
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.paydeskPayment.transfer.success);
        this.setState(initialState);
        this.props.loadPayments();
        this.props.loadBalances();
        this.props.closeModal();
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    const { payment, errors } = this.state;

    return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 6 }>
              <PaydeskPaymentSelect handleChange={ this.handleChange }
                                    name="from_balance"
                                    label="Касса"
                                    fullWidth
                                    error={ errors.getError('from_balance_id') }
                                    value={ payment.from_balance }/>

            </FormColumn>
            <FormColumn sm={ 6 }>
              <PaydeskPaymentSelect handleChange={ this.handleChange }
                                    label="Перемещены на баланс"
                                    name="to_balance"
                                    fullWidth
                                    error={ errors.getError('to_balance_id') }
                                    value={ payment.to_balance }/>
            </FormColumn>

          </FormRow>


          <FormRow>
            <FormColumn sm={ 12 }>
              <TextField
                multiline
                rows={ 3 }
                error={ errors.getError('comment') }
                name="comment"
                value={ payment.comment }
                onChange={ this.handleChange }
                label="Комментарий"
                placeholder="Введите комментарий"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField
                error={ errors.getError('amount') }
                name="amount"
                type="number"
                value={ payment.amount }
                onChange={ this.handleChange }
                label="Сумма"
                placeholder="Введите сумму"
              />
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn xs="auto"
                        sm="auto"
                        style={ { marginLeft: 'auto' } }>
              <ButtonWithProgress label={ 'Добавить' }
                                  disabled={ this.state.loading }
                                  onClick={ this.handleSubmit }/>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>

    );
  }
}

export default connect(null, { openSnackbar })(withRouter(PaydeskPaymentTransferForm));
