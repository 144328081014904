import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { blue, green, orange, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { closeSnackbar, openSnackbar } from '../actions/snackbarActions';
import { ERROR, INFO, SUCCESS, WARNING } from './Constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ( {
  success: {
    backgroundColor: green[600],
    padding: '4px 16px'
  },
  error: {
    backgroundColor: red[600],
    padding: '4px 16px'
  },
  warning: {
    backgroundColor: orange[600],
    padding: '4px 16px'
  },
  info: {
    backgroundColor: blue[600],
    padding: '4px 16px'
  },
  messageIcon: {
    marginRight: 12,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
} ));

const Snackbar = ({ isOpen, message, closeSnackbar, role, ...props }) => {
  const {t} = useTranslation();
  const classes = useStyles();

  let snackBarIcon = (role) => {
    switch ( role ) {
      case SUCCESS:
        return <CheckCircleIcon className={ classes.messageIcon }/>;
      case ERROR:
        return <CancelOutlinedIcon className={ classes.messageIcon }/>;
      case WARNING:
        return <InfoOutlinedIcon className={ classes.messageIcon }/>;
      case INFO:
        return <InfoOutlinedIcon className={ classes.messageIcon }/>;
      default:
        return;

    }
  };

  return (
    <MuiSnackbar anchorOrigin={ {
      vertical: 'bottom',
      horizontal: 'right',
    } }
                 open={ isOpen }
                 autoHideDuration={ 5000 }
                 onClose={ closeSnackbar }>
      <MuiSnackbarContent className={ classes[role] }
                          message={
                            <div className={ classes.message }>
                              { snackBarIcon(role) }
                              { t(message) }
                            </div>
                          }
                          action={ [
                            <IconButton key="close"
                                        color="inherit"
                                        onClick={ closeSnackbar }>
                              <CloseIcon className={ classes.icon }/>
                            </IconButton>,
                          ] }
      />
    </MuiSnackbar>
  );
};

const mapStateToProps = (state) => ( {
  ...state.snackbar,
} );

export default connect(mapStateToProps, { openSnackbar, closeSnackbar })(Snackbar);
