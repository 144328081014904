import React, { PureComponent } from 'react';
import Errors from '../Errors';
import { fetchRealty, updateRealty } from '../../actions/realtyActions';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import { Grid } from '@material-ui/core';
import RealtyAddressFields from '../RealtyAddressFields';
import Title from '../Title';
import RealtyAmenitiesFields from '../RealtyAmenitiesFields';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import SwitchField from '../SwitchField';
import RealtyRoomCountSelect from './RealtyRoomCountSelect';
import RealtyStatusField from './RealtyStatusField';
import RealtyGroupsAutocomplete from '../Autocomplete/RealtyGroupsAutocomplete';
import RealtyTypeSelectField from './RealtyTypeSelectField';
import RealtySubTypeSelectField from './RealtySubTypeSelectField';
import { flex } from '../JSStylesConstants';
import { APARTMENT, OFFICE, PARKING, REALTY_STATUS, SUCCESS } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';
import { Redirect } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog';


class RealtyEditMainForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      realty: {
        id: this.props.id,
        name: '',
        location: '',
        type: '',
        subtype: '',
        address: '',
        underground_station: '',
        underground_station_distance: '',
        parking_space_count: '',
        amenities: [],
        status: '',
        room_count: '',
        with_partial_rent: false,
        square: '',
        group: '',
        is_deleted: false
      },
      confirm: false,
      errors: new Errors(),
      loading: false,
    };
  }


  componentDidMount() {
    const { realty: { id } } = this.state;

    fetchRealty(id)
      .then(realty => {
        this.setRealty(realty);
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      });
  }


  setRealty = ({
                 id, name, status, type, address, square,
                 location, underground_station, underground_station_distance,
                 floor, room_count, section, amenities, with_partial_rent, living_space, kitchen_area,
                 wifi_password, parking_space_count, group, subtype, is_deleted
               }) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        id: id,
        name: name,
        status: status,
        type: type,
        address: address,
        location: location,
        underground_station: underground_station,
        underground_station_distance: underground_station_distance,
        floor: floor,
        room_count: room_count,
        section: section,
        amenities: amenities.map(x => x.id.toString()),
        with_partial_rent: with_partial_rent,
        living_space: living_space,
        kitchen_area: kitchen_area,
        wifi_password: wifi_password,
        parking_space_count: parking_space_count,
        square: square,
        group: group,
        subtype: subtype,
        is_deleted: is_deleted,
      },
    } ));
  };


  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));


  };

  handleStationChange = (e, value) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        underground_station: value,
        underground_station_distance: ''
      }
    } ));
  };

  handleLocationsChange = (e, value) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        location: value,
        underground_station: '',
        underground_station_distance: ''
      }
    } ));
  };


  handleComfortChange = (event) => {
    const { value, checked } = event.target;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        amenities: checked ? [...prevState.realty.amenities, value] : prevState.realty.amenities.filter(x => x !== value)
      }
    } ));
  };

  handleGroupChange = (e, value) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        group: value,
      }
    } ));
  };

  handleCloseConfirm = () => {
    this.setState({ confirm: false });
  };

  handleConfirm = () => {
    this.setState({ confirm: true });
  };

  handleSubmit = () => {
    const { realty } = this.state;

    this.setState({
      confirm: false,
      loading: true
    });

    const data = {
      name: realty.name,
      location_id: realty.location?.id,
      address: realty.address,
      floor: realty.floor,
      status: realty.status,
      room_count: realty.room_count ? realty.room_count : null,
      section: realty.section,
      square: realty.square,
      type: realty.type,
      subtype: realty.subtype,
      amenities: realty.amenities,
      with_partial_rent: realty.with_partial_rent,
      underground_station_id: realty.underground_station?.id,
      underground_station_distance: realty.underground_station_distance,
      living_space: realty.living_space,
      kitchen_area: realty.kitchen_area,
      wifi_password: realty.wifi_password,
      parking_space_count: realty.parking_space_count,
      group_id: realty?.group?.id
    };

    updateRealty(this.props.id, data)
      .then(realty => {
        this.setRealty(realty);
        this.setState({
          loading: false,
          errors: new Errors({})
        });
        this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
      })
      .catch(errors => {
        this.setState({
          loading: false,
          errors: new Errors(errors)
        });
      });

  };

  render() {
    const { realty, errors, loading, confirm } = this.state;

    if ( !!errors.errors.length ) {
      return <Redirect to="/"/>;
    }

    return (
      <Grid container
            spacing={ 2 }
            style={ { paddingTop: 24, paddingBottom: 24 } }>
        <Grid item
              xs={ 12 }>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 6 }
                          md={ 4 }>
                <TextField error={ errors.getError('name') }
                           name="name"
                           value={ realty.name }
                           onChange={ this.handleChange }
                           label="Название*"
                           placeholder="Введите название"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <RealtyTypeSelectField handleChange={ this.handleChange }
                                       error={ errors.getError('type') }
                                       name="type"
                                       disabled
                                       fullWidth
                                       value={ realty.type }/>
              </FormColumn>
              { ( ( realty?.type === APARTMENT ) && realty.subtype ) &&
                <FormColumn sm={ 2 }>
                  <RealtySubTypeSelectField handleChange={ this.handleChange }
                                            error={ errors.getError('subtype') }
                                            name="subtype"
                                            disabled
                                            fullWidth
                                            value={ realty.subtype }/>
                </FormColumn>
              }

            </FormRow>
            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <RealtyStatusField error={ errors.getError('status') }
                                   value={ realty.status }
                                   label="Статус"
                                   name="status"
                                   handleChange={ this.handleChange }
                                   fullWidth/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 2 }>
                <RealtyGroupsAutocomplete value={ realty?.group?.id }
                                          error={ errors.getError('group_id') }
                                          onChange={ this.handleGroupChange }/>
              </FormColumn>
              <FormColumn sm={ 3 }
                          md={ 2 }
                          style={ flex }>
                <SwitchField
                  error={ errors.getError('with_partial_rent') }
                  name="with_partial_rent"
                  value={ realty.with_partial_rent }
                  onChange={ this.handleChange }
                  label="Частичная занятость"
                />
              </FormColumn>
            </FormRow>
          </FormContainer>
        </Grid>

        <Grid item xs={ 12 }>
          <Title title="Адрес"
                 size="xl"
                 style={ { paddingBottom: 24 } }/>
          <RealtyAddressFields handleChange={ this.handleChange }
                               handleStationChange={ this.handleStationChange }
                               handleLocationsChange={ this.handleLocationsChange }
                               errors={ errors }
                               floor={ realty.floor }
                               underground_station_distance={ realty.underground_station_distance }
                               underground_station={ realty.underground_station }
                               location={ realty.location }
                               address={ realty.address }/>
        </Grid>

        <Grid item xs={ 12 }>
          <Title title="Параметры"
                 style={ { paddingBottom: 24 } }
                 size="xl"/>
          <Grid item
                xs={ 12 }>
            { realty.type &&
              <FormContainer>
                <FormRow>
                  <FormColumn sm={ 3 }
                              md={ 2 }>
                    <TextField type="number"
                               error={ errors.getError('square') }
                               name="square"
                               value={ realty.square }
                               onChange={ this.handleChange }
                               label="Площадь"
                               placeholder="Введите площадь"
                    />
                  </FormColumn>
                  { ( realty.type === APARTMENT || realty.type === OFFICE ) &&
                    <FormColumn sm={ 3 }
                                md={ 2 }>
                      <RealtyRoomCountSelect onChange={ this.handleChange }
                                             value={ realty.room_count }
                                             error={ errors.getError('room_count') }/>
                    </FormColumn>
                  }

                  { realty.type === PARKING &&
                    <FormColumn sm={ 2 }>
                      <TextField type="number"
                                 error={ errors.getError('parking_space_count') }
                                 name="parking_space_count"
                                 value={ realty.parking_space_count }
                                 onChange={ this.handleChange }
                                 label="Количество паркомест"
                                 placeholder="Введите количество"
                      />
                    </FormColumn>
                  }

                </FormRow>

                { realty.type === APARTMENT &&
                  <>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField type="number"
                                   error={ errors.getError('living_space') }
                                   name="living_space"
                                   value={ realty.living_space }
                                   onChange={ this.handleChange }
                                   label="Жилая площадь"
                                   placeholder="Введите площадь"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField type="number"
                                   error={ errors.getError('kitchen_area') }
                                   name="kitchen_area"
                                   value={ realty.kitchen_area }
                                   onChange={ this.handleChange }
                                   label="Площадь кухни"
                                   placeholder="Введите площадь"
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField error={ errors.getError('wifi_password') }
                                   name="wifi_password"
                                   value={ realty.wifi_password }
                                   onChange={ this.handleChange }
                                   label="Пароль от wifi"
                                   placeholder="Введите пароль"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField error={ errors.getError('section') }
                                   name="section"
                                   type="number"
                                   value={ realty.section }
                                   onChange={ this.handleChange }
                                   label="Секция"
                                   placeholder="Введите номер секции"
                        />
                      </FormColumn>
                    </FormRow>
                  </>
                }
              </FormContainer>
            }

          </Grid>
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          { realty.type &&
            <RealtyAmenitiesFields handleChange={ this.handleComfortChange }
                                   type={ realty.type }
                                   currentAmenities={ realty.amenities }/> }

        </Grid>
        <Grid item xs={ 12 }>
          <ButtonWithProgress loading={ loading }
                              disabled={ realty.is_deleted }
                              onClick={ realty.status === REALTY_STATUS.sold ? this.handleConfirm : this.handleSubmit }
                              label="Сохранить"/>
        </Grid>

        <ConfirmDialog description="Вы сохраняете статус объекта 'Продано'. Учтите что больше Вы не сможете
                         создавать заказы на объект, а только просматривать историю по нему."
                       open={ confirm }
                       handleClose={ this.handleCloseConfirm }
                       handleConfirm={ this.handleSubmit }
                       negativeAnswer="Нет"
                       positiveAnswer="Да"/>
      </Grid>
    );
  }
}

export default connect(null, { openSnackbar })(RealtyEditMainForm);
