import { textCenter } from './JSStylesConstants';

export const BOOKING_REQUEST_HEADERS = [
  {
    label: 'ID'
  },
  {
    label: 'Статус'
  },
  {
    label: 'Тип'
  },
  {
    label: 'Клиент'
  },
  {
    label: 'Объект'
  },
  {
    label: 'Создано'
  },
  {
    label: ''
  },
];

export const MEETINGS_HEADERS = [
  {
    label: 'Дата встречи'
  },
  {
    label: 'Кто встречает'
  },
  {
    label: 'Клиент'
  },
  {
    label: 'Тип'
  },
  {
    label: 'Объект'
  },
  {
    label: 'Статус'
  },
  {
    label: 'Комментарий'
  },
  {
    label: ''
  },
];

export const PAYDESK_PAYMENTS_HEADERS = [
  {
    label: 'ID'
  },
  {
    label: 'Дата'
  },
  {
    label: 'Объект'
  },
  {
    label: 'Категория платежа'
  },
  {
    label: 'Баланс'
  },
  {
    label: 'Приход'
  },
  {
    label: 'Расход'
  },
  {
    label: 'Комментарий'
  },
];

export const BOOKINGS_HEADERS = [
  {
    label: 'ID',
    name: 'id',
    sort: true
  },
  {
    label: 'Статус',
    sort: false
  },
  {
    label: 'Клиент',
    name: 'client.last_name',
    sort: true
  },
  {
    label: 'Объект',
  },
  {
    label: 'Депозит',
    name: 'deposit',
  },
  {
    label: 'Текущий тариф'
  },
  {
    label: 'Оплачено до',
    name: 'paid_until',
    sort: true
  },
  {
    label: 'Дата выезда',
    name: 'departure_date',
    sort: true
  },
  {
    label: 'Действие'
  },
  {
    label: ''
  }
];

export const CLIENTS_HEADERS = [
  {
    label: 'ID'
  },
  {
    label: 'ФИО'
  },
  {
    label: 'Телефон'
  },
  {
    label: 'Email'
  },
  {
    label: 'Заказы'
  },
  {
    label: 'Последняя активность'
  },
  {
    label: 'Активность'
  },
];

export const INVOICES_HEADERS = [
  {
    label: 'ID'
  },
  {
    label: 'Дата создания'
  },
  {
    label: 'Тип',
    style: textCenter
  },
  {
    label: 'Нач.'
  },
  {
    label: 'Посл.'
  },
  {
    label: 'Цена за 1'
  },
  {
    label: 'Счетчик'
  },
  {
    label: 'УК'
  },
  {
    label: 'Сумма'
  },
  {
    label: ''
  },
];


export const MANAGEMENT_COMPANIES_HEADERS = [
  {
    label: 'Название'
  },
  {
    label: 'URL'
  },
  {
    label: 'Счёт получателя'
  },
  {
    label: 'БИК банка получателя'
  },
  {
    label: 'ИНН получателя'
  },
  {
    label: 'КПП получателя'
  },
  {
    label: 'Получатель платежа'
  }
];

export const REALTY_GROUPS_HEADERS = [
  {
    label: 'ID'
  },
  {
    label: 'Название'
  },
  {
    label: 'Компания'
  },
  {
    label: 'К-во объектов в группе'
  },
];

export const MANAGERS_HEADERS = [
  {
    label: 'ФИО'
  },
  {
    label: 'Email'
  },
  {
    label: 'Главный менеджер'
  },
  {
    label: 'Активность'
  },
  {
    label: ''
  },
]

export const MODERATORS_HEADERS = [
  {
    label: 'ФИО'
  },
  {
    label: 'Email'
  },
  {
    label: 'Активность'
  },
  {
    label: ''
  },
]

export const PERMISSIONS_HEADERS = [
  {
    label: 'ID'
  },
  {
    label: 'Название'
  },
  {
    label: 'Активность'
  },
  {
    label: ''
  },
]

export const NOTIFICATION_HEADERS = [
  {
    label: 'Время события'
  },
  {
    label: 'Текст события'
  },
  {
    label: 'Ссылка'
  },
  {
    label: 'Ответственный'
  },
]

export const LOGS_HEADERS = [
  {
    label: 'Дата'
  },
  {
    label: 'Пользователь'
  },
  {
    label: 'Действие'
  },
  {
    label: 'Данные'
  }
]

export const TOCHKA_PAYMENTS_HEADERS = [
  {
    label: 'Дата'
  },
  {
    label: 'Приход'
  },
  {
    label: 'Расход'
  },
  {
    label: 'Организация'
  },
  {
    label: 'Назначение'
  },
  {
    label: 'Банк БИК'
  },
  {
    label: 'Имя Банка'
  },
  {
    label: 'Проведено в кассе'
  },
]
