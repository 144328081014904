import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelectField from '../FilterFields/FilterSelectField';
import { withRouter } from 'react-router-dom';
import FilterCheckboxField from '../FilterCheckboxField';
import RealtyGroupSelectField from './RealtyGroupSelectField';
import { REALTY_STATUS_OPT } from '../SelectConstats';
import FilterLayout from '../FilterLayout';


const RealtyFilter = ({ ...props }) => {

  return (
    <FilterLayout>
      <Grid item>
        <FilterSelectField options={ REALTY_STATUS_OPT }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Статус"
                           type="status"/>
      </Grid>
      <Grid item>
        <RealtyGroupSelectField/>
      </Grid>
      <Grid item>
        <FilterCheckboxField name="deleted"
                             label="Архивные"/>
      </Grid>
    </FilterLayout>
  );
};

export default withRouter(RealtyFilter);
