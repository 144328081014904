import axios from 'axios';
import queryString from 'query-string';
import { BOOKING_REQUESTS_ROUTE } from '../components/RouterConstants';

export const fetchBookingRequests = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`${BOOKING_REQUESTS_ROUTE}?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};


export const fetchBookingRequest = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${BOOKING_REQUESTS_ROUTE}/${id}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};


export const createBookingRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${BOOKING_REQUESTS_ROUTE}`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};

export const updateBookingRequest = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${BOOKING_REQUESTS_ROUTE}/${id}`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};

export const updateBookingRequestStatus = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${BOOKING_REQUESTS_ROUTE}/${id}/status`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};

export const fetchBookingRequestCountNew = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${BOOKING_REQUESTS_ROUTE}/total/new`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};


