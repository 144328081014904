import React, { PureComponent } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import PageHeader from '../PageHeader';
import FormColumn from '../FormColumn';
import moment from 'moment';
import PickDateField from '../PickDateField';
import TextField from '../TextField';
import { createBookingsPayments, fetchBookingsPaymentsFromAction } from '../../actions/bookingsActions';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import PaydeskPaymentSelect from '../Paydesk/PaydeskBalanceSelect';
import UtilitiesCounterAutocomplete from '../Autocomplete/UtilitiesCounterAutocomplete';
import Pagination from '../Pagination';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PaydeskPaymentCancelModal from '../Paydesk/PaydeskPaymentCancelModal';
import { openPaydeskCancelModal } from '../../actions/formDrawerActions';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import theme from '../../theme';
import { BOOKING_PAYMENTS_TYPE, BOOKING_STATUS, MANAGER, MODERATOR, SUCCESS } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';
import { withTranslation } from 'react-i18next';

const initialState = {
  booking: {
    id: '',
    amount: '',
    from_date: moment().startOf('day'),
    until_date: moment().endOf('day'),
    manager: '',
    balance: '',
    counter: '',
    status: '',
    comment: '',

    utilities_payments: [],
    meta: '',
    perPage: 25

  },
  loading: false,
  errors: new Errors()
};

class BookingUtilitiesForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadUtilitiesPayments();
    }
  }

  componentDidMount() {
    this.setBooking(this.props.booking);
    this.setDefaultManager();
    this.loadUtilitiesPayments();
  }

  loadUtilitiesPayments = () => {
    const { booking } = this.props;
    const search = queryString.parse(this.props.location.search);

    fetchBookingsPaymentsFromAction(booking.id, BOOKING_PAYMENTS_TYPE.utilities, search)
      .then(response => {
        this.setState(prevState => ( {
          booking: {
            ...prevState.booking,
            id: booking.id,
            utilities_payments: response.data,
            meta: response.meta,
          }
        } ));
      });
  };

  setBooking = ({ id, realty, status }) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        id: id,
        realty: realty,
        status: status,
      },
    } ));
  };


  setDefaultManager = () => {
    const { user } = this.props;

    if ( user.userable_type === MANAGER ) {
      this.setState(prevState => ( {
        booking: {
          ...prevState.booking,
          manager: { id: user.userable_id, name: user.name }
        }
      } ));
    }
  };


  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };


  handleChangeDate = (key, value) => {

    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        [key]: value
      }
    } ));
  };

  handleOpenModal = (payment) => {
    this.props.openPaydeskCancelModal(payment);
  };

  handleChangeManager = (e, value) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        manager: value
      }
    } ));
  };


  handleCounterChange = (e, value) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        counter: value
      }
    } ));
  };

  handleSubmit = () => {
    const { booking } = this.state;

    this.setState({ loading: true });

    const data = {
      booking_id: booking.id,
      amount: booking.amount,
      action: BOOKING_PAYMENTS_TYPE.utilities,
      paid: true,
      comment: booking.comment,
      balance_id: booking.balance.id,
      from_date: parseInt(moment(booking.from_date, 'DD/MM/YYYY').format('X')),
      until_date: parseInt(moment(booking.until_date, 'DD/MM/YYYY').format('X')),
      realty_utility_id: booking.counter.id,
      manager_id: booking.manager?.id
    };

    createBookingsPayments(booking.id, data)
      .then(() => {
        this.setBooking(this.props.booking);
        this.setState(initialState);
        this.setDefaultManager();
        this.loadUtilitiesPayments();
        this.props.openSnackbar(SUCCESS, 'Платеж успешно добавлен');
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };


  render() {
    const { booking, errors } = this.state;
    const { user, t } = this.props;

    return (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }
              style={ {
                display: 'flex',
                alignItems: 'center'
              } }
        >
          <PageHeader title="Коммуналка"
                      style={ { width: 'auto' } }
                      size="md"/>
        </Grid>

        { user.userable_type !== MODERATOR &&
          <Grid item
                xs={ 12 }>
            <FormContainer>
              <FormRow>
                <FormColumn sm={ 4 }
                            md={ 3 }
                            lg={ 2 }>
                  <ManagersAutocomplete value={ booking.manager }
                                        label={ `Менеджер` }
                                        onChange={ this.handleChangeManager }/>
                </FormColumn>
                <FormColumn sm={ 4 }
                            md={ 3 }
                            lg={ 2 }>
                  <UtilitiesCounterAutocomplete bookingId={ booking?.realty?.id }
                                                value={ booking ? booking.counter : '' }
                                                onChange={ this.handleCounterChange }
                                                error={ errors.getError('realty_utility_id') }/>
                </FormColumn>

                <FormColumn sm={ 4 }
                            md={ 3 }
                            lg={ 2 }>
                  <TextField name="amount"
                             error={ errors.getError('amount') }
                             value={ booking.amount }
                             type="number"
                             onChange={ this.handleChange }
                             label="Сумма"
                             placeholder="Введите сумму"
                  />
                </FormColumn>
                <FormColumn sm={ 4 }
                            md={ 3 }
                            lg={ 2 }>
                  <PickDateField name="from_date"
                                 type="from_date"
                                 error={ errors.getError('from_date') }
                                 value={ booking.from_date }
                                 defaultDateFrom={ moment().startOf('day') }
                                 onChange={ this.handleChangeDate }
                                 label="Дата от"/>
                </FormColumn>
                <FormColumn sm={ 4 }
                            md={ 3 }
                            lg={ 2 }>
                  <PickDateField name="until_date"
                                 type="until_date"
                                 error={ errors.getError('until_date') }
                                 value={ booking.until_date }
                                 defaultDateFrom={ moment().startOf('day') }
                                 onChange={ this.handleChangeDate }
                                 label="Дата до"/>
                </FormColumn>

                <FormColumn sm={ 4 }
                            md={ 3 }
                            lg={ 2 }>
                  <PaydeskPaymentSelect handleChange={ this.handleChange }
                                        name="balance"
                                        label="Баланс"
                                        fullWidth
                                        error={ errors.getError('balance_id') }
                                        value={ booking.balance }/>
                </FormColumn>

                <FormColumn sm={ 6 }
                            md={ 4 }
                            lg={ 3 }>
                  <TextField name="comment"
                             value={ booking.comment }
                             error={ errors.getError('comment') }
                             type="text"
                             onChange={ this.handleChange }
                             label="Комментарий"
                             placeholder="Введите комментарий"/>
                </FormColumn>

                <FormColumn sm={ 2 }
                            style={ {
                              display: 'flex',
                              alignItems: 'center'
                            } }>
                  <ButtonWithProgress label={ 'Добавить' }
                                      loading={ this.state.loading || booking.status === BOOKING_STATUS.close }
                                      onClick={ this.handleSubmit }/>
                </FormColumn>
              </FormRow>
            </FormContainer>
          </Grid>
        }


        <Grid item
              xs={ 12 }>
          <Paper>
            <TableContainer style={ {
              maxWidth: '100%',
              overflow: 'auto'
            } }>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{ t('ID') }</TableCell>
                    <TableCell>{ t('Дата создания') }</TableCell>
                    <TableCell>{ t('Пролонг') }</TableCell>
                    <TableCell>{ t('Тип') }</TableCell>
                    <TableCell>{ t('Тип платежа') }</TableCell>
                    <TableCell>{ t('Сумма') }</TableCell>
                    <TableCell>{ t('Комментарий') }</TableCell>
                    { user.userable_type !== MODERATOR &&
                      <TableCell></TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  { booking.utilities_payments && booking.utilities_payments.map(utility => (
                    <TableRow key={ utility.id } hover>
                      <TableCell>#{ utility.id }</TableCell>
                      <TableCell>{ utility.created_at }</TableCell>
                      <TableCell>{ utility.period }</TableCell>
                      <TableCell>{ utility.realty_utility?.basic_utility.name }</TableCell>
                      <TableCell>{ utility.payment?.balance?.name }</TableCell>
                      <TableCell>{ utility.amount }</TableCell>
                      <TableCell>{ utility?.comment }</TableCell>
                      { user.userable_type !== MODERATOR &&
                        <TableCell>
                          { !utility.payment?.canceled_at
                            ? <HighlightOffOutlinedIcon onClick={ () => this.handleOpenModal(utility.payment) }
                                                        style={ {
                                                          cursor: 'pointer',
                                                          color: theme.palette.secondary.main
                                                        } }/>
                            : <strong style={ { color: theme.palette.secondary.main } }>
                              { t('Отменен') }
                            </strong>
                          }
                        </TableCell>
                      }
                    </TableRow>
                  )) }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        { booking.meta.last_page > 1 &&

          <Pagination meta={ booking.meta }
                      wrapComponent={ (props) => <Grid item xs={ 12 }
                                                       style={ {
                                                         paddingTop: `24px`,
                                                         display: `flex`,
                                                         justifyContent: 'center'
                                                       } }
                                                       { ...props }/> }

          />
        }

        <PaydeskPaymentCancelModal loadPayments={ this.loadUtilitiesPayments }/>
      </Grid>
    );
  }
}

let mapStateToProps = (state) => ( {
  user: state.auth.user
} );


export default connect(mapStateToProps, { openSnackbar, openPaydeskCancelModal })
(withRouter(withTranslation()(BookingUtilitiesForm)));
