import React, {memo, useCallback, useEffect, useState} from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import {Grid} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {
  BLOB_FILE,
  formattedContractFields,
  getFormatTypeDownloadDocument,
  reformatPhoneNumber,
  validationLength
} from '../Tools';
import Errors from '../Errors';
import BookingUpdateContractInfoRow from './BookingUpdateContractInfoRow';
import SwitchField from '../SwitchField';
import moment from 'moment';
import {openSnackbar} from '../../actions/snackbarActions';
import fileDownload from 'js-file-download';
import {
  downloadBookingContract,
  getContractUpdatedFields,
  sendContractUpdatedFields
} from '../../actions/bookingsActions';
import Title from '../Title';
import DialogsRemoveWithCustomField from '../DialogsRemoveWithCustomField';
import ButtonWithProgress from '../ButtonWithProgress';
import { CLIENT_ENTITY, CLIENT_INDIVIDUAL, DATE_FORMAT, SUCCESS } from '../Constants';
import {openContractInfoUpdateModal} from '../../actions/formDrawerActions';


const BookingUpdateContractInfoModalForm = ({closeModal, loadBooking, client, ...props}) => {
  const dispatch = useDispatch();
  const {fields, id, format,
    created_at, rental_agreement} = useSelector(state => state.formDrawer.contractInfo.payload);
  const [state, setState] = useState({});
  const [fieldToRender, setFieldToRender] = useState([]);
  const [errors, setErrors] = useState(new Errors());
  const [legal_type, setLegal_type] = useState(null);
  const [handleModal, setHandleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFieldToRender(formattedContractFields(fields));
    setState(fields);

    return () => {
      setState({});
    };
  }, [fields]);


  //todo: not perfect but work, refactor
  //eslint-disable-next-line
  // const updateBookingInfoCallback = useCallback(() => {
  //   loadBooking();
  // }, []); //eslint-disable-line


  let handleGetFields = useCallback(() => {
    if(legal_type && id) {
      getContractUpdatedFields(id, {client_type: legal_type})
          .then(response => {
            const payload = {
              id: id,
              fields: response,
              format: format,
              created_at: created_at,
              rental_agreement: rental_agreement
            }

            dispatch(openContractInfoUpdateModal(payload));
          });
    }

  }, [legal_type, id, format, created_at, rental_agreement, dispatch]);

  // useEffect(() => {
  //   const defaultDataClient = {
  //     phone: client.phone,
  //     email: client.email,
  //   };
  //
  //   if ((client.clientable_type === CLIENT_INDIVIDUAL) && (legal_type === CLIENT_ENTITY)) {
  //     createClientsDetailsEntity(client.id, defaultDataClient)
  //         .then(() => {
  //           updateModalInfoCallback();
  //           updateBookingInfoCallback();
  //         });
  //   }
  //
  //   if ((client.clientable_type === CLIENT_ENTITY) && (legal_type === CLIENT_INDIVIDUAL)) {
  //     createClientsDetailsIndividual(client.id, defaultDataClient)
  //         .then(() => {
  //           updateModalInfoCallback();
  //           updateBookingInfoCallback();
  //         });
  //   }
  //
  // }, [legal_type, client, updateModalInfoCallback, updateBookingInfoCallback]);

  useEffect(() => {
    handleGetFields();
  }, [legal_type, handleGetFields])

  useEffect(() => {
    setLegal_type(client.clientable_type)
  }, [client.clientable_type])


  const handleChange = (e) => {
    const {name, value} = e.target;

    setState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleChangeAutocomplete = (value, key) => {
    setState(prev => ({
      ...prev,
      [key]: value
    }));
  }

  const handleChangeCheckbox = (e) => {
    const {checked} = e.target;

    // if (type === 'checkbox') {
    //   setHandleModal(true);
    //   return;
    // }

    setLegal_type(checked ? CLIENT_ENTITY : CLIENT_INDIVIDUAL);
  };

  const handleCloser = () => {
    setHandleModal(false);
  };

  const handleChangeType = () => {
    if (!legal_type) {
      setLegal_type(CLIENT_ENTITY);
    }
    if (legal_type === CLIENT_ENTITY) {
      setLegal_type(CLIENT_INDIVIDUAL);
    }
    if (legal_type === CLIENT_INDIVIDUAL) {
      setLegal_type(CLIENT_ENTITY);
    }
  };

  const handleChangeDate = (key, value) => {

    setState(prevState => ({
      ...prevState,
      [key]: key === 'renterBirthDay'
          ? value
          : moment(value, 'DD/MM/YYYY').format(DATE_FORMAT.DD_MM_YYYY)
    }));
  };


  const handleSubmit = () => {
    const validate = validationLength(state);

    setLoading(true);

    if (Object.keys(validate).length > 0) {
      setErrors(new Errors(validate));
      setLoading(false);
      return;
    }

    const data = {
      ...state,
      changed_client_type: legal_type,
      city: state.city.id,
      renterCountryCitizenship: state.renterCountryCitizenship?.iso,
      phone: reformatPhoneNumber(state.phone)
    };

    sendContractUpdatedFields(id, data)
        .then(() => {
          setErrors(new Errors({}));
          closeModal();
          loadBooking();
          setLoading(false);

          downloadBookingContract(id, {responseType: BLOB_FILE}, format)
              .then(response => {
                let fileFormat = getFormatTypeDownloadDocument(response.type);
                fileDownload(response, `${rental_agreement} от ${created_at}.${fileFormat}`);
                dispatch(openSnackbar(SUCCESS, 'Договор успешно сгенерирован'));


                // //send generating document from google cloud
                // uploadGoogleCloudDoc(id, response, response.type, fileFormat);
              })
              .catch(errors => {
                setErrors(new Errors(errors));
              });


        })
        .catch(errors => {
          setLoading(false);
          setErrors(new Errors(errors));
        });
  };


  return (
      <FormContainer>
        <FormRow>
          <FormColumn sm={12}
                      style={{
                        justifyContent: 'center',
                        display: 'flex'
                      }}>
            <Grid item xs={12} sm={4}>
              <Grid container
                    spacing={2}>
                <Grid item>Физ.лицо</Grid>

                <DialogsRemoveWithCustomField component={() => <SwitchField style={{marginRight: -11}}
                                                                            value={legal_type === CLIENT_ENTITY ? true : false}
                                                                            name="legal_type"
                                                                            onChange={handleChangeCheckbox}/>}
                                              isOpen={handleModal}
                                              closer={handleCloser}
                                              remover={handleChangeType}
                                              question="Изменить тип клиента?"
                                              question_text="(После переключение между физ. и юр. лицом все старые реквизиты удаляются)"
                                              positive_answer="Да"
                                              negative_answer="Нет"/>
                <Grid item>Юр. лицо</Grid>
              </Grid>
            </Grid>

          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn sm={12}>
            <Title title="Клиент"
                   size="md"/>
          </FormColumn>

          {fieldToRender.filter(x => x.individual === true)
              .map(field => <BookingUpdateContractInfoRow key={field.name}
                                                          errors={errors}
                                                          state={state}
                                                          handleChangeAutocomplete={handleChangeAutocomplete}
                                                          handleChangeDate={handleChangeDate}
                                                          handleChange={handleChange}
                                                          {...field}/>)}

        </FormRow>

        <FormRow>
          <FormColumn sm={12}>
            <Title title="Договор"
                   size="md"/>
          </FormColumn>
          {fieldToRender.filter(x => x.contract === true)
              .map(field => <BookingUpdateContractInfoRow key={field.name}
                                                          errors={errors}
                                                          state={state}
                                                          // handleChangeAutocomplete={handleChangeAutocomplete}
                                                          handleChangeDate={handleChangeDate}
                                                          handleChange={handleChange}
                                                          {...field}/>)}

        </FormRow>

        <FormRow>
          <FormColumn sm={12}>
            <Title title="Объект"
                   size="md"/>
          </FormColumn>
          {fieldToRender.filter(x => x.realty === true)
              .map(field => <BookingUpdateContractInfoRow key={field.name}
                                                          errors={errors}
                                                          state={state}
                                                          handleChangeDate={handleChangeDate}
                                                          handleChange={handleChange}
                                                          {...field}/>)}

        </FormRow>

        <FormRow>
          <FormColumn xs="auto"
                      sm="auto"
                      style={{marginLeft: 'auto'}}>
            <ButtonWithProgress loading={loading}
                                onClick={handleSubmit}
                                label="Сохранить"/>
          </FormColumn>
        </FormRow>
      </FormContainer>
  );
};

export default memo(BookingUpdateContractInfoModalForm);
