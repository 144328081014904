import React, { useEffect } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { linkTochkaTransaction } from '../../actions/tochkaActions';
import PageHeader from '../../components/PageHeader';
import TochkaFilter from '../../components/Tochka/TochkaFilter';
import TochkaBalance from '../../components/Tochka/TochkaBalance';
import TableTemplate from '../../components/TableTemplate';
import { TOCHKA_PAYMENTS_HEADERS } from '../../components/TableConstants';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import ButtonWithProgress from '../../components/ButtonWithProgress';

const TochkaPaymentsListPage = () => {
  const { setPageTitle } = usePageTitle();
  const { data: payments, meta, loading, refetch: loadPayments } = useFetch({
    url: API_ROUTE.tochkaPayments,
    hasMeta: true,
    oldResponseFormat: true
  });

  useEffect(() => {
    setPageTitle('Лог платежей по банку');
  });


  const handleMove = (id) => {
    linkTochkaTransaction(id)
      .then(() => {
        loadPayments();
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <Grid container
          spacing={ 2 }>
      <Grid item xs={ 12 }>
        <Grid container
              spacing={ 2 }>
          <Grid item>
            <PageHeader title="Лог платежей по банку"/>
          </Grid>
          <Grid item
                style={ { marginLeft: 'auto' } }
                xs={ 12 }
                sm={ 12 }
                md={ 6 }
                lg={ 4 }>
            <TochkaBalance loadTochkaPayments={ loadPayments }/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <PageHeader hasBottom
                    filter={ <TochkaFilter/> }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate meta={ meta }
                       data={ payments }
                       headers={ TOCHKA_PAYMENTS_HEADERS }
                       loading={ loading }>
          { payments && payments.map(payment => (
            <TableRow key={ payment.id } hover>
              <TableCell>{ payment.payment_date }</TableCell>
              <TableCell>{ payment.amount > 0 ? payment.amount : null }</TableCell>
              <TableCell>{ payment.amount < 0 ? payment.amount : null }</TableCell>
              <TableCell style={ { maxWidth: 400 } }>{ payment.counterparty_name }</TableCell>
              <TableCell style={ { maxWidth: 400 } }>{ payment.payment_purpose }</TableCell>
              <TableCell>{ payment.counterparty_bank_bic }</TableCell>
              <TableCell>{ payment.counterparty_bank_name }</TableCell>
              <TableCell>
                { payment.manager_id

                  ? <React.Fragment>
                    { payment.manager_id.name }<br/>
                    { payment.manager_id.created_at }
                  </React.Fragment>

                  : <ButtonWithProgress label={ 'Провел' }
                                        onClick={ () => handleMove(payment.id) }/>

                }

              </TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>

    </Grid>
  );
};

export default withRouter(TochkaPaymentsListPage);
