import React, { PureComponent } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './StatisticLogs.scss';
import ApartmentLogs from '../ApartmentLogs';
import Loader from '../Loader';
import Pagination from '../Pagination';

class StatisticLogs extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      current_page: 1,
      meta: [],
      apartmentLogs: [],
      loading: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevState.current_page !== this.state.current_page ) {
      this.fetchLogs();
    }
  }

  componentDidMount() {
    this.fetchLogs();
  }

  fetchLogs = () => {
    const { realtyId } = this.props;
    const { current_page } = this.state;
    const query = queryString.parse(this.props.location.search);

    this.setState((prev) => ( { ...prev, loading: true } ));


    const search = queryString.stringify({
      ...query,
      page: current_page
    });

    axios.get(`/statistics/payments?realty_id=${ realtyId }&${ search }`)
      .then(response => {
        this.setState({
          apartmentLogs: response.data.results.data,
          meta: response.data.results.meta
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState((prev) => ( { ...prev, loading: false } ));
      });
  };

  handlePageChange = (e, page) => {
    this.setState((prev) => ( {
      ...prev,
      current_page: page
    } ));

  };

  render() {
    const { loading, meta, apartmentLogs } = this.state;
    const { t } = this.props;

    if ( loading ) return <Loader/>;

    if ( apartmentLogs.length === 0 ) {
      return (
        <div className="apartment-no-logs">{ t('Логи за текущее время отсутствуют') }</div>
      );
    }

    return (
      <React.Fragment>
        <div className="apartment-logs">
          { apartmentLogs.map((log) => <ApartmentLogs key={ log.id } { ...log }/>) }

          { meta.last_page > 1 && (
            <Pagination meta={ meta }
                        wrapComponent={ () => <Grid item xs={ 12 }
                                                    style={ {
                                                      paddingTop: `24px`,
                                                      display: `flex`,
                                                      justifyContent: 'center'
                                                    } }/> }
                        onChange={ this.handlePageChange }/>
          ) }
        </div>
      </React.Fragment>
    );
  }
}


export default withRouter(withTranslation()(StatisticLogs));
