import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Grid, Link, TableCell, TableRow } from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import ModeratorCreateForm from './../../components/Moderator/ModeratorCreateForm';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import ModeratorRemove from '../../components/Moderator/ModeratorRemove';
import PageHeader from '../../components/PageHeader';
import AddingButton from '../../components/AddingButton';
import { PlusIcon } from '../../icons';
import { generateActiveStrStatus } from '../../components/Tools';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { MODERATORS_HEADERS } from '../../components/TableConstants';
import { useTranslation } from 'react-i18next';

const ModeratorsListPage = () => {
  const { setPageTitle } = usePageTitle();
  const {t} = useTranslation();
  const { data: moderators, loading, meta, refetch: loadModerators } = useFetch({
    url: API_ROUTE.moderators,
    oldResponseFormat: true,
    hasMeta: true
  });

  useEffect(() => {
    setPageTitle('Модераторы');
  });


  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Модераторы"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление модератора"
                        button={ props => <AddingButton { ...props }
                                                        tooltip="Добавить модератора"
                                                        icon={ () => <PlusIcon/> }/> }>
              <ModeratorCreateForm loadModerators={ loadModerators }/>
            </FormDrawer>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate data={ moderators }
                       meta={ meta }
                       loading={ loading }
                       headers={ MODERATORS_HEADERS }>
          { moderators && moderators.map(moderator => (
            <TableRow key={ moderator.id } hover>
              <TableCell>
                { moderator.update_url
                  ? <Link color="inherit"
                          component={ React.forwardRef((props, ref) => (
                            <RouterLink
                              { ...props }
                              ref={ ref }
                              to={ `/moderators/${ moderator.moderator_id }` }
                            />
                          )) }
                  >
                    <b>{ moderator.name }</b>
                  </Link>

                  : <b>{ moderator.name }</b>
                }

              </TableCell>
              <TableCell>{ moderator.email }</TableCell>
              <TableCell>{ generateActiveStrStatus(moderator.active, t) }</TableCell>
              <TableCell padding="none"
                         style={ { width: 75 } }>
                { moderator.delete_url
                  ? <ModeratorRemove id={ moderator.moderator_id }
                                     loadModerators={ loadModerators }/>

                  : null
                }

              </TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>

    </Grid>
  );
};

export default connect(null, { openSnackbar })(withRouter(ModeratorsListPage));
