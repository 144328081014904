import React, { useCallback, useEffect } from 'react';
import './StatisticCompny.scss';
import StatisticIncome from '../StatisticIncome/StatisticIncome';
import Efficiency from '../Efficiency/Efficiency';
import StatisticPayments from '../StatisticPayments';
import { getFormatNumber } from '../Tools';
import StatisticCompanyOutcome from '../StatisticCompanyOutcome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { fetchStatisticCompany } from '../../actions/statisticsActions';


const StatisticCompany = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const company = useSelector(state => state.statistic.company);
  const { date_from, ...query } = queryString.parse(location.search);

  useEffect(() => {
    let dateTo = parseInt(moment.utc().format('X')),
      lastMonth = moment().subtract('1', 'month'),
      dateFrom = parseInt(moment(lastMonth).format('X'));

    const params = {
      ...query,
      date_from: date_from ? date_from : dateFrom,
      date_to: dateTo
    };

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(params),
    });
  }, []);// eslint-disable-line


  let loadCompanyStatistic = useCallback(() => {
    const { date_from, date_to, ...query } = queryString.parse(location.search);

    if ( date_to && date_from ) {
      dispatch(fetchStatisticCompany({ date_to, date_from, ...query }));
    }

  }, [dispatch, location.search]);

  useEffect(() => {
    loadCompanyStatistic();
  }, [loadCompanyStatistic]);


  return (
    <div className="statistic-company">
      <div className="statistic-company__wrapper">

        <div className="statistic-company__part statistic-company__profit">
          <StatisticIncome income={ company?.income }
            // yearly_profit={ company?.actual_yearly_profit }
                           outcome={ company?.outcome }
                           profit={ company?.profit }
                           orange
                           title={ `Общий доход` }/>

        </div>

        <div className="statistic-company__part statistic-company__efficiency">
          <Efficiency title={ `Общая эффективность` }
                      realty_count={ company?.realty_count }
                      occupied_count={ company?.occupied_count }
            // actual_yearly_profit={company?.actual_yearly_profit}
                      percentage={ company?.avg_occupation_percentage }
                      profit={ company?.actual_yearly_profit }/>
        </div>
        <div className="statistic-company__part statistic-company__payments">
          <StatisticPayments paidRent={ company?.paid_rent_sum }
                             pendingPayments={ company?.payment_expected_sum }/>
        </div>
        { ( company?.staff_outcome || company?.realty_outcome )
          ? <div className="statistic-company__part statistic-company__outcome">
            <StatisticCompanyOutcome staff_outcome={ getFormatNumber(company?.staff_outcome) }
                                     realty_outcome={ getFormatNumber(company?.realty_outcome) }/>
          </div>
          : null
        }
      </div>
    </div>

  );
};


export default StatisticCompany;
