import React from 'react';
import './Title.scss';
import { useTranslation } from 'react-i18next';

const Title = ({ title, className = '', size = '', style, ...props }) => {
  const { t } = useTranslation();

  return (
    <div style={ style }
         className={ `title ${ className }
                      ${ size ? `title--${ size }` : '' }
                      ` }>
      { t(title) }
    </div>
  );
};

export default Title;
