import React, { PureComponent } from 'react';
import SelectField from '../SelectField';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class FilterSelectField extends PureComponent {

  handleChange = (e) => {
    const { value } = e.target;
    const query = queryString.parse(this.props.location.search);
    const { type } = this.props;

    const newQuery = {
      ...query,
      [type]: value
    };

    if ( query.page ) {
      newQuery.page = 1;
    }


    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });

  };

  render() {
    const { type, label, options } = this.props;
    const query = queryString.parse(this.props.location.search);

    return (
      <SelectField { ...this.props }
                   value={ query[type] || '' }
                   label={ label }
                   options={ options }
                   onChange={ this.handleChange }
      />
    );
  }
}

export default withRouter(FilterSelectField);
