import React from 'react';
import { connect } from 'react-redux';
import UtilitiesMetersDataCreateForm from './UtilitiesMetersDataCreateForm';
import FormDrawerWithPayload from '../../FormDrawerWithPayload/FormDrawerWithPayload';
import { closeMetersDataModal } from '../../../actions/formDrawerActions';

const UtilitiesMeterDataModal = ({ isOpen, closeMetersDataModal, loadMetersData, ...props }) => {

  return (
    <React.Fragment>
      <FormDrawerWithPayload isOpen={ isOpen }
                             closeModal={ closeMetersDataModal }>
        <UtilitiesMetersDataCreateForm closeModal={ closeMetersDataModal }
                                       loadMetersData={ loadMetersData }/>
      </FormDrawerWithPayload>
    </React.Fragment>
  );
};

let matStateToProps = (state) => ( {
  isOpen: state.formDrawer.metersData.isOpen
} );

let mapDispatchToProps = (dispatch) => ( {
  closeMetersDataModal: () => dispatch(closeMetersDataModal())
} );


export default connect(matStateToProps, mapDispatchToProps)(UtilitiesMeterDataModal);
