import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from '../../components/Pagination';
import { fetchBookingsUtilitiesInvoicesGroup } from '../../actions/bookingsActions';
import { Grid } from '@material-ui/core';
import UtilitiesFilter from '../../components/Utilities/Invoices/UtilitiesInvoicesFilter';
import FormDrawer from '../../components/FormDrawer';
import UtilityInvoiceCreateForm from '../../components/Utilities/Invoices/UtilityInvoiceCreateForm';
import PageHeader from '../../components/PageHeader';
import { PlusIcon } from '../../icons';
import AddingButton from '../../components/AddingButton';
import { uuidv4 } from '../../components/Tools';
import UtilitiesInvoicesGroupRow from '../../components/Utilities/Invoices/UtilitiesInvoicesGroupRow';
import Loader from '../../components/Loader';
import { usePageTitle } from '../../hooks';


const UtilitiesInvoicesListPage = ({ ...props }) => {
  const location = useLocation();
  const { setPageTitle } = usePageTitle();
  const [loading, setLoading] = useState(false);
  const [invoicesGroups, setInvoicesGroups] = useState(null);
  const [meta, setMeta] = useState(null);


  let loadUtilityInvoicesGroups = useCallback(() => {
    const query = queryString.parse(location.search);

    setLoading(true);
    setInvoicesGroups([]);

    fetchBookingsUtilitiesInvoicesGroup(query)
      .then(response => {
        setInvoicesGroups(response.data);
        setMeta(response.meta);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.search]);


  useEffect(() => {
    setPageTitle('Коммунальные инвойсы');
    loadUtilityInvoicesGroups();
  }, [loadUtilityInvoicesGroups, setPageTitle]);


  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Коммунальные инвойсы"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление инвойса"
                        button={ props => <AddingButton { ...props }
                                                        icon={ () => <PlusIcon/> }/> }>
              <UtilityInvoiceCreateForm loadInvoices={ loadUtilityInvoicesGroups }/>
            </FormDrawer>
          </Grid>
        </Grid>
      </Grid>


      <Grid item xs={ 12 }>
        <UtilitiesFilter/>
      </Grid>


      { loading &&
        <Grid item
              xs={ 12 }>
          <Loader/>
        </Grid>
      }

      { invoicesGroups &&
        <Grid item xs={ 12 }>
          { invoicesGroups.map((invoiceGroup) => <UtilitiesInvoicesGroupRow key={ uuidv4() }
                                                                            { ...invoiceGroup }
                                                                            loadUtilityInvoices={ loadUtilityInvoicesGroups }/>) }
        </Grid>
      }


      { meta && ( meta?.last_page > 1 ) &&
        <Pagination
          meta={ meta }
          wrapComponent={ (props) => <Grid item xs={ 12 }
                                           style={ {
                                             paddingTop: `24px`,
                                             display: `flex`,
                                             justifyContent: 'center'
                                           } }
                                           { ...props }/> }
        />
      }

    </Grid>
  );
};

export default UtilitiesInvoicesListPage;
