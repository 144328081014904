import React from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const TextFieldWithIcon = ({
                             label,
                             value,
                             handleChange,
                             icon: Icon = null,
                             fullWidth = false,
                             // labelWidth = 60,
                             placeholder,
                             ...props
                           }) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined"
                 fullWidth>
      <InputLabel htmlFor={ label }>
        { t(label) }
      </InputLabel>
      <OutlinedInput

        id={ label }
        label={ t(label) }
        value={ value }
        onChange={ handleChange }
        placeholder={ t(placeholder) }
        endAdornment={ <InputAdornment position="end">
          <Icon/>
        </InputAdornment> }
        // labelWidth={ labelWidth }

        { ...props } />
    </FormControl>
  );
};

export default TextFieldWithIcon;
