import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import Errors from '../Errors';
import {
  createManagementCompanyTariff,
  fetchManagementCompany,
  fetchManagementCompanyTariffs,
  updateManagementCompany
} from '../../actions/managementCompanyActions';
import TextField from '../TextField';
import SwitchField from '../SwitchField';
import { Divider, Grid } from '@material-ui/core';
import Title from '../Title';
import TariffsFields from './TariffsFields';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { transformBasicUtilities } from '../Tools';
import ButtonWithProgress from '../ButtonWithProgress';
import { flex } from '../JSStylesConstants';
import { SUCCESS } from '../Constants';


class ManagementCompanyEditForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      management_company: {
        id: this.props.id,
        name: '',
        gspm: false,
        account_number: '',
        bank_bic: '',
        inn: '',
        counterparty_name: '',
        url: '',
        kpp: '',
        driver: null,

        utilities: []
      },
      errors: new Errors(),
      loading: false,
      loadingTariffs: false,
    };
  }

  componentDidMount() {
    const { management_company: { id } } = this.state;

    fetchManagementCompany(id).then(company => {
      this.setManagementCompany(company);
    });

    fetchManagementCompanyTariffs(id)
      .then(tariffs => {
        this.setTariffsCompany(tariffs);
      });

  }

  setTariffsCompany = (tariffs) => {
    this.setState(prevState => ( {
      management_company: {
        ...prevState.management_company,
        utilities: transformBasicUtilities(tariffs)
      }
    } ));
  };


  setManagementCompany = ({
                            name, id, gspm,
                            counterparty_account_number, counterparty_bank_bic, counterparty_inn,
                            counterparty_name, url, counterparty_kpp, driver
                          }) => {
    this.setState(prevState => ( {
      management_company: {
        ...prevState.management_company,
        id: id,
        name: name,
        gspm: gspm,
        account_number: counterparty_account_number,
        bank_bic: counterparty_bank_bic,
        inn: counterparty_inn,
        counterparty_name: counterparty_name,
        url: url,
        kpp: counterparty_kpp,
        driver: driver
      },
    } ));
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      management_company: {
        ...prevState.management_company,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };


  handleUtilitiesChange = (event, field) => {
    const { value, id } = event.target;

    let newUtilities;

    if ( field.length > 0 ) {
      newUtilities = Object.assign(...field, { price_per_unit: value });
    } else {
      newUtilities = {
        id: +id,
        price_per_unit: value
      };
    }


    this.setState(prevState => ( {
      management_company: {
        ...prevState.management_company,
        utilities: value.length > 0
          ? [...prevState.management_company.utilities.filter(x => x.id !== +id), newUtilities]
          : prevState.management_company.utilities.filter(x => x.id !== +id)
      }
    } ));
  };

  handleSubmit = () => {
    const { management_company } = this.state;
    this.setState({ loading: true });

    const data = {
      name: management_company.name,
      counterparty_account_number: management_company.account_number,
      counterparty_bank_bic: management_company.bank_bic,
      counterparty_inn: management_company.inn,
      counterparty_name: management_company.counterparty_name,
      gspm: management_company.gspm,
      url: management_company.url,
      counterparty_kpp: management_company.kpp
    };

    updateManagementCompany(this.props.id, data)
      .then(management_company => {
        this.setManagementCompany(management_company);
        this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
        this.setState(prevState => ( {
          ...prevState,
          loading: false,
          errors: new Errors({})
        } ));
      })
      .catch(errors => {
        this.setState({
          loading: false,
          errors: new Errors(errors)
        });
      });

  };


  handleSubmitTariffs = () => {
    const { management_company } = this.state;
    this.setState({ loadingTariffs: true });

    const data = {
      utilities: management_company.utilities
    };

    createManagementCompanyTariff(management_company.id, data)
      .then(tariffs => {
        this.setState(prevState => ( {
          management_company: {
            ...prevState.management_company,
            utilities: transformBasicUtilities(tariffs)
          },
          loadingTariffs: false
        } ));
        this.props.openSnackbar(SUCCESS, 'Тарифы обновлены успешно');
      })
      .catch(errors => {
        this.setState({
          loadingTariffs: false,
          errors: new Errors(errors)
        });
      });
  };

  render() {
    const { management_company, errors, loading, loadingTariffs } = this.state;

    return (
      <Grid container
            spacing={ 2 }
            style={ { paddingLeft: 24, paddingRight: 24 } }>
        <Grid item xs={ 12 }>
          <FormContainer>
            <FormRow>
              <FormColumn>
                <TextField error={ errors.getError('name') }
                           name="name"
                           type="text"
                           value={ management_company.name }
                           onChange={ this.handleChange }
                           label="Имя управ. компании"
                           placeholder="Введите имя"/>
              </FormColumn>
              <FormColumn>
                <TextField error={ errors.getError('url') }
                           name="url"
                           type="text"
                           value={ management_company.url }
                           onChange={ this.handleChange }
                           label="URL"
                           placeholder="Введите url"/>
              </FormColumn>
              <FormColumn>
                <TextField error={ errors.getError('counterparty_account_number') }
                           name="account_number"
                           type="text"
                           value={ management_company.account_number }
                           onChange={ this.handleChange }
                           label="Счёт получателя"
                           placeholder="Введите cчёт"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <TextField error={ errors.getError('counterparty_bank_bic') }
                           name="bank_bic"
                           type="text"
                           value={ management_company.bank_bic }
                           onChange={ this.handleChange }
                           label="БИК банка получателя"
                           placeholder="Введите БИК"/>
              </FormColumn>

              <FormColumn>
                <TextField error={ errors.getError('counterparty_inn') }
                           name="inn"
                           type="text"
                           value={ management_company.inn }
                           onChange={ this.handleChange }
                           label="ИНН получателя"
                           placeholder="Введите ИНН"/>
              </FormColumn>

              <FormColumn>
                <TextField error={ errors.getError('counterparty_kpp') }
                           name="kpp"
                           type="text"
                           value={ management_company.kpp }
                           onChange={ this.handleChange }
                           label="КПП получателя"
                           placeholder="Введите КПП"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <TextField error={ errors.getError('counterparty_name') }
                           name="counterparty_name"
                           type="text"
                           value={ management_company.counterparty_name }
                           onChange={ this.handleChange }
                           label="Получатель платежа"
                           placeholder="Введите получателя"/>
              </FormColumn>
              <FormColumn style={ flex }>
                <SwitchField error={ errors.getError('gspm') }
                             name="gspm"
                             value={ management_company.gspm }
                             onChange={ this.handleChange }
                             label="Система GSPM"/>
              </FormColumn>
              <FormColumn style={ flex }>
                <SwitchField error={ errors.getError('driver') }
                             name="driver"
                             value={ management_company.driver }
                             label={ `${ management_company.driver
                               ? `Электронный кабинет [${ management_company.driver }]`
                               : 'Электронный кабинет' }` }/>
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn xs={ 12 } sm={ 12 }
                          style={ { textAlign: 'right' } }>
                <Divider style={ { marginBottom: 16 } }/>

                <ButtonWithProgress loading={ loading }
                                    onClick={ this.handleSubmit }
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </Grid>

        <Grid item xs={ 12 }>
          <FormContainer>
            <FormRow>
              <Title size="md"
                     title="Тарифы"/>
            </FormRow>
            <FormRow>
              <FormColumn xs={ 12 } sm={ 12 }>
                <TariffsFields handleChange={ this.handleUtilitiesChange }
                               currentTariffs={ management_company.utilities }/>
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn xs={ 12 } sm={ 12 }
                          style={ { textAlign: 'right' } }>
                <Divider style={ { marginBottom: 16 } }/>

                <ButtonWithProgress loading={ loadingTariffs }
                                    onClick={ this.handleSubmitTariffs }
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, { openSnackbar })(ManagementCompanyEditForm);
