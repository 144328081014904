import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, TableCell, TableRow } from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import PermissionsCreateForm from '../../components/Permissions/PermissionsCreateForm';
import { PlusIcon } from '../../icons';
import AddingButton from '../../components/AddingButton';
import PageHeader from '../../components/PageHeader';
import { generateActiveStrStatus } from '../../components/Tools';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { PERMISSIONS_HEADERS } from '../../components/TableConstants';
import { useTranslation } from 'react-i18next';
import { PermissionsRemove } from '../../components/Permissions';

const PermissionsListPage = ({ ...props }) => {
  const { setPageTitle } = usePageTitle();
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle('Права доступа');
  });

  const { data, loading, refetch: loadRoles } = useFetch({
    url: API_ROUTE.roles
  });


  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Права доступа"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление прав доступа"
                        button={ props => <AddingButton { ...props }
                                                        icon={ () => <PlusIcon/> }/> }>
              <PermissionsCreateForm loadPermissions={ loadRoles }/>
            </FormDrawer>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate headers={ PERMISSIONS_HEADERS }
                       data={ data?.results }
                       loading={ loading }>
          { data?.results && data?.results.map(roles => (
            <TableRow key={ roles.id } hover>
              <TableCell>#{ roles.id }</TableCell>
              <TableCell>
                <Link
                  color="inherit"
                  component={ React.forwardRef((props, ref) => (
                    <RouterLink
                      { ...props }
                      ref={ ref }
                      to={ `/permissions/${ roles.id }` }
                    />
                  )) }
                >
                  <b>{ roles.name }</b>
                </Link>
              </TableCell>
              <TableCell>{ generateActiveStrStatus(roles.active, t) }</TableCell>
              <TableCell padding="none"
                         style={ { width: 75 } }>
                <PermissionsRemove loadPermissions={ loadRoles }
                                   id={ roles.id }/>
              </TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>

    </Grid>
  );
};

export default connect(null, { openSnackbar })(PermissionsListPage);
