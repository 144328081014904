import axios from 'axios';
import queryString from 'query-string';
import { API_ROUTE } from '../api/routes';


export const createUtilitiesMeterData = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_ROUTE.utilitiesMeterData, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

//utilities payments orders

export const fetchPaymentOrders = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/utilities/payment_orders?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createPaymentOrders = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/utilities/payment_orders`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const fetchPaymentOrdersLogs = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/payment-order-logs?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};
