import React, { useState } from 'react';
import './StatisticApartment.scss';
import Button from '../Button';
import { ArrowRightIcon } from '../../icons';
import StatusApartment from '../StatusApartment';
import CalendarApartment from '../CalendarApartment';
import Employment from '../Employment';
import Title from '../Title';
import StatisticIncome from '../StatisticIncome';
import Outcome from '../Outcome';
import Efficiency from '../Efficiency/Efficiency';
import { Link } from 'react-router-dom';
import StatisticLogs from '../StatisticLogs';
import NoPhoto from '../NoPhoto';
import Sale from '../Sale';
import { REALTY_STATUS, RENT_TYPE } from '../Constants';
import { REALTY_LIST_ROUTE } from '../RouterConstants';
import { useTranslation } from 'react-i18next';

const StatisticApartment = ({
                              id, name, status, utilities_debt, actual_tariff, calendar,
                              occupied_until, days_to_availability, days_occupation, percentage_occupation,
                              income, outcome, expected_yearly_profit, yearly_profit, profit, tariffs,
                              status_description, dateTo, dateFrom, actual_yearly_profit, image,
                              potential_sale_price, sale_price, ...props
                            }) => {
  const { t } = useTranslation();

  let [show, showLogs] = useState(false);

  let handlerLogs = () => {
    showLogs(!show);
  };

  return (
    <React.Fragment>
      <div className="statistic-apartment">
        <div className="statistic-apartment__top">

          <div className="statistic-apartment__section section-main">
            <div className="statistic-apartment__image">
              { image
                ? <img src={ image } alt={ name }/>
                : <NoPhoto/>
              }

            </div>
            <div className="statistic-apartment__id">
              #{ id }
            </div>
            <Link to={ `${ REALTY_LIST_ROUTE }/${ id }` }
                  className="statistic-apartment__name">
              <Title title={ name }
                     size={ 'md' }/>
            </Link>

          </div>

          <div className="statistic-apartment__section section-tariff">
            <div className="statistic-apartment__piece">
              <div className="statistic-apartment__type">
                { t('Тип договора') }
              </div>
              <div className="statistic-apartment__date">
                { status === REALTY_STATUS.engaged
                  ? actual_tariff ? actual_tariff?.rent_type : tariffs[0]?.rent_type
                  : tariffs[0]?.rent_type ? tariffs[0]?.rent_type : null
                }
              </div>
            </div>
            <div className="statistic-apartment__piece">
              <div className="statistic-apartment__type">
                { t('Тариф') }
              </div>
              <div className="statistic-apartment__price">
                { status === REALTY_STATUS.engaged
                  ? actual_tariff ? actual_tariff.price : tariffs[0]?.price
                  : tariffs[0]?.price ? tariffs[0]?.price : null
                }

              </div>
            </div>
          </div>

          { ( actual_tariff && actual_tariff.rent_type === RENT_TYPE.short )
            ? <div className="statistic-apartment__section">
              <CalendarApartment calendar={ calendar }/>
            </div>
            : <div className="statistic-apartment__section section-status">
              <StatusApartment status={ status }
                               status_description={ status_description }
                               occupied_until={ occupied_until }
                               days_to_availability={ days_to_availability }/>

            </div>
          }


        </div>

        <div className="statistic-apartment__bottom">
          <div className="statistic-apartment__wrapper">
            <div className="statistic-apartment__part statistic-apartment__employment">
              <Employment percent={ percentage_occupation }
                          days={ days_occupation }/>
            </div>

            { !!utilities_debt
              ? (
                <div className="statistic-apartment__part statistic-apartment__rate">
                  <Outcome utilities={ utilities_debt }/>
                </div> )
              : null
            }

            { ( !!income || !!yearly_profit || !!profit || !!outcome )
              ? <div className="statistic-apartment__part statistic-apartment__profit">
                <StatisticIncome income={ income }
                                 yearly_profit={ yearly_profit }
                                 outcome={ outcome }
                                 profit={ profit }
                                 green
                                 title={ 'Общий доход' }/>
              </div>
              : null
            }


            <div className="statistic-apartment__part statistic-apartment__efficiency">
              <Efficiency title={ 'Эффективность' }
                          percentage={ percentage_occupation }
                          profit={ actual_yearly_profit }
                          yearly_profit={ expected_yearly_profit }/>
            </div>

            { ( potential_sale_price || sale_price )
              ? <div className="statistic-apartment__part statistic-apartment__sale">
                <Sale title={ 'Продажа' }
                      potential_sale_price={ potential_sale_price }
                      sale_price={ sale_price }/>
              </div>
              : null
            }
          </div>

          <div className={ `statistic-apartment__button` }
               style={ { cursor: 'pointer' } }
               onClick={ handlerLogs }>
            <div
              className={ `statistic-apartment__button-title 
                        ${ show ? 'statistic-apartment__button-title--show' : '' }` }>
              { t('Лог платежей') }
            </div>
            <Button className={ `statistic-apartment__arrow 
                                ${ show ? 'statistic-apartment__arrow--show' : '' }` }
                    icon={ () => <ArrowRightIcon/> }/>
          </div>
        </div>

        { show ? <StatisticLogs realtyId={ id }/> : null }
      </div>

    </React.Fragment>
  );
};

export default StatisticApartment;
