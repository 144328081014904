import React from 'react';
import { MEETING_STATUSES } from '../SelectConstats';
import SelectField from '../SelectField';

const MeetingStatusSelectField = ({ label, ...props }) => {
  return (
    <SelectField options={ MEETING_STATUSES }
                 fullWidth
                 label="Статус встречи"
                 type="meeting_status"
                 { ...props }/>
  );
};

export default MeetingStatusSelectField;
