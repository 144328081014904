import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import TextFieldWithIcon from './TextFiledWithIcon';
import { SearchIcon } from '../icons';
import { ENTER_KEY } from './Constants';
// import useDebounce from './Debounce';

const SearchField = ({ placeholder, fullWidth, searchField, labelWidth, label, ...props }) => {
  const search = queryString.parse(props.location.search);
  const [value, setValue] = useState(search[searchField] || '');


  useEffect(() => {
    setValue(search[searchField] || '');
    //eslint-disable-next-line
  }, [props.location.search]);

  // const debouncedSearchTerm = useDebounce(value, 1000);
  //
  // useEffect(() => {
  //       if (debouncedSearchTerm) {
  //         handleSearch(debouncedSearchTerm)
  //       } else {
  //         handleSearch('');
  //         setValue( '')
  //       }
  //     }, [debouncedSearchTerm, props.location.search]
  // );

  const pressEnter = (e) => {
    if ( e.keyCode === ENTER_KEY ) {
      handleSearch(value);
    }
  };

  let handleSubmitSearch = () => {
    handleSearch(value);
  };

  let handleSearch = (searchValue) => {
    const params = queryString.parse(props.location.search);

    let newQuery = {
      ...params,
      [searchField]: searchValue
    };

    //if we have pagination from page we skip page from 1
    if ( params.page ) newQuery.page = 1;


    if ( !searchValue ) delete newQuery[searchField];

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });
  };


  return (
    <TextFieldWithIcon value={ value }
                       fullWidth={ fullWidth }
                       handleChange={ e => setValue(e.target.value) }
                       onKeyDown={ e => pressEnter(e) }
                       placeholder={ placeholder }
                       icon={ () => <SearchIcon style={ { cursor: 'pointer' } } onClick={ handleSubmitSearch }/> }
                       label={ label }/>
  );
};

export default withRouter(SearchField);
