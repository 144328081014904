import React from 'react';
import TextField from '../TextField';

const TariffField = ({ id, handleChange, name, field, ...props }) => {

  return (
    <React.Fragment>
      <TextField key={ id }
                 id={ id.toString() }
                 type="number"
                 name={ name }
                 value={ field[0]?.price_per_unit ? field[0].price_per_unit : '' }
                 onChange={ event => handleChange(event, field) }
                 label={ name }
                 placeholder="Введите значение"
      />
    </React.Fragment>

  );
};

export default TariffField;
