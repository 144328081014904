import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import SwitchField from '../SwitchField';
import { Divider } from '@material-ui/core';
import Errors from '../Errors';
import { openSnackbar } from '../../actions/snackbarActions';
import { fetchModerator, updateModerator } from '../../actions/moderatorsActions';
import RolesSelect from '../Moderator/RolesSelect';
import PasswordField from '../PasswordField';
import ButtonWithProgress from '../ButtonWithProgress';
import { flex } from '../JSStylesConstants';
import { SUCCESS } from '../Constants';

class ModeratorEditForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      moderator: {
        id: this.props.id,
        name: '',
        email: '',
        password: '',
        roleId: '',
        active: false,
      },
      errors: new Errors(),
      showPassword: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { moderator: { id } } = this.state;

    fetchModerator(id).then(moderator => {
      this.setModerator(moderator);
    });
  }

  setModerator = ({ moderator_id, name, email, role, active }) => {
    this.setState(prevState => ( {
      moderator: {
        ...prevState.moderator,
        id: moderator_id,
        name: name,
        email: email,
        roleId: role.id,
        active: active,
      },
    } ));
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      moderator: {
        ...prevState.moderator,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };

  handlePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };


  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    const { id, ...moderator } = this.state.moderator;
    const data = {
      name: moderator.name,
      email: moderator.email,
      password: moderator.password,
      role_id: moderator.roleId,
      active: moderator.active,
    };

    updateModerator(id, data)
      .then(moderator => {
        this.setModerator(moderator);
        this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
        this.setState({
          loading: false,
          errors: new Errors({})
        });
      })
      .catch(errors => {
        this.setState({
          loading: false,
          errors: new Errors(errors)
        });
      });
  };

  render() {
    const { moderator, errors, showPassword, loading } = this.state;

    return (
      <div style={ { paddingLeft: 24, paddingRight: 24 } }>
        <FormContainer>
          <FormRow>
            <FormColumn>
              <TextField error={ errors.getError('name') }
                         name="name"
                         value={ moderator.name }
                         onChange={ this.handleChange }
                         label="Имя"
                         placeholder="Введите имя"/>
            </FormColumn>
            <FormColumn>
              <TextField error={ errors.getError('email') }
                         name="email"
                         value={ moderator.email }
                         onChange={ this.handleChange }
                         label="Электронная почта"
                         placeholder="Введите электронную почту"/>
            </FormColumn>
            <FormColumn>
              <PasswordField error={ errors.getError('password') }
                             name="password"
                             type={ showPassword ? 'text' : 'password' }
                             value={ moderator.password }
                             onChange={ this.handleChange }
                             label="Пароль"
                             placeholder="Введите пароль"
                             handlePassword={ this.handlePassword }
                             showPassword={ showPassword }/>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <RolesSelect value={ moderator.roleId }
                           name="roleId"
                           error={ errors.getError('role_id') }
                           onChange={ this.handleChange }/>
            </FormColumn>
            <FormColumn style={ flex }>
              <SwitchField error={ errors.getError('active') }
                           name="active"
                           value={ moderator.active }
                           onChange={ this.handleChange }
                           label="Активность"/>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn xs={ 12 }
                        sm={ 12 }
                        style={ { textAlign: 'right' } }>
              <Divider style={ { marginBottom: 16 } }/>

              <ButtonWithProgress loading={ loading }
                                  onClick={ this.handleSubmit }
                                  label="Сохранить"/>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </div>
    );
  }
}

export default connect(null, { openSnackbar })(ModeratorEditForm);
