import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ( {
  root: {
    '& .MuiInputBase-multiline': {
      padding: `15px 14px`,
    },
  },
} ));


const TextField = ({ label, placeholder, variant = 'outlined', ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MuiTextField
        { ...props }
        label={ t(label) }
        placeholder={ t(placeholder) }
        className={ classes.root }
        fullWidth
        error={ !!props.error }
        helperText={ props.helperText || '' }
        InputLabelProps={ {
          shrink: true
        } }
        variant={ variant }
        aria-describedby={ props.helperText }
      />
      {/*{ props.helperText &&*/ }
      {/*  <FormHelperText id={ props.helperText } style={ { color: '#f44336', marginTop:' 0' } }>{ props.helperText }</FormHelperText>*/ }
      {/*}*/ }
    </React.Fragment>

  );
};

export default TextField;
