import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const MuiButtonLink = ({ title, to, ...props }) => {
  const {t} = useTranslation();

  return (
    <Button component={ Link }
            to={ `${ process.env.PUBLIC_URL }/${ to }` }
            variant="outlined"
            { ...props }>
      { t(title) }
    </Button>
  );
};

export default MuiButtonLink;
