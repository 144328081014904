import React from 'react';
import './ApartmentLogs.scss';
import { getCurrentCurrency, getFormatNumber } from '../Tools';

const ApartmentLogs = ({ ...props }) => {
  return (
    <div className="log">
      <div className="log__inner">
        <div className="log__part log__id">
          #{ props.id }
        </div>
        <div className="log__part log__date">
          { props.created_at }
        </div>
        <div className="log__part log__status">
          { props.canceled
            ?
            <div className="log__status-icon">
              <i className="icon icon-eror"/>
            </div>
            : null
          }
          <span className={ `log__status-text ${ props.canceled ? 'log__status-text--error' : '' }` }>
            { props?.paymentable?.name }
          </span>
        </div>
        <div className="log__part log__title">
          { props.action }
        </div>
        <div className="log__part log__period">
          { props.period }
        </div>
        <div className={ `log__part log__sum ${ props.amount > 0 ? 'log__sum--positive' : 'log__sum--negative' }` }>
          { getFormatNumber(props.amount) } { getCurrentCurrency() }
        </div>
      </div>
    </div>
  );
};

export default ApartmentLogs;
