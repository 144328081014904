import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import Errors from '../Errors';
import FormColumn from '../FormColumn';
import { Divider } from '@material-ui/core';
import SwitchField from '../SwitchField';
import ClientDetailIndividualFields from './ClientDetailsIndividualFields';
import ClientDetailsEntityFields from './ClientDetailsEntityFields';
import { createClientsDetailsEntity, createClientsDetailsIndividual } from '../../actions/clientsActions';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import moment from 'moment';
import ButtonWithProgress from '../ButtonWithProgress';
import { CLIENT_TYPE, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { reformatPhoneNumber } from '../Tools';

const initialState = {
  individual: {
    first_name: '',
    last_name: '',
    birth_date: '',
    middle_name: '',
    place_of_birth: '',
    iso: '',
    email: '',
    phone: '',
    place_of_registration: '',


    passport_series: '',
    passport_id: '',
    passport_issued: '',
    passport_validity: '',
    passport_department_id: '',
    passport_issue_date: '',
    mailing_address: '',
  },
  entity: {
    title: '',
    inn: '',
    kpp: '',
    ogrn: '',
    place_of_registration: '',
    mailing_address: '',
    bank_account: '',
    bank_title: '',
    bank_bic: '',
    correspondent_account: '',
    head_position: '',
    head_full_name: '',
    acts_on: '',
    email: '',
    phone: ''
  },
  id: '',
  legal_type: false,
  errors: new Errors(),
  loading: false,
};


class ClientDetailsEditForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const { client } = this.props;

    this.setClient(client);
  }

  setClient = ({ id, first_name, last_name, email, phone, clientable, clientable_type }) => {
    this.setState(prevState => ( {
      ...prevState,
      individual: {
        ...prevState.individual,
        first_name: first_name,
        last_name: last_name,
        email: clientable?.email ? clientable?.email : email,
        phone: clientable?.phone ? clientable?.phone : phone,

        birth_date: moment(clientable?.birth_date).format('DD/MM/YYYY'),
        middle_name: clientable?.middle_name,
        place_of_birth: clientable?.place_of_birth,
        iso: clientable?.iso,
        place_of_registration: clientable?.place_of_registration,
        passport_series: clientable?.passport_series,
        passport_id: clientable?.passport_id,
        passport_issued: clientable?.passport_issued,
        passport_validity: clientable?.passport_validity,
        passport_department_id: clientable?.passport_department_id,
        passport_issue_date: clientable?.passport_issue_date,
        mailing_address: clientable?.mailing_address,
      },
      entity: {
        ...prevState.entity,
        email: clientable?.email ? clientable?.email : email,
        phone: clientable?.phone ? clientable?.phone : phone,

        title: clientable?.title,
        inn: clientable?.inn,
        kpp: clientable?.kpp,
        ogrn: clientable?.ogrn,
        place_of_registration: clientable?.place_of_registration,
        mailing_address: clientable?.mailing_address,
        bank_account: clientable?.bank_account,
        bank_title: clientable?.bank_title,
        bank_bic: clientable?.bank_bic,
        correspondent_account: clientable?.correspondent_account,
        head_position: clientable?.head_position,
        head_full_name: clientable?.head_full_name,
        acts_on: clientable?.acts_on,
      },
      id: id,
      legal_type: clientable_type === CLIENT_TYPE.entity,
      errors: new Errors(),
    } ));

  };

  handleChangeDate = (key, value) => {

    this.setState(prevState => ( {
      individual: {
        ...prevState.individual,
        [key]: value
      }
    } ));
  };

  handleCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      individual: {
        ...prevState.individual,
      },
      entity: {
        ...prevState.entity,
      },
      errors: new Errors(),
      [name]: type === 'checkbox' ? checked : value,
    } ));
  };

  handleChangeAutocomplete = (value, type) => {
    this.setState(prevState => ( {
      individual: {
        ...prevState.individual,
        [type]: value?.iso,
        passport_series: '',
        passport_id: '',
        passport_issued: '',
        passport_department_id: '',
        passport_issue_date: '',
      }
    } ));
  };


  handleIndividualChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      individual: {
        ...prevState.individual,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };

  handleEntityChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      entity: {
        ...prevState.entity,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };


  handleSubmit = () => {
    const { entity, individual, legal_type, id } = this.state;

    this.setState({ loading: true });

    if ( legal_type ) {
      let formData = {
        ...entity,
        phone: reformatPhoneNumber(entity.phone)
      };

      createClientsDetailsEntity(id, formData)
        .then(client => {
          this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.client.edit.success);
          this.setClient(client);
          this.setState({ loading: false });
        })
        .catch((errors) => {
          this.setState({
            loading: false,
            errors: new Errors(errors)
          });
        });
    } else {
      let formData = {
        ...individual,
        phone: reformatPhoneNumber(individual.phone)
      };
      createClientsDetailsIndividual(id, formData)
        .then(client => {
          this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.client.edit.success);
          this.setClient(client);
          this.setState({ loading: false });
        })
        .catch(errors => {
          this.setState({
            loading: false,
            errors: new Errors(errors)
          });
        });
    }

  };

  render() {
    const { entity, individual, errors, legal_type, loading } = this.state;
    return (
      <div style={ { paddingLeft: 24, paddingRight: 24 } }>
        <FormContainer>
          <FormRow>
            <FormColumn>
              <SwitchField value={ legal_type }
                           label="Юр. лицо"
                           name="legal_type"
                           onChange={ this.handleCheckboxChange }/>
            </FormColumn>
          </FormRow>
          { !legal_type &&
            <ClientDetailIndividualFields errors={ errors }
                                          handleChangeAutocomplete={ this.handleChangeAutocomplete }
                                          handleChangeDate={ this.handleChangeDate }
                                          handleIndividualChange={ this.handleIndividualChange }
                                          individual={ individual }/>
          }


          { legal_type &&
            <ClientDetailsEntityFields errors={ errors }
                                       handleEntityChange={ this.handleEntityChange }
                                       entity={ entity }/>
          }
          <FormRow>
            <FormColumn xs={ 12 }
                        sm={ 12 }
                        style={ { textAlign: 'right' } }>
              <Divider style={ { marginBottom: 16 } }/>

              <ButtonWithProgress loading={ loading }
                                  onClick={ this.handleSubmit }
                                  label="Сохранить"/>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </div>
    );
  }
}

export default connect(null, { openSnackbar })(ClientDetailsEditForm);
