import {
  APARTMENT, NOTIFICATION_TYPE,
  OFFICE,
  PANTRY,
  PARKING,
  REQUEST_INFO,
  REQUEST_ON_SELL,
  REQUEST_RENT,
  REQUEST_TIME_TO_AVAILABILITY
} from './Constants';
import moment from 'moment/moment';

export const ACTIVE_SELECT_OPTIONS = [
  { value: '1', name: 'Активен' },
  { value: '2', name: 'Закрыт' },
];

export const RENT_TYPE_OPTIONS = [
  { value: '1', name: 'Посуточно' },
  { value: '2', name: 'На длительный срок' },
];


export const RENT_SORT_TYPE_OPTIONS = [
  { value: 'day', name: 'Дни' },
  { value: 'week', name: 'Недели' },
];


export const statusType = [
  { value: 1, name: 'Новая' },
  { value: 2, name: 'Недозвон' },
  { value: 3, name: 'Перезвон' },
  { value: 4, name: 'Ожидает оплаты' },
  { value: 5, name: 'Брак' },
  { value: 6, name: 'Отказ' },
  { value: 7, name: 'Оплачено' },
  { value: 8, name: 'Сдано' },
  { value: 9, name: 'Завершено' },
  { value: 10, name: 'Заказ' },
];

export const requestType = [
  { value: REQUEST_RENT, name: 'На аренду' },
  { value: REQUEST_INFO, name: 'Информация' },
  { value: REQUEST_ON_SELL, name: 'На продажу' },
  { value: REQUEST_TIME_TO_AVAILABILITY, name: 'Когда освободится' },
];

export const REALTY_TYPES = [
  { value: APARTMENT, name: 'Жильё' },
  { value: PARKING, name: 'Паркинги' },
  { value: OFFICE, name: 'Офисы' },
  { value: PANTRY, name: 'Кладовка' },
];


export const MEETING_STATUSES = [
  { name: 'Назначена', value: 1 },
  { name: 'Состоялась', value: 2 },
  { name: 'Отменена', value: 3 }
];

export const MEETINGS_TYPES = [
  { name: 'Показ', value: 'show' },
  { name: 'Подписание документов', value: 'document_sign' },
  { name: 'Оплаты', value: 'payment' },
  { name: 'Закрытие договора', value: 'contract_closure' },
  { name: 'Передача ключей', value: 'key_handover' },
  { name: 'Тех. проблемы', value: 'tech_problems' }
];


export const INCOME_TYPE = [
  { value: '0', name: 'Расход' },
  { value: '1', name: 'Приход' }
];

export const INCOME_CATEGORY = [
  { value: '5', name: 'Разное' },
  { value: '11', name: 'РД' },
  { value: '15', name: 'Оплата за уборку' },
  { value: '16', name: 'Компенсация ущерба / штрафы' },
  { value: '17', name: 'Ввод от учредителей' },
  { value: '21', name: 'Представитель принес в кассу' },
];

export const OUTCOME_CATEGORY = [
  { value: '3', name: 'Оплата коммуналки' },
  { value: '5', name: 'Разное' },
  { value: '6', name: 'Зарплата' },
  { value: '7', name: 'Погрузка' },
  { value: '8', name: 'Средства для офиса' },
  { value: '9', name: 'Ремонт квартир' },
  { value: '10', name: 'Интерьер' },
  { value: '11', name: 'РД' },
  { value: '12', name: 'Автомобиль' },
  { value: '14', name: 'Возврат депозита' },
  { value: '15', name: 'Уборка' },
  { value: '17', name: 'Вывод от учредителей' },
  { value: '18', name: 'Размещение объявления(й)' },
  { value: '19', name: 'Интернет, ТБ, почта' },
  { value: '20', name: 'Содержание объекта' },
  { value: '21', name: 'Выдать представителю' },
  { value: '23', name: 'Комиссия банка' },
];

export const WITH_BOOKINGS = [
  { value: -1, name: 'Без заказов' },
  { value: 1, name: 'C заказами' },
];

export const REALTY_STATUS_OPT = [
  { value: '1', name: 'Свободно' },
  { value: '2', name: 'Забронировано' },
  { value: '3', name: 'Занято' },
  { value: '4', name: 'Требуется уборка' }
];


let previousMonth = moment().subtract('1', 'month'),
  previousYear = moment().subtract('3', 'month');


export const PERIOD_SELECT_OPTIONS = [
  { value: moment(previousMonth).format('DD/MM/YYYY'), name: 'За месяц' },
  { value: moment(previousYear).format('DD/MM/YYYY'), name: 'За три месяца' },
];

export const DEBT_OPTIONS = [
  { value: '0', name: 'Без долгов' },
  { value: '1', name: 'С долгами' },
];

export const STATISTIC_RENT_TYPE_OPTIONS = [
  { value: '1', name: 'Долгосрочно' },
  { value: '2', name: 'Посуточно' },
];

export const STATISTIC_STATUS_OPTIONS = [
  ...REALTY_STATUS_OPT.splice(0, 3)
];

export const NOTIFICATION_TYPES_OPTIONS = [
  { value: NOTIFICATION_TYPE.newRequestByRealty, name: 'Новая заявка' },
  { value: 2, name: 'Нужна поддержка клиентов' },
  { value: 3, name: 'Клиент внёс все показания счетчиков корректно' },
  { value: 4, name: 'Клиент внес подозрительные показания' },
  { value: 5, name: 'Ошибка в передаче показаний в УК' },
  { value: 6, name: 'Клиент проживает дольше проплаченого времени!' },
  { value: 7, name: 'У клиента заканчивается срок действия паспорта!' },
];
