import React from 'react';
import { MEETINGS_TYPES } from '../SelectConstats';
import SelectField from '../SelectField';

const MeetingTypeSelectField = ({ label, ...props }) => {
  return (
    <SelectField options={ MEETINGS_TYPES }
                 fullWidth
                 { ...props }
                 label="Тип встречи"
                 type="meeting_types"/>
  );
};

export default MeetingTypeSelectField;
