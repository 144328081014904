import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import './index.scss';
import App from './components/App';
import './api';
import StoreProvider from './components/StoreProvider';
import './globalLocaleSettings';
import theme from './theme';
import store from './reducers/store';
import './i18n';


ReactDOM.render(
  <StoreProvider store={ store }>
    <ThemeProvider theme={ theme }>
      <App/>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
);

