import React from 'react';
import './RealtyFeedItem.scss';
import { transformRealtyFeedsChanges, uuidv4 } from '../Tools';
import { Link } from 'react-router-dom';
import { BOOKINGS_ROUTE, REALTY_LIST_ROUTE } from '../RouterConstants';
import RealtyFeedChanges from './RealtyFeedChanges';
import { getTypeDocument, REALTY_FEED_TYPE } from '../Constants';
import BasicUtilitiesIcon from '../Utilities/BasicUtilitiesIcon';
import { useTranslation } from 'react-i18next';

const RealtyFeedItem = ({ className = '', data, created_at, ...props }) => {
  const { t } = useTranslation();

  switch ( data?.type ) {
    case ( REALTY_FEED_TYPE.newBookingRequest ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Создана') }&nbsp;
              <a href={ data?.url }
                 className="realty-feed__link">{ t('новая заявка') }</a>
              &nbsp;{ t('на объект') }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.clientWriteChat ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Клиент написал в чат менеджеру.') }
            </div>
            <div className="realty-feed__created">
              { data?.client_last_name }&nbsp;{ data?.client_first_name } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.clientWriteMetersDataCorrectly ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Клиент внёс все показания счетчиков корректно') }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.clientWriteMetersDataSuspicious ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Клиент внес подозрительные показания.') }
              { t('Менеджер будет перепроверять') }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.errorSendMetersDataToMainCompany ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Ошибка в передаче показаний в УК.') }
              { t('Менеджер перепроверяет') }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.clientStaysLongerPaidTime ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Клиент проживает дольше оплаченного времени!') }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.clientPassportExpires ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('У клиента заканчивается срок действия паспорта по заказу') }&nbsp;
              <Link to={ `${ BOOKINGS_ROUTE }/${ data.booking_id }` }>{ data?.agreement }</Link>.&nbsp;
              { t('Менеджер будет перезаключать договор на новые данные') }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.clientRequestProcessedByManager ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              Заявка от клиента обработана менеджером { data?.user }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.newBookingOrder ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('На объект создан новый') }&nbsp;
              <Link to={ `${ BOOKINGS_ROUTE }/${ data.booking_id }` }>{ t('заказ') }</Link>
            </div>
            <div className="realty-feed__data">
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.orderEditByManager ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Заказ') } &nbsp;
              <Link to={ `${ BOOKINGS_ROUTE }/${ data?.booking_id }` }>
                { data?.agreement }
              </Link>&nbsp;
              { t('отредактирован менеджером') } { data?.user }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyCreated ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Объект создан. Вы можете посмотреть его по ссылке') } -&nbsp;
              <Link to={ `${ REALTY_LIST_ROUTE }/${ data?.realty_id }` }>
                { data?.realty_name } { t('ссылка на объект') }
              </Link>
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyEditedByManager ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Объект отредактирован менеджером') } { data?.user }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }

            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtySold ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Объект продан за ') }{ data?.price }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtySeoUpdate ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Обновлены SEO данные объекта') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.managerAddRealtyToAds ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Менеджер добавил Ваш объект в объявления.') }
              { t('Вы можете посмотреть объявление по ссылкам:') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyPricesUpdated ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Обновлены цены на объект') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyDepositMade ):
      //todo: refactoring
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              Внесён депозит СУММА по договору
              <Link to={ '/' }>НАЗВАНИЕ_ДОГОВОРА</Link>
            </div>
            <div className="realty-feed__data">

            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyDepositRefund ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              Возврат депозита СУММА по договору
              <Link to={ '/' }>НАЗВАНИЕ_ДОГОВОРА</Link>'
            </div>
            <div className="realty-feed__data"
            />
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyRentPaid ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Оплачена аренда') } { data?.amount }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyCommunalPaid ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Оплачена коммуналка') } { data?.amount }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.paymentCancel ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Отмена платежа') } { data?.amount }
            </div>
            <div className="realty-feed__data">
              { data?.comment }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyAddDoc ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('К документам объекта добавили') } { getTypeDocument(data?.document_type) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.managerRemoveDoc ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Менеджер') } { data?.user }
              { t('удалил документ типа') } - { getTypeDocument(data?.document_type) }
            </div>
            <div className="realty-feed__data"
            />
            <div className="realty-feed__created">
              { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyCadastralNumberChanges ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Изменён кадастровый номер') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyAddRoom ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('К объекту добавлена комната для лучшего учета инвентаря') }
            </div>
            <div className="realty-feed__data">
              { t('Название комнаты') }- { data?.name }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyRoomUpdated ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Комната') } { data?.name } { t('изменена') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyRoomRemoved ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Комната') } { data?.name } { t('удалена') }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyAddedInventory ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Добавлен инвентарь') } { data?.name }
            </div>
            <div className="realty-feed__data"
            />
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyUpdatedInventory ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Инвентарь') } { data?.name } { t('изменён') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyRemovedInventory ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Инвентарь') } { data?.name } { t('удалён') }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyAddManagementCompany ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('К объекту добавлена управляющая компания') } - { data?.management_company_name }
            </div>

            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyManagementCompanyUpdated ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Управляющая компания') } { data?.management_company_name } { t('изменена') }
            </div>
            <div className="realty-feed__data">
              { transformRealtyFeedsChanges(data?.changes).map(data => (
                <RealtyFeedChanges key={ uuidv4() }
                                   { ...data }/>
              )) }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyManagementCompanyRemoved ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Управляющая компания') } { data?.management_company_name } { t('удалена') }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.counterAdded ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t(' Добавлен счётчик') } { data?.meter_name } на&nbsp;
              { <BasicUtilitiesIcon icon_id={ data?.basic_utility_id }/> }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.counterRemoved ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('Счётчик') } { data?.meter_name } на&nbsp;
              { <BasicUtilitiesIcon icon_id={ data?.basic_utility_id }/> }&nbsp;
              { t('удалён') }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyImageUpload ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('К объекту загружена фотография') }
            </div>
            <div className="realty-feed__data">
              <img src={ data?.path } alt="realtyPhoto"/>
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    case ( REALTY_FEED_TYPE.realtyImageRemoved ):
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { t('У объекта удалена фотография') }
            </div>
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
    default:
      return (
        <li className={ className }>
          <div className="realty-feed">
            <div className="realty-feed__type">
              { JSON.stringify(data) }
            </div>
            <div className="realty-feed__data"
            />
            <div className="realty-feed__created">
              { data?.user } в { created_at }
            </div>
          </div>
        </li>
      );
  }
};

export default RealtyFeedItem;
