import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import FormContainer from '../FormContainer';
import { MANAGER, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { createMeeting } from '../../actions/meetingsActions';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import MeetingStatusSelectField from './MeetingStatusSelectField';
import MeetingTypeSelectField from './MeetingTypeSelectField';
import PickDateTimeField from '../PickDateTimeField';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import ClientAutocomplete from '../Autocomplete/ClientAutocomplete';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import ButtonWithProgress from '../ButtonWithProgress';
import { MEETINGS_ROUTE } from '../RouterConstants';
import PhoneInput from '../PhoneInput';
import { Divider, Typography } from '@material-ui/core';
import { reformatPhoneNumber } from '../Tools';
import { useTranslation } from 'react-i18next';

const initialState = {
  manager_id: '',
  client_id: undefined,
  realty_id: '',
  datetime: '',
  type: '',
  status: '',
  comment: undefined,
  address: undefined,
  first_name: undefined,
  last_name: undefined,
  phone: undefined,
  email: undefined,
};


const MeetingCreateForm = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const [state, setState] = useState(initialState);
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(new Errors());


  let setDefaultManager = useCallback(() => {
    if ( user.userable_type === MANAGER ) {
      setState(prevState => ( {
        ...prevState,
        manager_id: String(user.userable_id)
      } ));
    }
  }, [user]);

  useEffect(() => {
    setDefaultManager();
  }, [setDefaultManager]);


  let handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setState(prevState => ( {
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    } ));
  };

  let handleChangeDate = (key, value) => {
    setState(prevState => ( {
      ...prevState,
      [key]: value
    } ));
  };

  let handleChangeAutocomplete = (value, type) => {
    setState(prevState => ( {
      ...prevState,
      [type]: value
    } ));
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    let formData;
    const formattedDate = state.datetime ? moment(state.datetime, 'DD/MM/YYYY, HH:mm').unix() : undefined;

    if ( state.client_id ) {
      formData = {
        manager_id: state.manager_id,
        client_id: state.client_id,
        realty_id: state.realty_id,
        type: state.type,
        status: state.status,
        comment: state.comment,
        address: state.address,
        datetime: formattedDate
      };
    } else {
      formData = {
        manager_id: state.manager_id,
        realty_id: state.realty_id,
        type: state.type,
        status: state.status,
        comment: state.comment,
        address: state.address,
        first_name: state.first_name,
        last_name: state.last_name,
        phone: reformatPhoneNumber(state.phone),
        email: state.email,
        datetime: formattedDate
      };
    }


    createMeeting(formData)
      .then(meeting => {
        setState(initialState);
        setErrors(new Errors());
        history.push(`${ MEETINGS_ROUTE }/${ meeting.id }`);
        dispatch(openSnackbar(SUCCESS, SNACKBAR_MESSAGES.meetings.create.success));
      })
      .catch(errors => {
        setErrors(new Errors(errors));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <React.Fragment>
      <FormContainer>
        <FormRow>
          <FormColumn sm={ 4 } md={ 2 }>
            <PickDateTimeField name="datetime"
                               error={ errors.getError('datetime') }
                               type="datetime"
                               disableToolbar={ false }
                               value={ state.datetime }
                               onChange={ handleChangeDate }
                               label="Дата и время встречи"/>
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <ManagersAutocomplete label="Кто встречает"
                                  error={ errors.getError('manager_id') }
                                  onChange={ (e, value) => handleChangeAutocomplete(value?.id, 'manager_id') }
                                  value={ String(state.manager_id) }
                                  placeholder="Выберите менеджера"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <MeetingTypeSelectField onChange={ handleChange }
                                    name="type"
                                    value={ state.type }
                                    error={ errors.getError('type') }/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <ClientAutocomplete doubleLabel
                                error={ errors.getError('client_id') }
                                onChange={ (e, value) => handleChangeAutocomplete(value?.id, 'client_id') }/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn xs={ 12 }
                      sm={ 8 }
                      md={ 4 }>
            <Typography variant="body2">{t('ИЛИ')}</Typography>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <TextField error={ errors.getError('first_name') }
                       fullWidth
                       name="first_name"
                       value={ state?.first_name }
                       onChange={ handleChange }
                       label="Имя"
                       placeholder="Введите имя"
            />
          </FormColumn>

          <FormColumn sm={ 4 }
                      md={ 2 }>
            <TextField error={ errors.getError('last_name') }
                       fullWidth
                       name="last_name"
                       value={ state?.last_name }
                       onChange={ handleChange }
                       label="Фамилия"
                       placeholder="Введите фамилию"
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <TextField error={ errors.getError('email') }
                       fullWidth
                       name="email"
                       value={ state?.email }
                       onChange={ handleChange }
                       label="Электронная почта"
                       placeholder="Введите электронную почту"
            />
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <PhoneInput error={ errors.getError('phone') }
                        name="phone"
                        type="phone"
                        onChange={ handleChange }
                        value={ state?.phone }
                        label="Телефон"
                        placeholder="Введите телефон"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn xs={ 12 }
                      sm={ 8 }
                      md={ 4 }>
            <Divider/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <RealtyAutocomplete value={ String(state.realty_id) }
                                error={ errors.getError('realty_id') }
                                onChange={ (e, value) => handleChangeAutocomplete(value?.id, 'realty_id') }/>
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <MeetingStatusSelectField onChange={ handleChange }
                                      name="status"
                                      value={ state.status }
                                      error={ errors.getError('status') }/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 8 }
                      md={ 4 }>
            <TextField error={ errors.getError('comment') }
                       name="comment"
                       value={ state.comment }
                       onChange={ handleChange }
                       label="Комментарий"
                       placeholder="Введите комментарий"/>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn sm={ 8 }
                      md={ 4 }>
            <TextField error={ errors.getError('address') }
                       name="address"
                       value={ state.address }
                       onChange={ handleChange }
                       label="Адрес встречи"
                       placeholder="Введите адрес"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <ButtonWithProgress label="Создать"
                                loading={ loading }
                                onClick={ handleSubmit }/>
          </FormColumn>
        </FormRow>
      </FormContainer>

    </React.Fragment>
  );
};

export default MeetingCreateForm;
