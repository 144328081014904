import React, { memo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { TableCell, TableRow } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import FilterLogs from '../../components/Logs/FilterLogs';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { LOGS_HEADERS } from '../../components/TableConstants';

const LogsListPage = ({ ...props }) => {
  const { setPageTitle } = usePageTitle();
  const { data: history, loading, meta } = useFetch({
    url: API_ROUTE.logs,
    hasMeta: true,
    oldResponseFormat: true
  });


  useEffect(() => {
    setPageTitle('История действий');
  });

  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <PageHeader title="История действий"
                    size="md"/>
      </Grid>

      <Grid item
            xs={ 12 }>
        <FilterLogs/>
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate data={ history } meta={ meta } loading={ loading } headers={ LOGS_HEADERS }>
          { history && history.map((item) => (
            <TableRow key={ item.id }
                      hover>
              <TableCell>{ item.created_at }</TableCell>
              <TableCell>{ item.user }</TableCell>
              <TableCell>{ item.route }</TableCell>
              <TableCell style={ { wordBreak: 'break-all', maxWidth: '500px' } }>
                { JSON.stringify(item.data) }
              </TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>


    </Grid>
  );

};

export default memo(LogsListPage);
