import React, { PureComponent } from 'react';
import { Card, CardHeader, Divider } from '@material-ui/core';
import ModeratorEditForm from './../../components/Moderator/ModeratorEditForm';
import { setPageTitle } from '../../components/Tools';
import { withTranslation } from 'react-i18next';

class ModeratorEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    setPageTitle('Редактирование модератора', this.props.t);
    this.setState({
      id: this.getId(),
    });
  }

  getId = () => {
    const { match: { params } } = this.props;

    return params.id || null;
  };

  render() {
    const { id } = this.state;
    const { t } = this.props;

    if ( !id ) {
      return null;
    }

    return (
      <React.Fragment>
        <Card>
          <CardHeader
            title={ t('Редактирование модератора') }
            subheader={ `ID: ${ id }` }
          />

          <Divider/>

          <div style={ { paddingTop: 24, paddingBottom: 24 } }>
            <ModeratorEditForm id={ id }/>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ModeratorEditPage);
