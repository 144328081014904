import React from 'react';
import MomentUtils from '@date-io/moment';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const PickDateTimeField = ({defaultDateFrom, value, onChange, name, label,
                             type, disabled, views, minDate, maxDate,
                             disableToolbar = true, ampm = false, ...props}) => {
  const {t} = useTranslation();
  let dateFrom;

  if ( /\d{2}\/\d{2}\/\d{4}, \d{2}:\d{2}/.test(value) ) {
    dateFrom = moment(value, 'DD/MM/YYYY, HH:mm');
  } else {
    dateFrom = defaultDateFrom;
  }

  return (
      <MuiPickersUtilsProvider utils={ MomentUtils }>
        <Grid item
              xs={ 12 }>
          <DateTimePicker views={ views }
                          autoOk
                          clearable="true"
                          ampm={ ampm }
                          fullWidth
                          disabled={ disabled }
                          disableToolbar={ disableToolbar }
                          minDate={ minDate || moment().subtract(0, 'year').startOf('day').toDate() }
                          maxDate={ maxDate || moment().add(3, 'year').startOf('day').toDate() }
                          variant="inline"
                          inputVariant="outlined"
                          format="DD/MM/YYYY, HH:mm"
                          value={dateFrom ? dateFrom.toDate() : null}
                          onChange={ value => onChange(type, moment(value).format('DD/MM/YYYY, HH:mm')) }
                          name={ name }
                          label={ t(label) }
                          error={ !!props.error }
                          helperText={ props.error || '' }
                          InputProps={ {
                            endAdornment: <InsertInvitationIcon/>
                          } }
          />
        </Grid>
      </MuiPickersUtilsProvider>
  );
};

export default PickDateTimeField;
