export const API_ROUTE = {
  bookingRequest: 'booking/requests',
  meetings: 'meetings',
  externalResources: 'realty/external_resources',
  utilitiesMeterData: 'utilities/meter_data',
  realtyOccupancy: 'statistics/occupied-realties',
  managementCompanies: 'utilities/management_companies',
  realtyGroups: 'realty/groups',
  managers: 'managers',
  moderators: 'moderators',
  roles: 'moderator_roles',
  notifications: 'notifications',
  logs: 'logs',
  tochkaPayments: 'payments/tochka'
};
