import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader';
import { setPageTitle } from '../../components/Tools';
import MeetingCreateForm from '../../components/Meetings/MeetingCreateForm';


const MeetingCreatePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle('Создание встречи', t);
  });

  return (
    <Paper>
      <Grid container
            spacing={ 2 }
            style={ { padding: '24px' } }>
        <Grid item sm={ 2 }>
          <PageHeader title="Создание встречи"
                      size="md"/>
        </Grid>

        <Grid item xs={ 12 }>
          <MeetingCreateForm/>
        </Grid>
      </Grid>
    </Paper>

  );
};

export default MeetingCreatePage;
