import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import PickDateField from '../PickDateField';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import FilterCategorySelect from '../FilterFields/FilterCategorySelect';
import ClearFiltersButton from '../ClearFiltersButton';
import SearchField from '../SearchField';
import SelectWithCustomValueField from '../SelectWithCustomValueField';
import { flexAlignCenter } from '../JSStylesConstants';


class PaydeskFilter extends PureComponent {

  handleChangeAutocomplete = (value, type) => {
    const query = queryString.parse(this.props.location.search);

    let newQuery = {
      ...query,
      [type]: value?.id,
      page: 1
    };

    if ( !value ) delete newQuery[type];

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });
  };

  handleChangeSelect = (e) => {
    const { name, value } = e.target;
    const query = queryString.parse(this.props.location.search);

    const newQuery = {
      ...query,
      page: 1,
      [name]: value
    };

    if ( !value ) delete newQuery[name];

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });

  };


  handleChangeDate = (key, value, type) => {
    const query = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        page: 1,
        [key]: type === 'to'
          ? value ? moment(value, 'DD/MM/YYYY').endOf('day').format('X') : undefined
          : value ? moment(value, 'DD/MM/YYYY').format('X') : undefined
      })
    });
  };

  getParam = (params) => {
    const query = queryString.parse(this.props.location.search);

    return query[params] || '';
  };


  render() {
    return (
      <Grid container
            spacing={ 2 }
            style={ { alignItems: 'center' } }>
        <Grid item>
          <SelectWithCustomValueField fullWidth
                                      label="Баланс"
                                      name="balance"
                                      style={ { minWidth: 220 } }
                                      onChange={ this.handleChangeSelect }
                                      optionName="name"
                                      optionValue="payment_method"
                                      value={ this.getParam('balance') }
                                      options={ this.props.balances }/>
        </Grid>
        <Grid item>
          <PickDateField name="period[from]"
                         type="period[from]"
                         value={ moment(this.getParam('period[from]'), 'X').format('DD/MM/YYYY') }
            // defaultDateFrom={moment().startOf('day')}
                         onChange={ (key, value) => this.handleChangeDate(key, value, 'from') }
                         label="Дата от"/>
        </Grid>
        <Grid item>
          <PickDateField name="period[to]"
                         type="period[to]"
                         value={ moment(this.getParam('period[to]'), 'X').format('DD/MM/YYYY') }
            // defaultDateFrom={moment().startOf('day')}
                         onChange={ (key, value) => this.handleChangeDate(key, value, 'to') }
                         label="Дата до"/>
        </Grid>
        <Grid item>
          <FilterCategorySelect style={ { minWidth: 250 } }
                                onChange={ this.handleChangeSelect }
                                value={ this.getParam('action_typed') }/>
        </Grid>
        <Grid item>
          <RealtyAutocomplete value={ this.getParam('realty_id') }
                              style={ { minWidth: 250 } }
                              onChange={ (e, value) => this.handleChangeAutocomplete(value, 'realty_id') }/>
        </Grid>
        <Grid item>
          <SearchField fulWidth
                       searchField="agreement"
                       label="Поиск"
                       placeholder="Номер договора"/>
        </Grid>

        <Grid item>
          <SearchField fulWidth
                       searchField="amount"
                       label="Сумма"
                       placeholder="Введите сумму"/>
        </Grid>
        <Grid item>
          <SearchField fulWidth
                       searchField="comment"
                       label="Комментарий"
                       placeholder="Введите комментарий"/>
        </Grid>


        <Grid item
              style={ flexAlignCenter }>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(PaydeskFilter);
