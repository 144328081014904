import React from 'react';
import Title from '../Title';
import RealtyDocument from './RealtyDocument';

const RealtyDocumentsPart = ({ id, documents, ...props }) => {


  return (
    <div className="documents">
      <div className="documents__wrap">
        <Title size="md"
               title="Документы"/>
      </div>

      <div className="documents__inner">
        <div className="documents__wrapper">
          { documents.map(document => <RealtyDocument key={ document.type } { ...document }/>) }
        </div>
      </div>
    </div>
  );
};

export default RealtyDocumentsPart;
