import axios from 'axios';
import queryString from 'query-string';
import { API_ROUTE } from '../api/routes';


export const fetchPermissions = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.roles }/permissions`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
  });
};

export const fetchRoles = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.roles }?${ queryString.stringify(query) }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const fetchRole = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.roles }/${ id }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const createRole = (data) => {
  return new Promise((resolve, reject) => {
    axios.get(`sanctum/csrf-cookie`).then(() => {
      axios.post(`${ API_ROUTE.roles }`, data)
        .then((response) => {
          resolve(response.data.results);
        })
        .catch((error) => {
          reject(error?.response?.data?.errors);
        });
    });
  });
};

export const updateRole = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${ API_ROUTE.roles }/${ id }`, data)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const deleteRole = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${ API_ROUTE.roles }/${ id }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

