import React from 'react';
import FormDrawerWithPayload from '../../components/FormDrawerWithPayload/FormDrawerWithPayload';
import { closePaydeskCancelModal } from '../../actions/formDrawerActions';
import { connect } from 'react-redux';
import PaydeskPaymetCancelForm from './PaydeskPaymetCancelForm';

const PaydeskPaymentCancelModal = ({ isOpen, closePaydeskCancelModal, loadPayments, ...props }) => {

  return (
    <React.Fragment>
      <FormDrawerWithPayload isOpen={ isOpen }
                             closeModal={ closePaydeskCancelModal }>
        <PaydeskPaymetCancelForm loadPayments={ loadPayments }
                                 closeModal={ closePaydeskCancelModal }/>
      </FormDrawerWithPayload>
    </React.Fragment>
  );
};

let matStateToProps = (state) => ( {
  isOpen: state.formDrawer.paydeskCancel.isOpen
} );

let mapDispatchToProps = (dispatch) => ( {
  closePaydeskCancelModal: () => dispatch(closePaydeskCancelModal())
} );


export default connect(matStateToProps, mapDispatchToProps)(PaydeskPaymentCancelModal);
