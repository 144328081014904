import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { flexCenter, textCenter } from '../../JSStylesConstants';
import { useLocation } from 'react-router-dom';
import { ERROR, SNACKBAR_MESSAGES, SUCCESS } from '../../Constants';
import AddingButton from '../../AddingButton';
import { DeleteIcon, EditIcon } from '../../../icons';
import DialogsRemove from '../../DialogsRemove';
import { useDispatch } from 'react-redux';
import { openInvoiceEditModal } from '../../../actions/formDrawerActions';
import { fetchBookingsUtilitiesInvoices, removeBookingsUtilitiesInvoices } from '../../../actions/bookingsActions';
import { openSnackbar } from '../../../actions/snackbarActions';
import queryString from 'query-string';
import { usePermissions } from '../../../hooks';
import TableTemplate from '../../TableTemplate';
import { INVOICES_HEADERS } from '../../TableConstants';

const UtilityInvoices = ({ booking_id, period, loadInvoices, ...props }) => {
  const { isModerator } = usePermissions();
  const dispatch = useDispatch();
  const location = useLocation();
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(false);

  let getInvoices = useCallback(() => {
    let query = queryString.parse(location.search);

    setLoading(true);

    fetchBookingsUtilitiesInvoices({ booking_id: booking_id, period: period, ...query })
      .then(response => {
        setInvoices(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.search, booking_id, period]);


  useEffect(() => {
    getInvoices();
  }, [getInvoices]);


  let handleEditModal = (log) => {
    dispatch(openInvoiceEditModal(log));
  };

  let handleRemoveInvoice = (_, id) => {
    removeBookingsUtilitiesInvoices(id)
      .then(() => {
        loadInvoices();
        dispatch(openSnackbar(SUCCESS, SNACKBAR_MESSAGES.invoices.remove.success));
      })
      .catch(error => {
        dispatch(openSnackbar(ERROR, error));
      });
  };


  return (
    <Grid item xs={ 12 }>
      <TableTemplate loading={ loading }
                     data={ invoices }
                     headers={ INVOICES_HEADERS }>
        { invoices && invoices.map(invoice => (
          <TableRow key={ invoice.id } hover>
            <TableCell>#{ invoice.id }</TableCell>
            <TableCell>{ invoice.created_at }</TableCell>
            <TableCell style={ textCenter }>
              <img src={ invoice.realty_utility.basic_utility.icon }
                   style={ { width: 16 } }
                   alt={ invoice.realty_utility.basic_utility.name }/>
            </TableCell>
            <TableCell>{ invoice.initial_value }</TableCell>
            <TableCell>{ invoice.final_value }</TableCell>
            <TableCell>{ invoice.price_per_unit }</TableCell>
            <TableCell>{ invoice.realty_utility.meter_name }</TableCell>
            <TableCell>{ invoice.realty_utility.management_company.name }</TableCell>
            <TableCell>{ invoice.amount }</TableCell>
            { ( isModerator() && invoice.removable )
              ? <TableCell>
                <div style={ { ...flexCenter } }>
                  <AddingButton icon={ () => <EditIcon style={ { fontSize: '24px' } }/> }
                                position="left"
                                onClick={ () => handleEditModal(invoice) }
                                tooltip="Редактирование инвойса"/>

                  <DialogsRemove position_button="left"
                                 tooltip="Удалить инвойс"
                                 negative_answer="Нет"
                                 positive_answer="Да"
                                 question="Удалить инвойс?"
                                 question_text="Вы действительно хотите удалить инвойс?"
                                 remover={ handleRemoveInvoice }
                                 id={ invoice.id }
                                 icon={ () => <DeleteIcon style={ { fontSize: '24px' } }/> }/>
                </div>
              </TableCell>
              : <TableCell/>
            }

          </TableRow>
        )) }
      </TableTemplate>
    </Grid>

  );
};

export default UtilityInvoices;
