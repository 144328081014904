import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import PageHeader from '../../components/PageHeader';
import NotificationsFilter from '../../components/Notifications/NotificationsFilter';
import NotificationsRow from '../../components/Notifications/NotificationsRow';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { NOTIFICATION_HEADERS } from '../../components/TableConstants';
import { uuidv4 } from '../../components/Tools';

const NotificationsListPage = () => {
  const { setPageTitle } = usePageTitle();
  const { data: notifications, meta, loading } = useFetch({
    url: API_ROUTE.notifications,
    oldResponseFormat: true,
    hasMeta: true
  });

  useEffect(() => {
    setPageTitle('Уведомления');
  });


  return (
    <Grid container
          spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item
                xs={ 12 }>
            <PageHeader title="Уведомления"
                        size="md"/>
          </Grid>

          <Grid item
                xs={ 12 }>
            <NotificationsFilter/>
          </Grid>
        </Grid>
      </Grid>


      <Grid item xs={ 12 }>
        <TableTemplate meta={ meta }
                       data={ notifications }
                       loading={ loading }
                       headers={ NOTIFICATION_HEADERS }>
          { notifications && notifications.map((notification) => (
            <NotificationsRow key={ uuidv4() }
                              notification={ notification }/>
          )) }
        </TableTemplate>
      </Grid>


    </Grid>
  );
};

export default connect(null, { openSnackbar })(NotificationsListPage);
