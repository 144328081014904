import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import ru from './locales/ru/translation.json';
import uk from './locales/uk/translation.json';
import { I18N_LANGUAGE, LANGUAGES } from './components/Constants';

const resources = {
  ru: {
    translation: ru,
  },
  uk: {
    translation: uk,
  },
};


// let languages = process.env.REACT_APP_LOCALES.split(',');
let languages = LANGUAGES.map((x) => x.value);

i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    fallbackLng: languages,
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      lookupLocalStorage: I18N_LANGUAGE,
      lookupCookie: I18N_LANGUAGE,
      caches: ['localStorage', 'cookie'],
    },
    resources: resources,
    // fallbackLng: languages, // use eu if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    checkWhitelist: true,
  });

export default i18n;
