import React from 'react';
import { useTranslation } from 'react-i18next';

const RealtyFeedChanges = ({ name, value, ...props }) => {
  const { t } = useTranslation();

  let checkInValue = (val) => {
    if ( typeof val === 'boolean' ) {
      if ( val ) {
        return t('Да');
      } else {
        return t('Нет');
      }
    } else {
      return val;
    }
  };


  return (
    <div className="realty-feed-data-row">
      <div className="realty-feed-data-row__title">
        { name }:
      </div>
      <div className="realty-feed-data-row__value">
        &nbsp;{ checkInValue(value) }
      </div>
    </div>
  );
};

export default RealtyFeedChanges;
