import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SwitchField = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <FormControl error={ !!props.error }>
      <FormControlLabel style={ props.style }
                        labelPlacement={ props.labelPlacement }
                        control={
                          <Switch color="primary"
                                  name={ props.name }
                                  value={ props.value }
                                  disabled={ props.disabled }
                                  checked={ Boolean(props.value) }
                                  onChange={ props.onChange }
                          />
                        }
                        label={
                          <Typography variant="body2">
                            { t(props.label) }
                          </Typography>
                        }
      />
      { !!props.error && (
        <FormHelperText>{ props.error || '' }</FormHelperText>
      ) }
    </FormControl>
  );
};

export default SwitchField;
