import React from 'react';
import PageHeader from '../PageHeader';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const StatisticCompanyTitle = ({ ...props }) => {
  const company = useSelector(state => state.statistic.company);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={ `${ t( 'Статистика по объектам' ) } 
                                    [${ company?.name || '' }: ${ company?.realty_count || 0 }]` }
                  className="statistic-page__title"/>
      <Typography style={ { fontWeight: 500 } }>
        { t('с') } { company?.date_from } { t('по') } { company?.date_to }
      </Typography>
    </>
  );
};

export default StatisticCompanyTitle;
