import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { fetchRoles } from '../../actions/permissionsActions';
import { useTranslation } from 'react-i18next';


const RolesSelect = ({ error, value, onChange, name, ...props }) => {
  const [roles, setRoles] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchRoles()
      .then((res) => {
        setRoles(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <FormControl variant="outlined"
                 style={ { width: `100%` } } error={ !!error }>

      <InputLabel id="roleId">
        { t('Права доступа') }
      </InputLabel>
      <Select
        labelId="roleId"
        name={ name }
        error={ !!error || false }
        value={ value }
        onChange={ onChange }
        label={ t('Права доступа') }
      >
        { roles && roles.map(role => <MenuItem value={ role.id }
                                               key={ role.id }>
          { role.name }
        </MenuItem>) }
      </Select>

      { error &&
        <FormHelperText>{ error }</FormHelperText>
      }

    </FormControl>
  );


};

export default RolesSelect;
