import React, { Fragment, useState } from 'react';
import { TableRow, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { uuidv4 } from '../../Tools';
import AddingButton from '../../AddingButton';
import { ArrowDownIcon } from '../../../icons';
import { flexAlignCenter } from '../../JSStylesConstants';

const PaymentActsRow = ({ item, ...props }) => {
  const [open, setOpen] = useState(false);

  let handleOpenPayments = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow>
        <TableCell style={ {
          position: 'sticky',
          minWidth: 200,
          maxWidth: 250,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          backgroundColor: 'white',
          left: 0
        } }>
          { item.name }
        </TableCell>
        { Object.values(item.management_companies).length > 1
          ? <Fragment key={ uuidv4() }>
            <TableCell>
              <div style={ { ...flexAlignCenter } }>
                <Typography>
                  { Object.values(item.management_companies).length }
                </Typography>
                <AddingButton icon={ props => <ArrowDownIcon { ...props }
                                                             style={ {
                                                               transform: open ? 'rotate(180deg)' : '',
                                                               transition: '.3s all',
                                                             } }/> }
                              position="right"
                              onClick={ handleOpenPayments }/>
              </div>
            </TableCell>
            { Object.values(item.payments).map(payment => (
              <TableCell key={ uuidv4() }
                         style={ { textAlign: 'center' } }>
                { payment.amount.toFixed(2) }
              </TableCell>
            )) }

          </Fragment>
          : <Fragment>
            { Object.values(item.management_companies) && Object.values(item.management_companies).map(management_company => (
              <Fragment key={ uuidv4() }>
                <TableCell>
                  { management_company.name }
                </TableCell>
                { Object.values(management_company.payments).map(payment => (
                  <TableCell key={ uuidv4() }
                             style={ { textAlign: 'center' } }>
                    { payment.amount.toFixed(2) }
                  </TableCell>
                )) }
              </Fragment>
            )) }
          </Fragment>

        }

      </TableRow>
      { ( open && Object.values(item.management_companies).length > 1 ) &&
        Object.values(item.management_companies).map(management_company => (
          <TableRow key={ uuidv4() }>
            <TableCell/>
            <TableCell>{ management_company.name }</TableCell>
            { Object.values(management_company.payments).map(payment => (
              <TableCell key={ uuidv4() }
                         style={ { textAlign: 'center' } }>
                { payment.amount.toFixed(2) }
              </TableCell>
            )) }
          </TableRow>
        ))
      }

    </>
  );
};

export default PaymentActsRow;
