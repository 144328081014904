import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Link, TableCell, TableRow } from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import ManagerCreateForm from '../../components/Manager/ManagerCreateForm';
import ManagerRemove from '../../components/Manager/ManagerRemove';
import { PlusIcon } from '../../icons';
import AddingButton from '../../components/AddingButton';
import PageHeader from '../../components/PageHeader';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { MANAGERS_HEADERS } from '../../components/TableConstants';
import { generateActiveStrStatus } from '../../components/Tools';

const ManagersListPage = () => {
  const { setPageTitle } = usePageTitle();
  const {t} = useTranslation();
  const { data: managers, meta, refetch: loadManagers, loading } = useFetch({
    url: API_ROUTE.managers,
    oldResponseFormat: true,
    hasMeta: true
  });

  useEffect(() => {
    setPageTitle('Менеджеры');
  });


  return (
    <Grid container
          spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Менеджеры"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление менеджера"
                        button={ props => <AddingButton { ...props }
                                                        tooltip="Добавить менеджера"
                                                        icon={ () => <PlusIcon/> }/> }>
              <ManagerCreateForm loadManagers={ loadManagers }/>
            </FormDrawer>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate meta={ meta } data={ managers } loading={ loading } headers={ MANAGERS_HEADERS }>
          { managers && managers.map(manager => (
            <TableRow key={ manager.id } hover>
              <TableCell>
                { manager.update_url
                  ? <Link
                    color="inherit"
                    component={ React.forwardRef((props, ref) => (
                      <RouterLink { ...props }
                                  ref={ ref }
                                  to={ `/managers/${ manager.manager_id }` }
                      />
                    )) }>
                    <b>{ manager.name }</b>
                  </Link>
                  : <b>{ manager.name }</b>

                }

              </TableCell>
              <TableCell>{ manager.email }</TableCell>
              <TableCell>{ generateActiveStrStatus(manager.chief, t) }</TableCell>
              <TableCell>{ generateActiveStrStatus(manager.active, t) }</TableCell>
              <TableCell padding="none"
                         style={ { width: 75 } }>
                { manager.delete_url
                  ? <ManagerRemove id={ manager.manager_id }
                                   loadManagers={ loadManagers }/>
                  : null
                }

              </TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>

    </Grid>
  );
};

export default connect(null, { openSnackbar })(ManagersListPage);
