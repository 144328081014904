import React, { PureComponent } from 'react';
import { Grid, } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader';
import { fetchBooking } from '../../actions/bookingsActions';
import BookingEditTabs from '../../components/Booking/BookingsEditTabs';
import BookingRentForm from '../../components/Booking/BookingRentForm';
import BookingUtilitiesForm from '../../components/Booking/BookingUtilitiesForm';
import { withRouter } from 'react-router-dom';
import BookingDocumentsForm from '../../components/Booking/BookingDocumentsForm';
import { setPageTitle } from '../../components/Tools';
import { BOOKINGS_ROUTE } from '../../components/RouterConstants';
import BookingMainForm from '../../components/Booking/BookingMainForm';


class BookingEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      booking: [],
      activeTab: 0
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevState.activeTab !== this.state.activeTab ) {
      this.props.history.push({
        pathname: window.location.pathname,
      });
    }
  }

  componentDidMount() {
    setPageTitle('Редактирование заказа', this.props.t);
    this.setState({
      id: this.getId(),
    });

    this.loadBooking();

  }

  loadBooking = () => {
    const { match: { params } } = this.props;

    fetchBooking(params.id)
      .then(booking => {
        this.setState({
          booking: booking
        });
      })
      .catch(() => {
        this.props.history.push({
          pathname: BOOKINGS_ROUTE
        });
      });
  };

  getId = () => {
    const { match: { params } } = this.props;

    return params.id || null;
  };

  handleChangeTab = (e, value) => {
    this.setState({
      activeTab: value
    });
  };

  render() {
    const { id, booking, activeTab } = this.state;
    const { t } = this.props;

    if ( !id || !booking.client ) {
      return null;
    }

    const TABS_CONTENT = [
      {
        component: BookingMainForm
      },
      {
        component: BookingRentForm
      },
      {
        component: BookingUtilitiesForm
      },
      {
        component: BookingDocumentsForm
      },
    ];

    return (
      <Grid container
            spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <PageHeader title={ `${ t('Заказ') } #${ booking.id } ${ booking?.rental_agreement || '' }` }
                      size="md"/>
        </Grid>

        <Grid item
              xs={ 12 }>
          <BookingEditTabs value={ activeTab }
                           with_utility_bills={ booking.with_utility_bills }
                           handleChange={ this.handleChangeTab }/>
        </Grid>

        <Grid item
              xs={ 12 }>
          { TABS_CONTENT.map(({ component: Component }, index) => {
            if ( index === activeTab ) {
              return (
                <Component booking={ booking }
                           key={ index }
                           loadBooking={ this.loadBooking }/>
              );
            } else {
              return null;
            }
          }) }
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(BookingEditPage));
