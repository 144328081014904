import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import FormContainer from '../FormContainer';
import { SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { getMeeting, updateMeeting } from '../../actions/meetingsActions';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import MeetingStatusSelectField from './MeetingStatusSelectField';
import MeetingTypeSelectField from './MeetingTypeSelectField';
import PickDateTimeField from '../PickDateTimeField';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import ClientAutocomplete from '../Autocomplete/ClientAutocomplete';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import ButtonWithProgress from '../ButtonWithProgress';
import { openCreateClientModal } from '../../actions/formDrawerActions';
import PhoneInput from '../PhoneInput';
import { Divider } from '@material-ui/core';
import ClientCreateModal from '../Client/ClientCreateModal';
import { reformatPhoneNumber } from '../Tools';

const initialState = {
  manager_id: '',
  client_id: '',
  realty_id: '',
  datetime: '',
  type: '',
  status: '',
  comment: '',
  address: '',
  client_name: undefined,
  first_name: undefined,
  last_name: undefined,
  phone: undefined,
  email: undefined
};


const MeetingEditForm = ({ id, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(new Errors());
  const [loading, setLoading] = useState(false);

  let setMeetingData = useCallback(({
                                      manager, client, realty,
                                      datetime, type, status, comment, address,
                                      first_name, last_name, phone, email
                                    }) => {

    setState(prevState => ( {
      ...prevState,
      manager_id: manager.manager_id,
      client_id: client?.id,
      client_name: client?.name,
      realty_id: realty?.id,
      datetime: moment(datetime).format('DD/MM/YYYY, HH:mm'),
      type: type,
      status: status,
      comment: comment,
      address: address,
      first_name,
      last_name,
      phone,
      email
    } ));
  }, []);

  useEffect(() => {
    getMeeting(id)
      .then(meeting => {
        setMeetingData(meeting);
      })
      .catch(() => {
        history.push('/');
      });
  }, [id, setMeetingData, history]);


  let handleOpenModal = () => {
    const client = {
      first_name: state?.first_name,
      phone: state?.phone,
      last_name: state?.last_name,
      email: state?.email
    };

    dispatch(openCreateClientModal(client));
  };


  let handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setState(prevState => ( {
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    } ));
  };

  let handleChangeDate = (key, value) => {
    setState(prevState => ( {
      ...prevState,
      [key]: value
    } ));
  };

  let handleChangeAutocomplete = (value, type) => {
    setState(prevState => ( {
      ...prevState,
      [type]: value
    } ));
  };

  let handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    let formData;

    if ( state.client_id ) {
      formData = {
        manager_id: state.manager_id,
        client_id: state.client_id,
        realty_id: state.realty_id,
        type: state.type,
        status: state.status,
        comment: state.comment,
        address: state.address,
        datetime: moment(state.datetime, 'DD/MM/YYYY, HH:mm').unix()
      };
    } else {
      formData = {
        manager_id: state.manager_id,
        realty_id: state.realty_id,
        type: state.type,
        status: state.status,
        comment: state.comment,
        address: state.address,
        phone: reformatPhoneNumber(state.phone),
        datetime: moment(state.datetime, 'DD/MM/YYYY, HH:mm').unix(),
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email ? state.email : undefined
      };
    }


    updateMeeting(id, formData)
      .then(meeting => {
        setMeetingData(meeting);
        dispatch(openSnackbar(SUCCESS, SNACKBAR_MESSAGES.meetings.edit.success));
      })
      .catch(errors => {
        setErrors(new Errors(errors));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <div style={ { paddingLeft: 24, paddingRight: 24 } }>
      <FormContainer>
        <FormRow>
          <FormColumn sm={ 4 } md={ 2 }>
            <PickDateTimeField name="datetime"
                               error={ errors.getError('datetime') }
                               type="datetime"
                               disableToolbar={ false }
                               value={ state.datetime }
              // defaultDateFrom={ moment() }
                               onChange={ handleChangeDate }
                               label="Дата и время встречи"/>
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <ManagersAutocomplete label="Кто встречает"
                                  error={ errors.getError('manager_id') }
                                  onChange={ (e, value) => handleChangeAutocomplete(value?.id, 'manager_id') }
                                  value={ String(state.manager_id) }
                                  placeholder="Виберите менеджера"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <MeetingTypeSelectField onChange={ handleChange }
                                    name="type"
                                    value={ state.type }
                                    error={ errors.getError('type') }/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <ClientAutocomplete doubleLabel
                                value={ state.client_name }
                                error={ errors.getError('client_id') }
                                onChange={ (e, value) => handleChangeAutocomplete(value?.id, 'client_id') }/>
          </FormColumn>
        </FormRow>


        { ( state.first_name && state.last_name && state.phone ) &&
          <>
            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 2 }>
                <TextField error={ errors.getError('first_name') }
                           fullWidth
                           name="first_name"
                           value={ state?.first_name }
                           onChange={ handleChange }
                           label="Имя"
                           placeholder="Введите имя"
                />
              </FormColumn>

              <FormColumn sm={ 4 }
                          md={ 2 }>
                <TextField error={ errors.getError('last_name') }
                           fullWidth
                           name="last_name"
                           value={ state?.last_name }
                           onChange={ handleChange }
                           label="Фамилия"
                           placeholder="Введите фамилию"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 2 }>
                <TextField error={ errors.getError('email') }
                           fullWidth
                           name="email"
                           value={ state?.email }
                           onChange={ handleChange }
                           label="Электронная почта"
                           placeholder="Введите электронную почту"
                />
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 2 }>
                <PhoneInput error={ errors.getError('phone') }
                            name="phone"
                            type="phone"
                            onChange={ handleChange }
                            value={ state?.phone }
                            label="Телефон"
                            placeholder="Введите телефон"/>
              </FormColumn>
            </FormRow>
          </>

        }


        <FormRow>
          <FormColumn xs={ 12 }
                      sm={ 8 }
                      md={ 4 }>
            <Divider/>
          </FormColumn>
        </FormRow>


        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <RealtyAutocomplete value={ String(state.realty_id) }
                                error={ errors.getError('realty_id') }
                                onChange={ (e, value) => handleChangeAutocomplete(value?.id, 'realty_id') }/>
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 2 }>
            <MeetingStatusSelectField onChange={ handleChange }
                                      name="status"
                                      value={ state.status }
                                      error={ errors.getError('status') }/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 8 }
                      md={ 4 }>
            <TextField error={ errors.getError('comment') }
                       name="comment"
                       value={ state.comment }
                       onChange={ handleChange }
                       label="Комментарий"
                       placeholder="Введите комментарий"/>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn sm={ 8 }
                      md={ 4 }>
            <TextField error={ errors.getError('address') }
                       name="address"
                       value={ state.address }
                       onChange={ handleChange }
                       label="Адрес встречи"
                       placeholder="Введите адрес"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 12 }>
            <ButtonWithProgress label="Сохранить"
                                loading={ loading }
                                onClick={ handleSubmit }/>

            { ( state.first_name && state.last_name && state.phone ) &&
              <ButtonWithProgress label="Создать клиент из встречи"
                                  style={ { marginLeft: 16 } }
                                  onClick={ handleOpenModal }/>
            }


          </FormColumn>
        </FormRow>
      </FormContainer>
      <ClientCreateModal/>
    </div>
  );
};

export default MeetingEditForm;
