import React from 'react';
import Loader from '../Loader';
import NoResultsMessage from '../NoResultsMessage';
import ErrorResultMessage from '../ErrorResultMessage';
import { Grid } from '@material-ui/core';
import { ABORT_REQUEST_ERROR } from '../Constants';

// loaderProps: {
//   noResultMessage: message
//   noResultIcon
// }

const LoaderContainerProgressive = ({ loading, children, error, data, loaderProps }) => {
  if ( loading ) {
    return (
      <Grid item xs={ 12 }>
        <Loader/>
      </Grid>
    );
  }

  if ( error && error.message !== ABORT_REQUEST_ERROR ) {
    return (
      <Grid item xs={ 12 }>
        <ErrorResultMessage/>
      </Grid>
    );
  }

  if ( Array.isArray(data) && !data?.length ) {
    return (
      <Grid item xs={ 12 }>
        <NoResultsMessage message={ loaderProps?.noResultMessage }
                          color={ loaderProps?.noResultBgColor }
                          hasIcon={ loaderProps?.noResultIcon }/>
      </Grid>
    );
  }

  if ( Array.isArray(data) && !!data?.length ) {
    return (
      <>{ children }</>
    );
  }

  return null;
};

export default LoaderContainerProgressive;
