import React, { useEffect } from 'react';
import { Grid, Link, TableCell, TableRow } from '@material-ui/core';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import FormDrawer from '../../components/FormDrawer';
import AddingButton from '../../components/AddingButton';
import { PlusIcon } from '../../icons';
import { removeRealtyGroup } from '../../actions/realtyGroupsActions';
import RealtyGroupsCreateForm from '../../components/RealtyGroups/RealtyGroupsGreateForm';
import DialogsRemove from '../../components/DialogsRemove';
import DeleteIcon from '@material-ui/icons/Delete';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import { ERROR, SNACKBAR_MESSAGES, SUCCESS } from '../../components/Constants';
import RealtyGroupFilter from '../../components/RealtyGroups/RealtyGroupFilter';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import TableTemplate from '../../components/TableTemplate';
import { REALTY_GROUPS_HEADERS } from '../../components/TableConstants';


const RealtyGroupsListPage = ({ ...props }) => {
  const { setPageTitle } = usePageTitle();
  const { data, loading, refetch: loadRealtyGroups } = useFetch({
    url: API_ROUTE.realtyGroups
  });

  useEffect(() => {
    setPageTitle('Групы объектов');
  });


  let handleRemoveGroup = (_, id) => {
    removeRealtyGroup(id)
      .then(() => {
        loadRealtyGroups();
        props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.realtyGroups.remove.success);
      })
      .catch((errors) => {
        props.openSnackbar(ERROR, errors);
      });
  };


  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Групы объектов"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление групы объектов"
                        button={ props => <AddingButton { ...props }
                                                        tooltip="Добавить групу объектов"
                                                        icon={ () => <PlusIcon/> }/> }>
              <RealtyGroupsCreateForm loadRealtyGroups={ loadRealtyGroups }/>
            </FormDrawer>
          </Grid>

          <Grid item xs={ 12 }>
            <RealtyGroupFilter/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>

        <TableTemplate data={ data?.results }
                       loading={ loading }
                       headers={ REALTY_GROUPS_HEADERS }>
          { data?.results && data?.results.map(group => (
            <TableRow key={ group.id }
                      hover>
              <TableCell>#{ group.id }</TableCell>
              <TableCell>
                <Link color="inherit"
                      component={ React.forwardRef((props, ref) => (
                        <RouterLink{ ...props }
                                   ref={ ref }
                                   to={ `/realty-groups/${ group.id }` }
                        />
                      )) }>
                  <b>{ group.name }</b>
                </Link>
              </TableCell>
              <TableCell>{ group.company.name }</TableCell>
              <TableCell>{ group.realty_count }</TableCell>
              <TableCell>
                { !group.realty_count &&
                  <DialogsRemove positive_answer="Да"
                                 negative_answer="Нет"
                                 question="Удалить групу объектов"
                                 question_text="Действительно удалить групу объектов ?"
                                 remover={ handleRemoveGroup }
                                 icon={ () => <DeleteIcon color="secondary"
                                                          fontSize="small"/> }
                                 id={ group.id }
                                 tooltip="Удалить групу объектов"/>
                }

              </TableCell>
            </TableRow>
          )) }
        </TableTemplate>
      </Grid>
    </Grid>
  );
};

export default connect(null, { openSnackbar })(withRouter(RealtyGroupsListPage));
