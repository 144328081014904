import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ClearFiltersButton from '../ClearFiltersButton';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import queryString from 'query-string';
import SearchField from '../SearchField';
import FilterSelectField from '../FilterFields/FilterSelectField';
import { flexAlignCenter } from '../JSStylesConstants';
import { requestType, statusType } from '../SelectConstats';

const BookingRequestFilters = ({ ...props }) => {
  const query = queryString.parse(props.location.search);

  let handleChangeRealty = (e, value) => {

    let newQuery = {
      ...query,
      page: 1,
      realty_id: value?.id
    };

    if ( !value ) delete newQuery.realty_id;

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });

  };

  let getParams = (key) => {

    return query[key] || '';
  };


  return (
    <Grid container
          spacing={ 2 }
          style={ { alignItems: 'center' } }>
      <Grid item>
        <RealtyAutocomplete style={ { minWidth: 250 } }
                            value={ getParams('realty_id') }
                            onChange={ handleChangeRealty }/>
      </Grid>
      <Grid item>
        <FilterSelectField options={ statusType }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Статус"
                           type="status"/>
      </Grid>
      <Grid item>
        <FilterSelectField options={ requestType }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Тип заявки"
                           type="type"/>
      </Grid>
      <Grid item>
        <SearchField fulWidth
                     searchField="name"
                     label="Клиент"
                     placeholder="Введите имя"/>
      </Grid>
      <Grid item
            style={ flexAlignCenter }>
        <ClearFiltersButton/>
      </Grid>
    </Grid>
  );
};

export default withRouter(BookingRequestFilters);
