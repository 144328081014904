import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import {
  getBookingStatus,
  getBookingUtilitiesPaymentStatus,
  getBookingUtilitiesStatus,
  getFormatNumber
} from '../Tools';
import { BOOKINGS_ROUTE, CLIENTS_ROUTE } from '../RouterConstants';
import { BOOKING_STATUS } from '../Constants';
import { flexAlignCenter, flexJustifyAround } from '../JSStylesConstants';
import { useTranslation } from 'react-i18next';

const BookingTableRow = ({ booking, ...props }) => {
  const { t } = useTranslation();

  return (
    <TableRow hover
              style={ !booking.paid && booking.status !== BOOKING_STATUS.close
                ? { backgroundColor: 'rgba(255, 157, 58, .25)' }
                : { backgroundColor: 'inherit' } }>
      <TableCell>#{ booking.id }</TableCell>
      <TableCell>
        { getBookingStatus(booking) }
      </TableCell>
      <TableCell>
        <Link style={ {
          maxWidth: 300,
          textDecoration: 'none',
          color: 'inherit',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'block'
        } }
              to={ `${ CLIENTS_ROUTE }/${ booking.client.id }` }
        >
          <strong>
            { booking.client.name }&nbsp;
            { booking.client.is_verified && <DoneIcon style={ { fontSize: 14 } }/> }
          </strong>
        </Link>
        <span style={ { fontSize: 13.5 } }>{ booking.client.phone }</span>
      </TableCell>
      <TableCell>
        <Link to={ `/realty/${ booking.realty.id }` }
              style={ { textDecoration: 'none', color: 'inherit' } }>
          { booking.realty.name }
        </Link>
      </TableCell>
      <TableCell>
        { booking.expected_deposit ? getFormatNumber(booking.expected_deposit) : '-' }/
        { booking.total_deposit ? getFormatNumber(booking.total_deposit) : '-' }
      </TableCell>

      <TableCell>
        { getFormatNumber(booking?.price_per_unit) }
      </TableCell>
      <TableCell>{ booking.paid_until }</TableCell>
      <TableCell>{ booking.departure_date }</TableCell>
      <TableCell>
        <Link style={ { textDecoration: 'none', ...flexAlignCenter } }
              to={ `${ BOOKINGS_ROUTE }/${ booking.id }` }>
          <Button variant="contained"
                  color="primary">
            { t('Подробнее') }
          </Button>
        </Link>
      </TableCell>
      <TableCell style={ { textAlign: 'center' } }>
        <div style={ { ...flexAlignCenter, ...flexJustifyAround } }>
          { getBookingUtilitiesStatus(booking, t) }
          { getBookingUtilitiesPaymentStatus(booking, t) }
        </div>
      </TableCell>
    </TableRow>
  );
};

export default BookingTableRow;
