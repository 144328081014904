import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import Errors from '../Errors';
import { updateBooking } from '../../actions/bookingsActions';
import { Grid } from '@material-ui/core';
import { EditIcon, EyeShowIcon } from '../../icons';
import TextField from '../TextField';
import moment from 'moment';
import PickDateField from '../PickDateField';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { Link } from 'react-router-dom';
import SwitchField from '../SwitchField';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import BookingContractButtons from './BookingContractButtons';
import { BOOKING_STATUS, DATE_FORMAT, MANAGER, MODERATOR, REAL_ESTATE_TYPE, SUCCESS } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';
import BookingStatusSelectField from './BookingStatusSelectField';
import { flex, flexCenter } from '../JSStylesConstants';


class BookingEditMainForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: '',
        client: '',
        created_at: '',
        rental_agreement: '',
        rent_type: '',
        files: '',
        arrival_date: '',
        departure_date: '',
        paid_until: '',
        comment: '',
        partial_rent: false,
        rent_area: '',
        door_keys: '',
        departure_manager: '',
        responsible_manager: '',
        arrival_manager: '',
        has_parking_pass: false,
        with_utility_bills: false,
        status: '',
        unActive: false,
        costInWords: '',
        realty: '',
        deposit_obligatory: false,
        expected_deposit: '',
        expected_deposit_in_words: ''
      },
      errors: new Errors(),
      loading: false
    };

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.client.clientable_type !== this.props.client.clientable_type ) {
      this.setBooking(this.props);
    }
  }

  componentDidMount() {
    this.setBooking(this.props);
    this.setDefaultManager();
  }

  setBooking = ({
                  id, client, arrival_date,
                  departure_date, paid_until,
                  comment, partial_rent, rent_area, rental_agreement, door_keys,
                  price_per_unit, responsible_manager, departure_manager, arrival_manager, parking_pass,
                  with_utility_bills, rent_type, status, costInWords, depositInWords, realty,
                  deposit_obligatory, expected_deposit, expected_deposit_in_words, created_at
                }) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        id: id,
        client: client,
        created_at: created_at,
        arrival_date: moment(arrival_date, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY'),
        departure_date: moment(departure_date, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY'),
        paid_until: moment(paid_until, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY'),
        comment: comment,
        partial_rent: partial_rent,
        rent_area: rent_area,
        rental_agreement: rental_agreement,
        door_keys: door_keys,
        arrival_manager: arrival_manager,
        departure_manager: departure_manager,
        responsible_manager: responsible_manager,
        price_per_unit: price_per_unit,
        has_parking_pass: parking_pass,
        with_utility_bills: with_utility_bills,
        rent_type: rent_type,
        status: status,
        unActive: status === BOOKING_STATUS.close,
        costInWords: costInWords,
        realty: realty,
        deposit_obligatory: deposit_obligatory,
        expected_deposit: expected_deposit,
        expected_deposit_in_words: expected_deposit_in_words
      },
    } ));
  };

  setDefaultManager = () => {
    const { user } = this.props;

    if ( user.userable_type === MANAGER ) {
      this.setState(prevState => ( {
        booking: {
          ...prevState.booking,
          arrival_manager: prevState.booking.arrival_manager
            ? {
              userable_id: prevState.booking.arrival_manager.userable_id,
              name: prevState.booking.arrival_manager.name
            }
            : { userable_id: user.userable_id, name: user.name },
          responsible_manager: prevState.booking.responsible_manager
            ? {
              userable_id: prevState.booking.responsible_manager.userable_id,
              name: prevState.booking.responsible_manager.name
            }
            : { userable_id: user.userable_id, name: user.name },
          departure_manager: prevState.booking.departure_manager
            ? {
              userable_id: prevState.booking.departure_manager.userable_id,
              name: prevState.booking.departure_manager.name
            }
            : { userable_id: user.userable_id, name: user.name },
        }
      } ));
    }
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };


  // handleRemoveUploadFile = (file) => {
  //   this.setState(prevState => ({
  //     booking: {
  //       ...prevState.booking,
  //       files: prevState.booking.files.filter(f => f !== file)
  //     }
  //   }));
  // };

  handleChangeDate = (key, value) => {

    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        [key]: value
      }
    } ));
  };


  handleChangeAutocomplete = (value, type) => {
    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        [type]: value
      }
    } ));
  };


  handleCheckUserType = () => {
    const { user } = this.props;
    const { booking } = this.state;


    if ( user.role === MANAGER ) {
      if ( booking.departure_manager?.id !== user.userable_id ) {
        return false;
      } else {
        return true;
      }
    } else if ( user.role === MODERATOR ) {
      return false;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { booking } = this.state;

    const data = {
      comment: booking.comment,
      rental_agreement: booking.rental_agreement,
      door_keys: booking.door_keys,
      arrival_manager_id: booking.arrival_manager?.userable_id || booking.arrival_manager?.id,
      responsible_manager_id: booking.responsible_manager?.userable_id || booking.responsible_manager?.id,
      price_per_unit: booking.price_per_unit,
      has_parking_pass: booking.has_parking_pass ? 1 : 0,
      with_utility_bills: booking.with_utility_bills ? 1 : 0,
      status: booking.status,
      departure_date: parseInt(moment(booking.departure_date, DATE_FORMAT.DD_MM_YYYY_DASH).unix()),
      costInWords: booking.costInWords,
      deposit_obligatory: booking.deposit_obligatory,
      expected_deposit: booking.expected_deposit,
      expected_deposit_in_words: booking.expected_deposit_in_words
    };

    if ( booking.status !== BOOKING_STATUS.active ) {
      data.departure_manager_id = booking.departure_manager?.userable_id || booking.departure_manager?.id;
    }

    updateBooking(booking.id, data)
      .then(booking => {
        this.props.loadBooking();
        this.setBooking(booking);
        this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
        this.setState({
          loading: false,
          errors: new Errors({})
        });
      }).catch(errors =>
      this.setState({
        loading: false,
        errors: new Errors(errors)
      })
    );
  };

  render() {
    const { booking, errors, loading } = this.state;
    const { realty } = this.props;

    return (
      <FormContainer>
        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <Grid container
                  spacing={ 2 }>
              <Grid item
                    xs={ 12 }>
                <Link color="inherit"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={ { textDecoration: 'none', color: 'rgba(0, 0, 0, .87)' } }
                      to={ `/clients/${ booking.client.id }` }>
                  <strong>
                    { booking.client.name }&nbsp;<EditIcon/>
                  </strong>
                </Link>
              </Grid>
              <Grid item
                    xs={ 12 }>
                { booking.client.phone }
              </Grid>
            </Grid>
          </FormColumn>

          <FormColumn sm={ 6 }
                      md={ 5 }
                      lg={ 4 }>
            <Grid container
                  spacing={ 2 }>
              <Grid item
                    xs={ 12 }>
                <Link color="inherit"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={ `/realty/${ realty.id }` }
                      style={ {
                        marginRight: '16px',
                        textDecoration: 'none',
                        color: 'rgba(0, 0, 0, .87)'
                      } }>
                  <strong>{ realty.name }</strong>&nbsp;
                  <EditIcon/>
                </Link>
                <a href={ `${ process.env.REACT_APP_URL }/zhilie/${ realty.slug }` }
                   target="_blank"
                   rel="noopener noreferrer"
                   style={ {
                     textDecoration: 'none',
                     color: 'rgba(0, 0, 0, 0.87)'
                   } }>
                  <EyeShowIcon/>
                </a>
              </Grid>
            </Grid>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <BookingStatusSelectField fullWidth
                                      label={ 'Статус' }
                                      disabled={ this.handleCheckUserType() }
                                      onChange={ this.handleChange }
                                      value={ booking.status }/>
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }
                      style={ flex }>
            <SwitchField error={ errors.getError('with_utility_bills') }
                         name="with_utility_bills"
                         onChange={ this.handleChange }
                         disabled={ this.handleCheckUserType() }
                         value={ booking.with_utility_bills }
                         label="С коммунальными платежами"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField error={ errors.getError('rental_agreement') }
                       name="rental_agreement"
                       disabled
                       value={ booking.rental_agreement }
                       onChange={ this.handleChange }
                       label="Договор"
                       placeholder="Введите номер договора"/>
          </FormColumn>
          <FormColumn style={ flexCenter }
                      sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>

            {/*hide in ua*/ }
            { realty.subtype !== REAL_ESTATE_TYPE.house &&
              <BookingContractButtons booking_id={ booking.id }
                                      client={ booking.client }
                                      rental_agreement={ booking.rental_agreement }
                                      created_at={ booking.created_at }
                                      loadBooking={ this.props.loadBooking }/>
            }
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 8 }
                      md={ 6 }
                      lg={ 4 }>
            <TextField error={ errors.getError('comment') }
                       name="comment"
                       rows={ 3 }
                       disabled={ this.handleCheckUserType() }
                       multiline
                       value={ booking.comment }
                       onChange={ this.handleChange }
                       label="Комментарий"
                       placeholder="Введите комментарий"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <PickDateField name="arrival_date"
                           type="arrival_date"
                           disabled
                           value={ booking.arrival_date }
                           defaultDateFrom={ moment().startOf('day') }
                           label="Дата въезда*"/>
          </FormColumn>

          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <PickDateField name="departure_date"
                           type="departure_date"
                           disabled={ this.handleCheckUserType() }
                           value={ booking.departure_date }
                           error={ errors.getError('departure_date') }
                           defaultDateFrom={ moment().startOf('day') }
                           onChange={ this.handleChangeDate }
                           label="Дата выезда"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <PickDateField name="paid_until"
                           type="paid_until"
                           disabled
                           value={ booking.paid_until }
                           defaultDateFrom={ moment().startOf('day') }
                           label="Оплачен до"/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }
                      style={ flex }>
            <SwitchField error={ errors.getError('partial_rent') }
                         name="partial_rent"
                         disabled={ this.handleCheckUserType() }
                         value={ booking.partial_rent }
                         label="Частичная занятость"
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField error={ errors.getError('price_per_unit') }
                       name="price_per_unit"
                       disabled={ this.handleCheckUserType() }
                       value={ booking.price_per_unit }
                       onChange={ this.handleChange }
                       label="Тариф"
                       placeholder="Тариф"
            />
          </FormColumn>

          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField error={ errors.getError('costInWords') }
                       name="costInWords"
                       disabled={ this.handleCheckUserType() }
                       value={ booking.costInWords }
                       onChange={ this.handleChange }
                       label="Стоимость аренды прописью"
                       placeholder="Стоимость аренды прописью"
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField
              error={ errors.getError('rent_type') }
              name="rent_type"
              value={ booking.rent_type }
              disabled
              onChange={ this.handleChange }
              label="Тип аренды"
            />
          </FormColumn>

          <FormColumn sm={ 2 }
                      style={ flex }>
            <SwitchField error={ errors.getError('deposit_obligatory') }
                         name="deposit_obligatory"
                         value={ booking.deposit_obligatory }
                         onChange={ this.handleChange }
                         label="Обязательный депозит"/>
          </FormColumn>
        </FormRow>


        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField error={ errors.getError('expected_deposit') }
                       name="expected_deposit"
                       type="number"
                       value={ booking.expected_deposit }
                       onChange={ this.handleChange }
                       label="Ожидаемый депозит"
                       placeholder="Введите ожидаемый депозит"
            />
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField error={ errors.getError('expected_deposit_in_words') }
                       name="expected_deposit_in_words"
                       value={ booking.expected_deposit_in_words }
                       onChange={ this.handleChange }
                       label="Депозит прописью"
                       placeholder="Введите депозит прописью"
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <TextField
              error={ errors.getError('door_keys') }
              name="door_keys"
              disabled={ this.handleCheckUserType() }
              value={ booking.door_keys }
              onChange={ this.handleChange }
              label="Ключи"
              placeholder="Ключи"/>
          </FormColumn>

          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }
                      style={ flex }>
            <SwitchField error={ errors.getError('has_parking_pass') }
                         name="has_parking_pass"
                         disabled={ this.handleCheckUserType() }
                         onChange={ this.handleChange }
                         value={ booking.has_parking_pass }
                         label="Пропуск на парковку"/>
          </FormColumn>
        </FormRow>


        <FormRow>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <ManagersAutocomplete label="Поселил"
                                  disabled={ this.handleCheckUserType() }
                                  error={ errors.getError('arrival_manager_id') }
                                  onChange={ (e, value) => this.handleChangeAutocomplete(value, 'arrival_manager') }
                                  value={ booking.arrival_manager }
                                  placeholder="Выберите менеджера"/>
          </FormColumn>
          <FormColumn sm={ 4 }
                      md={ 3 }
                      lg={ 2 }>
            <ManagersAutocomplete label="Ответственный"
                                  disabled={ this.handleCheckUserType() }
                                  error={ errors.getError('responsible_manager_id') }
                                  onChange={ (e, value) => this.handleChangeAutocomplete(value, 'responsible_manager') }
                                  value={ booking.responsible_manager }
                                  placeholder="Выберите менеджера"/>
          </FormColumn>
          { ( booking.status !== BOOKING_STATUS.active ) &&
            <FormColumn sm={ 4 }
                        md={ 3 }
                        lg={ 2 }>
              <ManagersAutocomplete label="Выселил"
                                    disabled={ this.handleCheckUserType() }
                                    error={ errors.getError('departure_manager_id') }
                                    onChange={ (e, value) => this.handleChangeAutocomplete(value, 'departure_manager') }
                                    value={ booking.departure_manager }
                                    placeholder="Выберите менеджера"/>
            </FormColumn>
          }
        </FormRow>


        <FormRow>
          <FormColumn>
            <ButtonWithProgress onClick={ this.handleSubmit }
                                loading={ loading }
                                label="Сохранить"/>
          </FormColumn>
        </FormRow>
      </FormContainer>
    );
  }
}

let mapStateToProps = (state) => ( {
  user: state.auth.user
} );

export default connect(mapStateToProps, { openSnackbar })(BookingEditMainForm);
