import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { ERROR, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { useTranslation } from 'react-i18next';
import { deleteRole } from '../../actions/permissionsActions';


const PermissionsRemove = ({ id, loadPermissions, ...props }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    deleteRole(id)
      .then(() => {
        props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.permissions.remove.success);
        loadPermissions();
      })
      .catch(() => {
        props.openSnackbar(ERROR, SNACKBAR_MESSAGES.permissions.remove.error);
      });

    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        style={ { marginTop: -16, marginBottom: -16, marginLeft: -8 } }
        color="secondary"
        onClick={ handleClickOpen }
      >
        <DeleteIcon fontSize="small"/>
      </Button>

      <Dialog
        open={ open }
        onClose={ handleClose }
        maxWidth="xs"
      >
        <DialogTitle>{ t('Удалить права доступа?') }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { t('Вы действительно хотите удалить права доступа?') }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleClose }
                  color="secondary">
            { t('Нет') }
          </Button>
          <Button onClick={ handleRemove }
                  color="primary" autoFocus>
            { t('Да') }
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default connect(null, { openSnackbar })(PermissionsRemove);
