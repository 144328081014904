import React from 'react';
import moment from 'moment';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import PickDateField from '../PickDateField';
import PhoneInput from '../PhoneInput';
import CountriesAutocomplete from '../Autocomplete/CountriesAutocomplete';
import { DATE_FORMAT } from '../Constants';


const ClientDetailIndividualFields = ({
                                        handleIndividualChange,
                                        handleChangeDate, individual,
                                        handleChangeAutocomplete, countries,
                                        errors, ...props }) => {

  return (
    <>
      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('first_name') }
                     name="first_name"
                     value={ individual.first_name }
                     onChange={ handleIndividualChange }
                     label="Имя"
                     placeholder="Введите имя"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('last_name') }
                     name="last_name"
                     value={ individual.last_name }
                     onChange={ handleIndividualChange }
                     label="Фамилия"
                     placeholder="Введите фамилию"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('middle_name') }
                     name="middle_name"
                     value={ individual.middle_name }
                     onChange={ handleIndividualChange }
                     label="Отчество"
                     placeholder="Введите отчество"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn sm={ 2 }>
          <PickDateField views={ ['year', 'month', 'date'] }
                         openTo={ 'year' }
                         disableToolbar={ false }
                         name="birth_date"
                         type="birth_date"
                         error={ errors.getError('birth_date') }
                         value={ individual.birth_date }
                         onChange={ handleChangeDate }
                         label="Дата рождения"/>
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('place_of_birth') }
                     name="place_of_birth"
                     value={ individual.place_of_birth }
                     onChange={ handleIndividualChange }
                     label="Место рождения"
                     placeholder="Точь в точь как в паспорте"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn sm={ 2 }>
          <CountriesAutocomplete value={ individual.iso }
                                 error={ errors.getError('iso') }
                                 onChange={ (e, value) => handleChangeAutocomplete(value, 'iso') }/>
        </FormColumn>
      </FormRow>

      { individual.iso &&
        <>
          <FormRow>
            <FormColumn sm={ 2 }>
              <TextField error={ errors.getError('passport_series') }
                         name="passport_series"
                         value={ individual.passport_series }
                         onChange={ handleIndividualChange }
                         label="Серия паспорта"
                         placeholder="Введите серию паспорта"
              />
            </FormColumn>
            <FormColumn sm={ 2 }>
              <TextField error={ errors.getError('passport_id') }
                         name="passport_id"
                         value={ individual.passport_id }
                         onChange={ handleIndividualChange }
                         label="Номер паспорта"
                         placeholder="Введите номер паспорта"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={ 2 }>
              <TextField error={ errors.getError('passport_issued') }
                         name="passport_issued"
                         value={ individual.passport_issued }
                         onChange={ handleIndividualChange }
                         label="Кем выдан паспорт"
                         placeholder="Введите кем выдан паспорт"
              />
            </FormColumn>
            <FormColumn sm={ 2 }>
              <TextField error={ errors.getError('passport_department_id') }
                         name="passport_department_id"
                         value={ individual.passport_department_id }
                         onChange={ handleIndividualChange }
                         label="Код подразделения"
                         placeholder="Введите код подразделения"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={ 2 }>
              <PickDateField name="passport_issue_date"
                             type="passport_issue_date"
                             error={ errors.getError('passport_issue_date') }
                             value={ moment(individual.passport_issue_date, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY') }
                             views={ ['year', 'month', 'date'] }
                             openTo={ 'year' }
                             disableToolbar={ false }
                             onChange={ handleChangeDate }
                             label="Когда выдан паспорт"/>
            </FormColumn>
            <FormColumn sm={ 2 }>
              <PickDateField name="passport_validity"
                             type="passport_validity"
                             error={ errors.getError('passport_validity') }
                             views={ ['year', 'month', 'date'] }
                             openTo={ 'year' }
                             disableToolbar={ false }
                             value={ moment(individual.passport_validity, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY') }
                             onChange={ handleChangeDate }
                             label="Паспорт действует до"/>
            </FormColumn>
          </FormRow>
        </>
      }


      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('place_of_registration') }
                     name="place_of_registration"
                     value={ individual.place_of_registration }
                     onChange={ handleIndividualChange }
                     label="Адрес регистрации"
                     placeholder="Введите адрес"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('mailing_address') }
                     name="mailing_address"
                     value={ individual.mailing_address }
                     onChange={ handleIndividualChange }
                     label="Почтовый адрес"
                     placeholder="Введите почтовый адрес"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn sm={ 2 }>
          <TextField error={ errors.getError('email') }
                     name="email"
                     value={ individual.email }
                     onChange={ handleIndividualChange }
                     label="Электронная почта"
                     placeholder="Введите электронную почту"
          />
        </FormColumn>
        <FormColumn sm={ 2 }>
          <PhoneInput error={ errors.getError('phone') }
                      name="phone"
                      type="phone"
                      onChange={ handleIndividualChange }
                      value={ individual.phone }
                      label="Телефон"
                      placeholder="Введите телефон"/>
        </FormColumn>
      </FormRow>
    </>
  );
};

export default ClientDetailIndividualFields;
