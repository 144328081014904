import axios from 'axios';
import queryString from 'query-string';
import { API_ROUTE } from '../api/routes';

export const fetchRealtyGroups = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.realtyGroups }?${ queryString.stringify(query) }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};


export const createRealtyGroups = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(API_ROUTE.realtyGroups, data)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const getRealtyGroup = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.realtyGroups }/${ id }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const updateRealtyGroup = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${ API_ROUTE.realtyGroups }/${ id }`, data)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const removeRealtyGroup = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${ API_ROUTE.realtyGroups }/${ id }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};
