import React, { memo, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import RealtyGroupsEditForm from '../../components/RealtyGroups/RealtyGroupsEditForm';
import { usePageTitle } from '../../hooks';
import { useTranslation } from 'react-i18next';


const RealtyGroupsEditPage = ({ match: { params }, ...props }) => {
  const [id, setId] = useState(null);
  const { t } = useTranslation();
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle('Редактирование групы объектов');
    setId(params.id);
  }, [params, setPageTitle]);

  if ( !id ) {
    return null;
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader title={ t('Редактирование групы объектов') }
                    subheader={ `ID: ${ id }` }/>

        <Divider/>

        <div style={ { paddingTop: 24, paddingBottom: 24 } }>
          <RealtyGroupsEditForm id={ id }/>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default memo(RealtyGroupsEditPage);

