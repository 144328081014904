import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import RealtyStatisticTable from '../../components/Realty/RealtyStatisticTable';
import { useFetch, usePageTitle } from '../../hooks';
import RealtyStatisticFilter from '../../components/Realty/RealtyStatisticFilter';
import Loader from '../../components/Loader';
import { API_ROUTE } from '../../api/routes';

const RealtyOccupancyListPage = () => {
  const { setPageTitle } = usePageTitle();
  const { data, loading } = useFetch({ url: API_ROUTE.realtyOccupancy });

  useEffect(() => {
    setPageTitle('Зайнятость обьектов');
  });

  return (
    <Grid container
          spacing={ 2 }>
      <Grid item>
        <RealtyStatisticFilter/>
      </Grid>
      <Grid item
            xs={ 12 }>

        { ( !!data?.dates?.length && !!data?.statistic?.length )
          ? <RealtyStatisticTable dates={ data?.dates }
                                  loading={ loading }
                                  data={ data?.statistic }/>
          : <Grid item
                  xs={ 12 }>
            <Loader/>
          </Grid> }

      </Grid>
    </Grid>
  );
};

export default RealtyOccupancyListPage;
