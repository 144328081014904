import React from 'react';
import { Card, CardHeader, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ErrorLoadingDataMessage = ({ title, ...props }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <Card>
          <CardHeader style={ { textAlign: 'center' } }
                      title={ t(title) }/>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ErrorLoadingDataMessage;
