import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import BookingEditMainForm from './BookingEditMainForm';
import BookingMoneyForm from './BookingMoneyForm';

const BookingMainForm = ({ booking, loadBooking, ...props }) => {
  return (
    <Grid container
          spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <BookingEditMainForm { ...booking }
                             loadBooking={ loadBooking }/>
      </Grid>
      <Grid item
            xs={ 12 }>
        <Divider/>
      </Grid>
      <Grid item
            xs={ 12 }>
        <BookingMoneyForm booking={ booking }
                          loadBooking={ loadBooking }/>
      </Grid>
    </Grid>
  );
};

export default BookingMainForm;
