import React from 'react';
import { ColdIcon, ElectroIcon, GasIcon, HotIcon } from '../../icons';

const BasicUtilitiesIcon = ({ icon_id, ...props }) => {

  switch ( icon_id ) {
    case 1:
      return <ColdIcon/>;
    case 2:
      return <HotIcon/>;
    case 3:
      return <ElectroIcon style={ { width: 14 } }/>;
    case 4:
      return <GasIcon/>;
    default:
      return null;
  }
};

export default BasicUtilitiesIcon;
