import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PickDateField from '../PickDateField';
import moment from 'moment';
import queryString from 'query-string';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import SearchField from '../SearchField';
import LogsActionsSelect from './LogsActionsSelect';
import FilterLayout from '../FilterLayout';


const FilterLogs = ({ ...props }) => {

  let handleChangeAutocomplete = (value, type) => {
    const query = queryString.parse(props.location.search);

    let newQuery = {
      ...query,
      page: query.page ? 1 : query.page,
      [type]: value?.id,
    };


    if ( !value ) delete newQuery[type];

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });
  };

  let handleChangeDate = (key, value, type) => {
    const query = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        page: query.page ? 1 : query.page,
        [key]: type === 'to'
          ? moment(value, 'DD/MM/YYYY').endOf('day').format('X')
          : moment(value, 'DD/MM/YYYY').format('X')
      })
    });
  };

  let getParam = (params) => {
    const query = queryString.parse(props.location.search);

    return query[params] || '';
  };

  return (
    <FilterLayout>
      <Grid item>
        <ManagersAutocomplete label="Менеджер"
                              style={ { minWidth: 240 } }
                              onChange={ (e, value) => handleChangeAutocomplete(value, 'manager_id') }
                              value={ getParam('manager_id') }
                              placeholder="Виберите менеджера"/>
      </Grid>

      <Grid item>
        <PickDateField name="from"
                       type="from"
                       value={ moment(getParam('from'), 'X').format('DD/MM/YYYY') }
                       onChange={ (key, value) => handleChangeDate(key, value, 'from') }
                       label="Дата от"/>
      </Grid>
      <Grid item>
        <PickDateField name="to"
                       type="to"
                       value={ moment(getParam('to'), 'X').format('DD/MM/YYYY') }
                       onChange={ (key, value) => handleChangeDate(key, value, 'to') }
                       label="Дата до"/>
      </Grid>
      <Grid item>
        <SearchField searchField="search"
                     fulWidth
                     label="Поиск"
                     placeholder="Введите имя/email/ID"/>
      </Grid>
      <Grid item>
        <LogsActionsSelect/>
      </Grid>

    </FilterLayout>
  );
};

export default withRouter(FilterLogs);
