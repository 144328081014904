import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const usePageTitle = () => {
  const { t } = useTranslation();

  const setPageTitle = useCallback((title) => {
    document.title = t(title);
  }, [t]);

  return {
    setPageTitle
  };
};
