import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import BookingCreateForm from '../../components/Booking/BookingCreateForm';
import { usePageTitle } from '../../hooks';


const BookingCreatePage = () => {
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle('Создание заказа');
  });

  return (
    <Paper>
      <Grid container spacing={ 2 } style={ { padding: '24px' } }>
        <Grid item sm={ 2 }>
          <PageHeader title="Создание заказа"
                      size="md"/>
        </Grid>

        <Grid item xs={ 12 }>
          <BookingCreateForm/>
        </Grid>
      </Grid>
    </Paper>

  );
};

export default BookingCreatePage;
