import React, { PureComponent } from 'react';
import { Button, Grid, TableCell, TableRow } from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { withRouter } from 'react-router-dom/';
import {
  fetchBookingRequestCountNew,
  fetchBookingRequests,
  updateBookingRequestStatus
} from '../../actions/bookingReqestsActions';
import BookingRequestFilters from '../../components/BookingRequests/BookingRequestsFilters';
import { Link } from 'react-router-dom';
import RequestStatusSelect from '../../components/BookingRequests/RequestStatusSelect';
import Errors from '../../components/Errors';
import BookingRequestCreateForm from '../../components/BookingRequests/BookingRequestCreateForm';
import PageHeader from '../../components/PageHeader';
import AddingButton from '../../components/AddingButton';
import { PlusIcon } from '../../icons';
import { fetchCountBookingRequest } from '../../actions/drawerActions';
import { setPageTitle } from '../../components/Tools';
import { ERROR, SNACKBAR_MESSAGES, SUCCESS } from '../../components/Constants';
import { withTranslation } from 'react-i18next';
import { BOOKING_REQUESTS_ROUTE } from '../../components/RouterConstants';
import TableTemplate from '../../components/TableTemplate';
import { BOOKING_REQUEST_HEADERS } from '../../components/TableConstants';


class BookingRequestsListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      meta: {},
      requests: [],
      errors: new Errors(),
      loading: false
    };
  }

  componentDidMount = () => {
    setPageTitle('Заявки', this.props.t);
    this.loadRequests();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadRequests();
    }
  }

  loadRequests = () => {
    const query = queryString.parse(this.props.location.search);

    this.setState((prevState) => ( {
      ...prevState,
      loading: true
    } ));

    fetchBookingRequests({ ...query })
      .then((response) => {
        this.setState({
          requests: response.data,
          meta: response.meta
        });
      })
      .finally(() => {
        this.setState((prevState) => ( {
          ...prevState,
          loading: false
        } ));
      });
  };


  handleStatusChange = (e, id) => {
    const { value } = e.target;

    updateBookingRequestStatus(id, { status: value })
      .then(() => {
        this.loadRequests();
        this.handleUpdateNewRequestCount();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.status.success);
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
        this.props.openSnackbar(ERROR, errors);
      });
  };

  handleUpdateNewRequestCount = () => {
    fetchBookingRequestCountNew()
      .then((response) => {
        this.props.fetchCountBookingRequest(response.total);
      })
      .catch((error) => {
        console.error(error);
      })
  };

  render() {
    const { requests, meta, loading } = this.state;

    if ( !requests ) {
      return null;
    }

    return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <Grid container
                spacing={ 2 }
                style={ { alignItems: 'center' } }>
            <Grid item>
              <PageHeader title="Заявки"
                          size="md"/>
            </Grid>

            <Grid item>
              <FormDrawer title="Добавление заявки"
                          button={ props => <AddingButton { ...props }
                                                          tooltip="Создать заявку"
                                                          icon={ () => <PlusIcon/> }/> }>
                <BookingRequestCreateForm loadRequests={ this.loadRequests }/>
              </FormDrawer>
            </Grid>
          </Grid>
        </Grid>

        <Grid item
              xs={ 12 }>
          <BookingRequestFilters/>
        </Grid>


        <Grid item
              xs={ 12 }>
          <TableTemplate meta={ meta }
                         data={ requests }
                         loading={ loading }
                         headers={ BOOKING_REQUEST_HEADERS }>
            { requests.map(request => (
              <TableRow key={ request.id } hover>
                <TableCell>#{ request.id }</TableCell>
                <TableCell>
                  <RequestStatusSelect value={ request.status }
                                       onChange={ (e) => this.handleStatusChange(e, request.id) }/>
                </TableCell>
                <TableCell>
                  { request.text_request_type }
                </TableCell>
                <TableCell>{ request.name }</TableCell>
                <TableCell>{ request?.realty?.name }</TableCell>
                <TableCell>{ request.created_at }</TableCell>
                <TableCell>
                  <Link to={ `${ BOOKING_REQUESTS_ROUTE }/${ request.id }` }
                        style={ { textDecoration: 'none' } }>
                    <Button color="primary"
                            variant="contained">
                      { this.props.t('Детали') }
                    </Button>
                  </Link>

                </TableCell>
              </TableRow>
            )) }
          </TableTemplate>
        </Grid>

      </Grid>
    );
  }
}

export default connect(null, {
  openSnackbar,
  fetchCountBookingRequest
})(withRouter(withTranslation()(BookingRequestsListPage)));
