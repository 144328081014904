import React from 'react';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import Title from '../Title';
import RealtyStatusField from './RealtyStatusField';
import { Link } from 'react-router-dom';
import { EditIcon, EyeShowIcon } from '../../icons';
import NoPhoto from '../NoPhoto';
import { useTranslation } from 'react-i18next';

const StyledFormControlLabel = withStyles({
  root: {},
  label: {
    fontSize: 12
  },
})(FormControlLabel);

const RealtyMainPart = ({ imageSrc, id, type, company, name, slug, published, status, activeBookings, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className="main-info">
      <div className="main-info__top">
        <div className="main-info__inner">
          <div className="main-info__photo">
            { imageSrc
              ? <img src={ imageSrc } alt={ name }/>
              : <NoPhoto/>
            }

          </div>
          <div className="main-info__info">
            <div className="main-info__type">
              <span>#{ id }</span>
              { t(type) }
            </div>
            <div className="main-info__name">
              <EditIcon/>&nbsp;
              <Link color="inherit"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={ `/realty/${ id }` }
                    style={ {
                      paddingRight: '8px',
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, .87)'
                    } }>
                <strong>{ name }</strong>
              </Link>
              { published &&
                <a href={ `${ process.env.REACT_APP_URL }/zhilie/${ slug }` }
                   target="_blank"
                   rel="noopener noreferrer"
                   style={ {
                     textDecoration: 'none',
                     color: 'rgba(0, 0, 0, 0.87)'
                   } }>
                  <EyeShowIcon/>
                </a>
              }

            </div>
          </div>
        </div>

        <div className="main-info__status-employment">
          <RealtyStatusField value={ status }
                             disabled
                             fullWidth/>
        </div>
      </div>

      <div className="main-info__bottom">
        <div className="main-info__bottom-left">
          <div className="main-info__published">
            <Title size="sm"
                   className="main-info__published-title"
                   title="Статус опубликованности:"/>

            <StyledFormControlLabel
              control={
                <Switch
                  checked={ published }
                  name="published"
                  color="primary"
                />
              }
              labelPlacement="end"
              label={ t('Опубликован') }
            />
          </div>
          <div className="main-info__company">
            <Title size="sm"
                   title="Компания:"/>
            <div className="main-info__status">
              { company }
            </div>
          </div>
        </div>
        { status !== 1 &&

          <div className="main-info__bottom-right">
            <div className="main-info__order">
              { activeBookings.map(active => (
                <Link key={ active.id }
                      className="last-order-link"
                      to={ `/bookings/${ active.id }` }>
                  { active.rental_agreement }
                </Link>
              )) }
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default RealtyMainPart;
