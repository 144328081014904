import React, { memo, useState } from 'react';
import './StatisticGroups.scss';
import StatisticApartmentContainer from '../StatisticApartment/StatisticApartmentContainer';
import Title from '../Title';
import { ArrowRightIcon } from '../../icons';
import StatisticIncome from '../StatisticIncome';
import Efficiency from '../Efficiency/Efficiency';
import StatisticOutcome from '../StatisticOutcome';
import StatisticPayments from '../StatisticPayments';
import { useTranslation } from 'react-i18next';


const StatisticGroups = ({
                           name, avg_expected_yearly_profit, avg_occupation_percentage, avg_yearly_profit,
                           id, income, occupied_count, outcome, paid_rent_sum, payment_expected_sum,
                           profit, realty_count, staff_outcome, realty_outcome, debt, actual_yearly_profit,
                           ...props
                         }) => {
  const {t} = useTranslation();

  const [show, setShow] = useState(false);


  let handleShow = () => {
    setShow(!show);
  };


  return (
    <>
      <div className="statistic-group"
           onClick={ handleShow }>
        <div className="statistic-group__wrapper">

          <div className="statistic-group__part statistic-group__main">

            <Title title={ name }
                   className="statistic-group__title group-title"
                   size="md"/>
            <div className="statistic-group__realty-count">
              ({ t('Объектов') } { realty_count })
            </div>
          </div>

          { debt
            ? <div className="statistic-group__part statistic-group__outcome">
              <StatisticOutcome title="Общий расход"
                                debt={ debt }
                                realty_outcome={ realty_outcome }
                                staff_outcome={ staff_outcome }/>
            </div>
            : null
          }

          { ( income || outcome || profit )
            ? <div className="statistic-group__part statistic-group__profit">
              <StatisticIncome income={ income }
                // yearly_profit={ avg_expected_yearly_profit }
                               outcome={ outcome }
                               profit={ profit }
                               orange
                               title={ `Общий доход` }/>
            </div>
            : null
          }

          <div className="statistic-group__part statistic-group__efficiency">
            <Efficiency title={ `Общая эффективность` }
                        percentage={ avg_occupation_percentage }
                        profit={ actual_yearly_profit }/>
          </div>

          <div className="statistic-group__part statistic-group__payments">
            <StatisticPayments paidRent={ paid_rent_sum }
                               pendingPayments={ payment_expected_sum }/>
          </div>

          <div className="statistic-group__part statistic-group__arrow">
            <div className={ `arrow-button ${ show ? 'arrow-button--rotate' : '' }` }>
              <ArrowRightIcon/>
            </div>
          </div>

        </div>
      </div>
      { show && <StatisticApartmentContainer group_id={ id }/> }
    </>
  );
};

export default memo(StatisticGroups);
