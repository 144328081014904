import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './LoginForm.scss';
import { connect } from 'react-redux';
import Errors from '../Errors';
import { Grid, withStyles } from '@material-ui/core';
import { login } from '../../actions/authActions';
import TextField from '../TextField';
import PasswordField from '../PasswordField';
import { ENTER_KEY, MANAGER } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';

const initialState = {
  data: {
    email: '',
    password: ''
  },
  showPassword: '',
};

const styles = (theme) => ({
  button: {
    padding: '8px 16px'
  },
});

const LoginForm = ({classes, ...props}) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const [errors, setErrors] = useState(new Errors({}));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    history.push({
      pathname: '/'
    });
  }, [history]);

  let handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };



  let handlePressEnter = (e) => {
    if ( e.keyCode === ENTER_KEY ) {
      handleSubmit(e);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const {data} = state;

    props.login(data)
        .then(user => {
          setLoading(false);
          setErrors(new Errors({}));
          if ( user.userable_type === MANAGER ) {
            history.push({
              pathname: '/notifications'
            });
            // window.location = '/notifications';
          } else {
            history.push({
              pathname: '/statistic'
            });
            // window.location = '/statistic';
          }
        })
        .catch((errors) => {
          setLoading(false);
          setErrors(new Errors(errors));
        });
  };

  return (
      <Grid container
            spacing={ 3 }
            style={ {margin: 'auto', maxWidth: 380} }>
        <Grid item
              xs={ 12 }>
          <TextField
              fullWidth
              error={ errors.getError('email') }
              name="email"
              value={ state.data.email }
              onChange={ handleChange }
              label="Электронная почта"
              placeholder="Введите электронную почту"/>
        </Grid>
        <Grid item
              xs={ 12 }>
          <PasswordField
              error={ errors.getError('password') }
              name="password"
              value={ state.data.password }
              onChange={ handleChange }
              onKeyDown={ e => handlePressEnter(e) }
              label="Пароль"
              placeholder="Введите пароль"
          />
        </Grid>
        <Grid item xs={ 12 }>
          <ButtonWithProgress onClick={ handleSubmit }
                              loading={ loading }
                              fullWidth
                              className={ classes.button }
                              label="Войти"/>
        </Grid>
      </Grid>
  );

};


export default connect(null, {login})(withStyles(styles)(LoginForm));
