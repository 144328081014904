import React, { PureComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, TableCell, TableRow } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FormDrawer from './../../components/FormDrawer';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { fetchClients } from '../../actions/clientsActions';
import ClientCreateForm from '../../components/Client/ClientCreateForm';
import { withRouter } from 'react-router-dom/';
import ClientsFilter from '../../components/Client/ClientsFilter';
import { PlusIcon } from '../../icons';
import AddingButton from '../../components/AddingButton';
import PageHeader from '../../components/PageHeader';
import { formatPhoneNumber, generateActiveStrStatus, setPageTitle } from '../../components/Tools';
import { withTranslation } from 'react-i18next';
import TableTemplate from '../../components/TableTemplate';
import { CLIENTS_HEADERS } from '../../components/TableConstants';

class ClientsListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      meta: null,
      clients: [],
      loading: false
    };
  }

  componentDidMount = () => {
    setPageTitle('Клиенты', this.props.t);
    this.loadClients();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadClients();
    }
  }

  loadClients = () => {
    const query = queryString.parse(this.props.location.search);

    this.setState((prev) => ( { ...prev, loading: true } ));

    fetchClients(query)
      .then((response) => {
        this.setState({
          clients: response.data,
          meta: response.meta
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState((prev) => ( { ...prev, loading: false } ));
      });
  };

  render() {
    const { clients, meta, loading } = this.state;
    const { t } = this.props;

    if ( !clients ) {
      return null;
    }

    return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <Grid container
                spacing={ 2 }
                style={ { alignItems: 'center' } }>
            <Grid item>
              <PageHeader title="Клиенты"
                          size="md"/>
            </Grid>
            <Grid item>
              <FormDrawer title="Добавление клиента"
                          button={ props => <AddingButton { ...props }
                                                          tooltip="Добавить клиента"
                                                          icon={ () => <PlusIcon/> }/> }>
                <ClientCreateForm loadClients={ this.loadClients }/>
              </FormDrawer>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={ 12 }>
          <ClientsFilter/>
        </Grid>

        <Grid item xs={ 12 }>
          <TableTemplate meta={ meta } data={ clients } loading={ loading } headers={ CLIENTS_HEADERS }>
            { clients && clients.map(client => (
              <TableRow key={ client.id } hover>
                <TableCell>#{ client.id }</TableCell>
                <TableCell>
                  <Link
                    style={ {
                      maxWidth: 300,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      display: 'block'
                    } }

                    color="inherit"
                    component={ React.forwardRef((props, ref) => (
                      <RouterLink
                        { ...props }
                        ref={ ref }
                        to={ `/clients/${ client.id }` }
                      />
                    )) }
                  >
                    <strong>
                      { client.name }&nbsp;
                      { client.is_verified && <DoneIcon style={ { fontSize: 14 } }/> }
                    </strong>
                  </Link>
                </TableCell>
                <TableCell>{ formatPhoneNumber(client.phone) }</TableCell>
                <TableCell>{ client.email }</TableCell>
                <TableCell>
                  <Link
                    color="inherit"
                    component={ React.forwardRef((props, ref) => (
                      <RouterLink
                        { ...props }
                        ref={ ref }
                        to={ `/bookings?client=${ client.last_name }` }
                      />
                    )) }
                  >
                    { client.bookings_count } шт.
                  </Link>
                </TableCell>
                <TableCell>{ client.last_activity }</TableCell>
                <TableCell>{ generateActiveStrStatus(client.active, t) }</TableCell>
              </TableRow>
            )) }
          </TableTemplate>
        </Grid>

      </Grid>
    );
  }
}

export default connect(null, { openSnackbar })(withRouter(withTranslation()(ClientsListPage)));
