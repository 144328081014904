import moment from 'moment-timezone';
import 'moment/locale/ru';
import 'moment/locale/uk';

moment.tz.setDefault(process.env.REACT_APP_TIMEZONE);

moment.updateLocale('ru', {
  months: 'Января_Февраля_Марта_Апреля_Мая_Июня_Июля_Августа_Сентября_Октября_Ноября_Декабря'.split('_'),
  monthsShort: 'Янв_Фев_Мар_Апр_Мая_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'),
});

moment.updateLocale('uk', {
  months: ['Січня', 'Лютого', 'Березня', 'Квітня', 'Травня', 'Червня', 'Липня', 'Серпня', 'Вересня', 'Жовтня', 'Листопада', 'Грудня'],
  monthsShort: 'Січ_Лют_Бер_Кві_Трав_Чер_Лип_Серп_Вер_Жов_Лис_Гру'.split('_')
});

