export const OFFICE = 'Office';
export const PARKING = 'Parking';
export const APARTMENT = 'Apartment';
export const PANTRY = 'Pantry';
export const LAND = 'Land';

export const VER_RU = 'ru';
export const VER_UA = 'ua';
export const VER_MD = 'md';

export const APP_LANG_VERSION = {
  ru: 'ru',
  ua: 'ua',
  md: 'md'
};

export const REAL_ESTATE_TYPE = {
  loft: 'Loft',
  house: 'House',
  flat: 'Flat',
};

export const COMPANY_ID = 1;
export const LABEL_WIDTH_SM = 120;
export const ONE_HUNDRED_PERCENT = 100;

export const MODERATOR = 'Moderator';
export const MANAGER = 'Manager';
export const ENTER_KEY = 13;
export const BLOB_TYPE = 'blob';
export const TIME_INTERVAL_REQUEST_CHAT = 10000;
export const VISIBLE = 'visible';
export const HIDDEN = 'hidden';


export const RENT_TYPE = {
  short: 'Посуточно',
  long: 'Долгосрочно'
};


export const ERROR_500_MESSAGE = 'Запрос не может быть обработан. Попробуйте позже.';
export const ERROR_NETWORK = 'Отсутствует подключение к интернету.';

export const NOT_FOUND = 'К сожалению по таким параметрам ничего не найдено.';

export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';

export const FORBIDDEN = 'FORBIDDEN';

//bookings statuses
export const BOOKING_STATUS = {
  active: 1,
  close: 2,
  debtor: 4,
  creditor: 5
};

//realty statuses

export const REALTY_STATUS = {
  free: 1,
  booked: 2,
  engaged: 3,
  cleaning: 4,
  partialRent: 5,
  sold: 6
};


export const ABORT_REQUEST_ERROR = 'canceled';


export const REQUEST_RENT = 'rent';
export const REQUEST_INFO = 'info';
export const REQUEST_ON_SELL = 'on_sell';
export const REQUEST_TIME_TO_AVAILABILITY = 'time_to_availability';


export const REALTY_DOCUMENTS = [
  { type: 'type_1', title: 'Выписка ЕГРН' },
  { type: 'type_2', title: 'Свидетельство о государственной регистрации права' },
  { type: 'type_3', title: 'Договор купли-продажи' },
  { type: 'type_4', title: 'Договор долевого участия' },
  { type: 'type_5', title: 'Предварительный договор купли-продажи' },
  { type: 'type_6', title: 'Акт приема передачи' },
  { type: 'type_7', title: 'Соглашение о передаче помещения для производства ремонтных работ' },
  { type: 'type_8', title: 'Справка о состоянии взаимных расчетов' },
  { type: 'type_9', title: 'Уведомление о снятии обременений' },
  { type: 'type_10', title: 'Кадастровый паспорт' },
  { type: 'type_11', title: 'Соглашение о разделе общего имущества' },
  { type: 'type_12', title: 'Договор на эксплуатационное обслуживание' },
];


export const getTypeDocument = (type) => {
  return REALTY_DOCUMENTS_TYPES[type] || 'Другой документ';
};

export const REALTY_DOCUMENTS_TYPES = {
  1: 'Выписка ЕГРН',
  2: 'Свидетельство о государственной регистрации права',
  3: 'Договор купли-продажи',
  4: 'Договор долевого участия',
  5: 'Предварительный договор купли-продажи',
  6: 'Акт приема передачи',
  7: 'Соглашение о передаче помещения для производства ремонтных работ',
  8: 'Справка о состоянии взаимных расчетов',
  9: 'Уведомление о снятии обременений',
  10: 'Кадастровый паспорт',
  11: 'Соглашение о разделе общего имущества',
  12: 'Договор на эксплуатационное обслуживание'
};


export const CLIENT_DONT_PAYMENT_COMMUNAL = 'Клиент не оплатил коммуналку в этом месяце';
export const CLIENT_DONT_SEND_METER_DATA = 'Клиент не подал показания счетчиков в этом месяце';

export const TOOLTIP_TOP_START = 'top-start';
export const TOOLTIP_RIGHT_START = 'right-start';
export const TOOLTIP_TOP = 'top';


//client types
export const CLIENT_INDIVIDUAL = 'ClientIndividual';
export const CLIENT_ENTITY = 'ClientEntity';

export const NETWORK_ERROR = 'Network Error';

export const RESPONSE_STATUS_403 = 403;
export const RESPONSE_STATUS_404 = 404;
export const RESPONSE_STATUS_500 = 500;
export const RESPONSE_STATUS_401 = 401;
export const RESPONSE_STATUS_419 = 419;

export const MAX_SITE_META_DESCRIPTION = 158;
export const MAX_SITE_DESCRIPTION = 2000;

export const DATE_FORMAT = {
  DD_MM_YYYY: 'DD.MM.YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MM_YYYY_HH_mm: 'DD.MM.YYYY, HH:mm',
  HH: 'HH',
  H: 'H',
  HH_mm: 'HH:mm',
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  DD_MMM_YYYY_HH_mm: 'DD MMM YYYY HH:mm',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  DD_MMM_dd: 'DD MMM dd',
  DD_MMM: 'DD MMM',
  D: 'D',
  YYYY_MM: 'YYYY-MM',
  DD_MM_YYYY_DASH: 'DD-MM-YYYY'
};

export const I18N_LANGUAGE = 'i18nextLng';

export const LANGUAGES = [
  {
    name: 'ua',
    value: 'uk',
    // icon: <UaFlagIcon/>
  },
  {
    name: 'ru',
    value: 'ru',
    // icon: <RuFlagIcon/>
  }
];

export const LOCALES = {
  ru: 'ru',
  uk: 'uk'
};


export const SNACKBAR_MESSAGES = {
  moderator: {
    create: {
      success: 'Модератор добавлен успешно'
    },
    edit: {
      success: ''
    },
    remove: {
      success: 'Модератор успешно удален',
      error: 'У Вас недостаточно прав для этой операции'
    }
  },
  manager: {
    edit: {
      success: 'Данные сохранены успешно'
    },
    create: {
      success: 'Менеджер добавлен успешно'
    },
    remove: {
      success: 'Менеджер успешно удален'
    }
  },
  status: {
    success: 'Статус успешно изменен'
  },
  bookingRequest: {
    create: {
      success: 'Заявка создана успешно'
    }
  },
  client: {
    add: {
      success: 'Клиент добавлен успешно'
    },
    edit: {
      success: 'Успешно сохранено'
    }
  },
  meetings: {
    create: {
      success: 'Встреча добавлен успешно'
    },
    edit: {
      success: 'Встреча изменена успешно'
    }
  },
  paydeskPayment: {
    payment: {
      success: 'Платеж успешно создан'
    },
    transfer: {
      success: 'Перевод успешно создан',
      cancel: 'Транзакция отменена успешно'
    }
  },
  booking: {
    create: {
      success: 'Заказ успешно создан'
    },
    debt: {
      create: {
        success: 'Долг успешно добавлен',
        repaid: 'Долго успешно погашен'
      }
    },
    deposit: {
      create: {
        success: 'Депозит успешно добавлен',
        off: 'Депозит успешно списан'
      }
    },
    rent: {
      create: {
        success: 'Платеж успешно добавлен'
      }
    },
    documents: {
      remove: {
        success: 'Файл успешно удален'
      },
      upload: {
        success: 'Файл успешно загружен'
      }
    }
  },
  invoices: {
    create: {
      success: 'Инвойс добавлен успешно'
    },
    remove: {
      success: 'Инвойс успешно удален'
    },
    edit: {
      success: 'Инвойс изменен успешно'
    }
  },
  utilities: {
    meters: {
      create: {
        success: 'Показания добавлены успешно'
      },
      read: {
        success: 'Показания успешно считаны'
      },
      send: {
        success: 'Показания успешно отправлены'
      },
      needWrite: 'Необходимо внести показания счетчиков'
    }
  },
  realty: {
    archived: {
      success: 'Объект успешно заархивирован'
    }
  },
  realtyGroups: {
    remove: {
      success: 'Група объектов удалена успешно'
    },
    create: {
      success: 'Група объектов добавлена успешно'
    },
    edit: {
      success: 'Група объектов обновлена успешно'
    },
  },
  permissions: {
    create: {
      success: 'Права доступа добавлены успешно'
    },
    remove: {
      success: 'Права успешно удалены',
      error: 'Ошибка'
    }
  },
  payments: {
    tochka: {
      balance: {
        update: {
          success: 'Баланс обновлен'
        }
      },
      payments: {
        download: {
          success: 'Платежи получены'
        }
      }
    }
  }
};


export const DEPOSIT_STATUS = {
  replenishment: 1,
  refund: 2,
  fine: 3
};

export const BALANCE_TYPE = {
  cash: 1,
  cashless: 2,
  banking: 3
};

export const BOOKING_PAYMENTS_TYPE = {
  rent: 1,
  utilities: 2
};

export const ALL_BOOKING_DOCS = [
  { type: 'type_1', title: 'Договор' },
  { type: 'type_2', title: 'Акт сдачи помещения' },
  { type: 'type_3', title: 'Акт возврата помещения' },
  { type: 'type_4', title: 'Шаблон договора' },
];

//remove type 3 if booking still open
export const ACTIVE_BOOKING_DOCS = [
  ...ALL_BOOKING_DOCS.filter((_, index) => index !== 2)
];

export const CLIENT_TYPE = {
  entity: 'ClientEntity',
  individual: 'ClientIndividual'
};

export const METERS_TYPE = {
  coldWater: 'Холодная вода',
  hotWater: 'Горячая вода',
  electricity: 'Электричество',
  gas: 'Газ'
};

export const REALTY_FEED_TYPE = {
  newBookingRequest: 1,
  clientWriteChat: 2,
  clientWriteMetersDataCorrectly: 3,
  clientWriteMetersDataSuspicious: 4,
  errorSendMetersDataToMainCompany: 5,
  clientStaysLongerPaidTime: 6,
  clientPassportExpires: 7,
  clientRequestProcessedByManager: 8,
  newBookingOrder: 9,
  orderEditByManager: 10,
  realtyCreated: 11,
  realtyEditedByManager: 12,
  realtySold: 13,
  realtySeoUpdate: 14,
  managerAddRealtyToAds: 15,
  realtyPricesUpdated: 16,
  realtyDepositMade: 17,
  realtyDepositRefund: 18,
  realtyRentPaid: 19,
  realtyCommunalPaid: 20,
  paymentCancel: 21,
  realtyAddDoc: 22,
  managerRemoveDoc: 23,
  realtyCadastralNumberChanges: 24,
  realtyAddRoom: 25,
  realtyRoomUpdated: 26,
  realtyRoomRemoved: 27,
  realtyAddedInventory: 28,
  realtyUpdatedInventory: 29,
  realtyRemovedInventory: 30,
  realtyAddManagementCompany: 31,
  realtyManagementCompanyUpdated: 32,
  realtyManagementCompanyRemoved: 33,
  counterAdded: 34,
  counterRemoved: 35,
  realtyImageUpload: 36,
  realtyImageRemoved: 37,
};

export const NOTIFICATION_TYPE = {
  newRequestByRealty: 1,
  needSupport: 2,
  clientEnterAlMeterDataCorrectly: 3,
  clientWriteMetersDataSuspicious: 4,
  errorSendMetersDataToMainCompany: 5,
  clientStaysLongerPaidTime: 6,
  clientPassportExpires: 7,
}
