import React from 'react';
import { Grid } from '@material-ui/core';
import SearchField from '../SearchField';
import FilterSelectField from '../FilterFields/FilterSelectField';
import FilterCheckboxField from '../FilterCheckboxField';
import { WITH_BOOKINGS } from '../SelectConstats';
import FilterLayout from '../FilterLayout';


const ClientsFilter = ({ ...props }) => {

  return (
    <FilterLayout>
      <Grid item>
        <SearchField searchField="name"
                     fulWidth
                     label="Поиск по имени"
                     placeholder="Введите имя"/>
      </Grid>
      <Grid item>
        <SearchField searchField="email"
                     fulWidth
                     label="Поиск по email"
                     placeholder="Введите email"/>
      </Grid>
      <Grid item>
        <SearchField searchField="phone"
                     fulWidth
                     label="Поиск по телефону"
                     placeholder="Введите номер телефона"/>
      </Grid>
      <Grid item>
        <FilterSelectField options={ WITH_BOOKINGS }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Заказ клиента"
                           type="bookings"/>
      </Grid>
      <Grid item>
        <FilterCheckboxField name="debt"
                             label="C долгами"/>
      </Grid>
    </FilterLayout>
  );
};

export default ClientsFilter;
