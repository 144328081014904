import React, { useEffect } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Loader from '../../components/Loader';
import { ColdIcon, ElectroIcon, GasIcon, HotIcon } from '../../icons';
import UtilitiesMetersDataRow from '../../components/Utilities/MetersData/UtilitiesMetersDataRow';
import UtilitiesMeterDataModal from '../../components/Utilities/MetersData/UtilitiesMeterDataModal';
import PageHeader from '../../components/PageHeader';
import { getCurrentAppVersion } from '../../components/Tools';
import { VER_UA } from '../../components/Constants';
import { useFetch, usePageTitle } from '../../hooks';
import { API_ROUTE } from '../../api/routes';
import { useTranslation } from 'react-i18next';


const UtilitiesMetersDataListPage = () => {
  const { setPageTitle } = usePageTitle();
  const {t} = useTranslation();
  const { data, loading, refetch: loadMetersData } = useFetch({
    url: API_ROUTE.utilitiesMeterData,
  });


  useEffect(() => {
    setPageTitle('Коммунальные показания');
  });


  if ( loading ) {
    return <Loader/>;
  }

  return (
    <React.Fragment>
      <Grid container
            spacing={ 2 }>

        <Grid item
              xs={ 12 }>
          <PageHeader title="Коммунальные показания"
                      size="md"/>
        </Grid>

        <Grid item
              xs={ 12 }>
          <Paper>
            <TableContainer style={ {
              maxWidth: '100%',
              overflow: 'auto'
            } }>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{ t('ID') }</TableCell>
                    <TableCell>{ t('Объект') }</TableCell>
                    <TableCell>{ t('Лог') }</TableCell>
                    <TableCell>
                      <HotIcon/>
                    </TableCell>
                    <TableCell>
                      <ColdIcon/>
                    </TableCell>
                    <TableCell>
                      <ElectroIcon/>
                    </TableCell>
                    { ( getCurrentAppVersion() === VER_UA ) &&
                      <TableCell>
                        <GasIcon/>
                      </TableCell>
                    }
                    <TableCell>{ t('Внести') }</TableCell>
                    <TableCell>{ t('Отправить показания в УК') }</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { data?.results && data?.results.map((meter) => (
                    <UtilitiesMetersDataRow key={ meter.realty_id }
                                            loadMetersData={ loadMetersData }
                                            meter={ meter }/>

                  )) }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <UtilitiesMeterDataModal loadMetersData={ loadMetersData }/>
    </React.Fragment>
  );
};

export default UtilitiesMetersDataListPage;
