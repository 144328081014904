import axios from 'axios';
import queryString from 'query-string';
import { API_ROUTE } from '../api/routes';

export const fetchModerators = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.moderators }?${ queryString.stringify(query) }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const fetchModerator = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`${ API_ROUTE.moderators }/${ id }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};


export const createModerator = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${ API_ROUTE.moderators }`, data)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const updateModerator = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`${ API_ROUTE.moderators }/${ id }`, data)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const deleteModerator = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${ API_ROUTE.moderators }/${ id }`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

