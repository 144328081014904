import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import Menu from './.././Menu';
import {
  closeMenuDrawer,
  fetchCountBookingRequest,
  fetchCountClientMessage,
  fetchCountSupport
} from '../../actions/drawerActions';
import { fetchUnreadMessageClientSupport, fetchUnreadMessageTechnicalSupport } from '../../actions/chatActions';
import { fetchBookingRequestCountNew } from '../../actions/bookingReqestsActions';
import { MANAGER, MODERATOR } from '../Constants';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ( {
  root: {
    display: 'flex',
  },
  drawer: {
    // [theme.breakpoints.up('xl')]: {
    //   width: drawerWidth,
    //   flexShrink: 0,
    // },
  },
  menuButton: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  toolbar: {
    minHeight: theme.mixins.toolbar.minHeight,
  },
  drawerPaper: {
    // paddingTop: theme.mixins.toolbar.minHeight + 16,
    width: drawerWidth,
    paddingTop: 0,

    // [theme.breakpoints.down('xl')]: {
    //   paddingTop: 0,
    // },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.mixins.toolbar.minHeight + 30,
  }
} ));


const MenuDrawer = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const drawer = useSelector(state => state.drawer);

  useEffect(() => {
    if ( drawer.isOpen ) {

      if ( user.userable_type === MANAGER ) {
        fetchUnreadMessageClientSupport()
          .then(response => {
            dispatch(fetchCountClientMessage(response.count));
          })
          .catch((error) => {
            console.error(error);
          })
      }

      if ( user.userable_type === MODERATOR ) {
        fetchUnreadMessageTechnicalSupport()
          .then(response => {
            dispatch(fetchCountSupport(response.count));
          })
          .catch((error) => {
            console.error(error);
          })
      }

      fetchBookingRequestCountNew()
        .then(response => {
          dispatch(fetchCountBookingRequest(response.total));
        })
        .catch((error) => {
          console.error(error);
        })

    }
  }, [user.userable_type, dispatch, drawer.isOpen]);

  const closeDrawer = () => {
    dispatch(closeMenuDrawer());
  };


  return (
    <nav className={ classes.drawer }>
      <MuiDrawer variant="temporary"
                 open={ drawer.isOpen }
                 onClose={ closeDrawer }
                 classes={ {
                   paper: classes.drawerPaper,
                 } }
                 ModalProps={ {
                   keepMounted: true,
                 } }>
        <Menu closeDrawer={ closeDrawer }/>
      </MuiDrawer>
    </nav>
  );
};


export default memo(MenuDrawer);
