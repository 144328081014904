import React from 'react';
import './StatisticIncome.scss';
import Number from '../Number';
import Title from '../Title';
import { getFormatNumber } from '../Tools';

const StatisticIncome = ({ income, yearly_profit, profit, outcome, title, orange, green, ...props }) => {
  return (
    <React.Fragment>
      <Title title={ title }/>
      <div className="statistic-income__wrap">
        { income
          ? <div className="statistic-income__piece">
            <Number size={ 'sm' }
                    value={ getFormatNumber(income) }
                    icon/>
            <Title size={ 'sm' }
                   title={ 'Касса' }/>
          </div>
          : null
        }

        { outcome
          ? <div className="statistic-income__piece">
            <Number size={ 'sm' }
                    value={ getFormatNumber(outcome) }
                    icon/>
            <Title size={ 'sm' }
                   title={ 'Расходы' }/>
          </div>
          : null
        }

        { profit
          ? <div className="statistic-income__piece">
            { yearly_profit > 0 &&
              <div className="statistic-income__sum">
                est. { yearly_profit } %
              </div>
            }

            <Number size={ 'sm' }
                    orange={ orange }
                    value={ getFormatNumber(profit) }
                    icon/>
            <Title size={ 'sm' }
                   title={ 'Доход' }/>

          </div>
          : null
        }
      </div>
    </React.Fragment>
  );
};

export default StatisticIncome;
