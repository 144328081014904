import React, { PureComponent } from 'react';
import { Button, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { readManagementCompanyDebt, sendManagementCompanyDebt } from '../../../actions/managementCompanyActions';
import { openMetersDataModal } from '../../../actions/formDrawerActions';
import { openSnackbar } from '../../../actions/snackbarActions';
import { connect } from 'react-redux';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { DATE_FORMAT, ERROR, SNACKBAR_MESSAGES, SUCCESS, TOOLTIP_RIGHT_START, VER_UA } from '../../Constants';
import { INVOICES_ROUTE } from '../../RouterConstants';
import { getCurrentAppVersion, uuidv4 } from '../../Tools';
import { withTranslation } from 'react-i18next';


class UtilitiesMetersDataRow extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  handleReadDebt = (id) => {
    this.setState({ loading: true });

    readManagementCompanyDebt(id)
      .then(() => {
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.utilities.meters.read.success);
        this.setState({ loading: false });
        this.props.loadMetersData();
      })
      .catch(error => {
        this.props.openSnackbar(ERROR, error);
        this.setState({ loading: false });
      });
  };

  handleSendDebt = (id) => {
    this.setState({ loading: true });
    sendManagementCompanyDebt(id)
      .then(() => {
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.utilities.meters.send.success);
        this.setState({ loading: false });
        this.props.loadMetersData();
      })
      .catch(error => {
        this.props.openSnackbar(ERROR, error);
        this.setState({ loading: false });
      });

  };


  handleOpenModal = (meterData) => {
    this.props.openMetersDataModal(meterData);
  };

  render() {
    const { meter, t } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        <TableRow key={ meter.realty_id }
                  hover>
          <TableCell>#{ meter.realty_id }</TableCell>
          <TableCell>
            <Link style={ { color: 'inherit', textDecoration: 'none' } }
                  to={ `/realty/${ meter.realty_id }` }>
              { meter.realty_name }
            </Link>
          </TableCell>
          <TableCell style={ { borderRight: '1px solid rgba(224, 224, 224, 1)' } }>
            <Link style={ { color: 'inherit' } }
                  to={ `${ INVOICES_ROUTE }?realty=${ meter.realty_id }` }>
              <InfoOutlinedIcon/>
            </Link>
          </TableCell>

          {/*//todo: need refactoring*/}
          { Object.keys(meter.utilities).length > 0
            ? <React.Fragment>
              <TableCell style={ { borderRight: '1px solid rgba(224, 224, 224, 1)' } }>
                { meter?.utilities?.hotWater?.filter(h => !h.archived_at).map(meter => (
                  <div key={ meter.meter_id }
                       style={ { padding: 6 } }>
                    <div>{ meter.value }</div>
                    { meter.created_at &&
                      <>
                        <small>{ t('Внесено') } - { meter.created_at }</small><br/>
                      </>
                    }

                    { meter.last_uploaded_at && (
                      <small>
                        { t('Обновлено УК') } - { moment(meter.last_uploaded_at).format(DATE_FORMAT.DD_MM_YYYY) }
                      </small>
                    )

                    }
                  </div>
                )) }
              </TableCell>
              <TableCell style={ { borderRight: '1px solid rgba(224, 224, 224, 1)' } }>
                { meter?.utilities?.coldWater?.filter(c => !c.archived_at).map(meter => (
                  <div key={ meter.meter_id }
                       style={ { padding: 6 } }>
                    <div>{ meter.value }</div>
                    { meter.created_at &&
                      <>
                        <small>{ t('Внесено') } - { meter.created_at }</small><br/>
                      </>
                    }
                    { meter.last_uploaded_at &&
                      <small>
                        { t('Обновлено УК') } - { moment(meter.last_uploaded_at).format(DATE_FORMAT.DD_MM_YYYY) }
                      </small>
                    }
                  </div>
                )) }
              </TableCell>
              <TableCell style={ { borderRight: '1px solid rgba(224, 224, 224, 1)' } }>
                { meter?.utilities?.electricity?.filter(e => !e.archived_at).map((meter) => (
                  <div key={ meter.meter_id }
                       style={ { padding: 6 } }>
                    <div>{ meter.value }</div>
                    { meter.created_at &&
                      <>
                        <small>{ t('Внесено') } - { meter.created_at }</small><br/>
                      </>
                    }
                    { meter.last_uploaded_at && (
                      <small>
                        { t('Обновлено УК') } - { moment(meter.last_uploaded_at).format(DATE_FORMAT.DD_MM_YYYY) }
                      </small>
                    ) }

                  </div>
                )) }
              </TableCell>

              { ( getCurrentAppVersion() === VER_UA ) &&
                <TableCell style={ { borderRight: '1px solid rgba(224, 224, 224, 1)' } }>
                  { meter?.utilities?.gas?.filter(e => !e.archived_at).map(meter => (
                    <div key={ meter.meter_id }
                         style={ { padding: 6 } }>
                      <div>{ meter.value }</div>
                      { meter.created_at &&
                        <>
                          <small>{ t('Внесено') } - { meter.created_at }</small><br/>
                        </>
                      }
                      { meter.last_uploaded_at && (
                        <small>
                          { t('Обновлено УК') } - { moment(meter.last_uploaded_at).format(DATE_FORMAT.DD_MM_YYYY) }
                        </small>
                      ) }

                    </div>
                  )) }
                </TableCell>
              }

            </React.Fragment>
            : <React.Fragment>
              <TableCell/>
              <TableCell/>
              <TableCell/>
              { ( getCurrentAppVersion() === VER_UA ) &&
                <TableCell></TableCell>
              }

            </React.Fragment>

          }
          <TableCell>
            { Object.keys(meter.utilities).length > 0
              ? <React.Fragment>
                <AddBoxOutlinedIcon style={ { cursor: 'pointer', verticalAlign: 'middle' } }
                                    onClick={ () => this.handleOpenModal(meter) }/>
              </React.Fragment>
              : <React.Fragment>
                { t('Нет счет-ов') }
              </React.Fragment>
            }
          </TableCell>

          <TableCell>
            { Object.keys(meter.utilities).length > 0 && meter.accounts.map((account, index) => (
              <React.Fragment key={ index }>
                <div style={ { border: 'none' } }>
                  { account.can_data_be_read &&
                    <Tooltip title={ t('Считать показания') }
                             arrow
                             placement={ TOOLTIP_RIGHT_START }>
                      <Button variant="outlined"
                              disabled={ loading }
                              style={ { marginBottom: 8 } }
                              onClick={ () => this.handleReadDebt(account.id) }>
                        <GetAppOutlinedIcon/>
                        &nbsp;
                        { account?.icons.length > 0 && account?.icons.map((icon, index) => (
                          <img src={ icon }
                               alt=""
                               key={ uuidv4() }
                               style={ { width: 10 } }/>
                        ))
                        }
                      </Button>
                    </Tooltip>
                  }


                  &nbsp;&nbsp;
                  { account.can_data_be_send &&
                    <Tooltip title={ t('Подать показания') }
                             arrow
                             placement={ TOOLTIP_RIGHT_START }>
                      <Button variant="outlined"
                              disabled={ loading }
                              style={ { marginBottom: 8 } }
                              onClick={ () => this.handleSendDebt(account.id) }>
                        <PublishOutlinedIcon/>
                        &nbsp;
                        { account?.icons.length > 0 && account?.icons.map((icon) => (
                          <img src={ icon }
                               key={ uuidv4() }
                               alt=""
                               style={ { width: 10 } }/>
                        ))
                        }
                      </Button>
                    </Tooltip>
                  }

                </div>
              </React.Fragment>
            )) }
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

let mapDispatchToProps = (dispatch) => ( {
  openMetersDataModal: (payload) => dispatch(openMetersDataModal(payload)),
  openSnackbar: (role, message) => dispatch(openSnackbar(role, message)),
} );

export default connect(null, mapDispatchToProps)(withTranslation()(UtilitiesMetersDataRow));


