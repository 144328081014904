import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './LanguageSwitch.scss';
import { LANGUAGES } from '../Constants';
import { getLocale } from '../Utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(i18n.language);

  const changeLanguage = (e, lng) => {
    i18n.changeLanguage(lng)
    moment.locale(getLocale(lng))

  };

  useEffect(() => {
    setActiveLang(i18n.language);
  }, [setActiveLang, i18n.language]);

  return (
    <ToggleButtonGroup value={ activeLang }
                       size={ 'small' }
                       color={ 'primary' }
                       sx={ { p: 1, } }
                       exclusive
                       onChange={ changeLanguage }>
      { LANGUAGES.map((locale) => (
        <ToggleButton value={ locale.value }
                      key={ locale.value }>
          { locale.name }
        </ToggleButton>

      )) }
    </ToggleButtonGroup>
  );
};

export default LanguageSwitch;
