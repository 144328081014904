import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import queryString from 'query-string';
import MuiPagination from '../Pagination';
import LoaderContainerProgressive from '../LoaderContainerProgressive';
import { useTranslation } from 'react-i18next';


const TableTemplate = ({
                         error,
                         loading,
                         data,
                         headers,
                         children,
                         meta,
                         headCellProps,
                         tableProps,
                         loaderProps,
                         headProps,
                         ...props
                       }) => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { t } = useTranslation();


  let handleSort = (key) => {
    const { sort, direction, page, ...query } = queryString.parse(location.search);

    let nextQuery = {
      ...query,
      sort: key,
    };

    if ( page ) {
      delete nextQuery.page;
    }

    if ( sort === key && direction === 'desc' ) {
      Object.assign(nextQuery, { direction: 'asc' });

      history.push({
        pathname: window.location.pathname,
        search: queryString.stringify(nextQuery)
      });

    } else {
      Object.assign(nextQuery, { direction: 'desc' });
      history.push({
        pathname: window.location.pathname,
        search: queryString.stringify(nextQuery)
      });
    }
  };

  return (
    <Grid container
          spacing={ 2 }>

      {/*<MuiPagination meta={ meta }*/ }
      {/*               wrapComponent={ (props) => <Grid item { ...props }/> }/>*/ }

      <LoaderContainerProgressive error={ error }
                                  loaderProps={ loaderProps }
                                  loading={ loading }
                                  data={ data }>
        <Grid item
              xs={ 12 }>
          <TableContainer component={ Paper }>
            <Table { ...tableProps }>
              { headers &&
                <TableHead>
                  <TableRow>
                    { headers.map((head) => {
                      if ( head.sort ) {
                        return (
                          <TableCell key={ head.label }>
                            <TableSortLabel onClick={ () => handleSort(head.name) }
                                            active={ params['sort'] === head.name }
                                            direction={ params['sort'] === head.name ? params['direction'] : 'asc' }
                                            { ...headCellProps }>
                              { t(head.label) }
                            </TableSortLabel>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={ head.label }
                                     style={ { ...head.style } }
                                     { ...head.cellProps }
                                     { ...headCellProps }>
                            { typeof head.label === 'string' ? t(head.label) : head.label }
                          </TableCell>
                        );
                      }
                    }) }
                  </TableRow>
                </TableHead>
              }

              <TableBody>
                { children }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </LoaderContainerProgressive>


      {meta?.last_page > 1 && (
        <MuiPagination meta={ meta }
                       wrapComponent={ (props) => <Grid xs={ 12 }
                                                        item
                                                        style={ {
                                                          paddingTop: `24px`,
                                                          display: `flex`,
                                                          justifyContent: 'center'
                                                        } }
                                                        { ...props }/> }/>
      ) }



    </Grid>
  );
};

export default TableTemplate;
