import React, { PureComponent } from 'react';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { createBookingRequest } from '../../actions/bookingReqestsActions';
import moment from 'moment';
import RequestStatusSelect from './RequestStatusSelect';
import TextField from '../TextField';
import PhoneInput from '../PhoneInput';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import SelectRentField from '../SelectRentField';
import PickDateField from '../PickDateField';
import RequestTypeSelect from './RequestTypeSelect';
import { withRouter } from 'react-router-dom';
import { REQUEST_RENT, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import CheckboxField from '../CheckboxField';
import { reformatPhoneNumber } from '../Tools';
import { withTranslation } from 'react-i18next';
import ButtonWithProgress from '../ButtonWithProgress';

const initialState = {
  request: {
    status: '',
    name: '',
    phone: '',
    realty: '',
    comment: '',
    rent_type: undefined,
    recall_time: undefined,
    notes: '',
    arrival_date: undefined,
    departure_date: undefined,
    request_type: '',
    create_client: false
  },
  loading: false,
  errors: new Errors()
};

class BookingRequestCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;

  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState(prevState => ( {
      request: {
        ...prevState.request,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));
  };

  handleRealtyChange = (e, value) => {
    this.setState(prevState => ( {
      request: {
        ...prevState.request,
        realty: value,
      }
    } ));
  };

  handleChangeDate = (key, value) => {

    this.setState(prevState => ( {
      request: {
        ...prevState.request,
        [key]: value
      }
    } ));
  };


  handleSubmit = (event) => {
    event.preventDefault();

    this.setState((prev) => ( { ...prev, loading: true } ));

    const { id, ...request } = this.state.request;

    const data = {
      status: request.status,
      name: request.name,
      phone: reformatPhoneNumber(request.phone),
      realty_id: request.realty?.id,
      comment: request.comment,
      rent_type: request.rent_type,
      recall_time: request.recall_time ? moment(request.recall_time, 'DD/MM/YYYY').unix() : undefined,
      notes: request.notes,
      arrival_date: request.arrival_date ? moment(request.arrival_date, 'DD/MM/YYYY').unix() : undefined,
      departure_date: request.departure_date ? moment(request.departure_date, 'DD/MM/YYYY').unix() : undefined,
      request_type: request.request_type,
      create_client: request.create_client
    };

    createBookingRequest(data)
      .then(() => {
        this.props.loadRequests();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.request.create);
        this.props.location.state = { open: false };
        this.setState(initialState);
      })
      .catch((errors) => {
          this.setState({
            errors: new Errors(errors)
          });
        }
      )
      .finally(() => {
        this.setState((prev) => ( { ...prev, loading: false } ));
      });
  };


  render() {
    const { request, errors } = this.state;

    return (
      <FormContainer>
        <FormRow>
          <FormColumn sm={ 6 }>
            <RequestStatusSelect value={ request.status }
                                 name="status"
                                 error={ errors.getError('status') }
                                 onChange={ this.handleChange }/>
          </FormColumn>
          <FormColumn sm={ 6 }>
            <TextField fullWidth
                       error={ errors.getError('name') }
                       name="name"
                       value={ request.name }
                       onChange={ this.handleChange }
                       label="Имя"
                       placeholder="Введите имя"
            />
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 6 }>
            <PhoneInput error={ errors.getError('phone') }
                        name="phone"
                        type="phone"
                        onChange={ this.handleChange }
                        value={ request.phone }/>
          </FormColumn>
          <FormColumn sm={ 6 }>
            <RequestTypeSelect value={ request.request_type }
                               name="request_type"
                               error={ errors.getError('request_type') }
                               onChange={ this.handleChange }/>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn sm={ 6 }>
            <RealtyAutocomplete value={ request.realty }
                                onChange={ this.handleRealtyChange }/>
          </FormColumn>
          <FormColumn sm={ 6 }>
            <TextField fullWidth
                       error={ errors.getError('notes') }
                       name="notes"
                       value={ request.notes }
                       onChange={ this.handleChange }
                       label="Заметка"
                       placeholder="Введите заметку"
            />
          </FormColumn>
        </FormRow>


        <FormRow>
          <FormColumn sm={ 6 }>
            <PickDateField name="recall_time"
                           type="recall_time"
                           error={ errors.getError('recall_time') }
                           value={ request.recall_time }
                           onChange={ this.handleChangeDate }
                           label="Время перезвона"/>
          </FormColumn>
          <FormColumn sm={ 6 }>
            <TextField fullWidth
                       error={ errors.getError('comment') }
                       name="comment"
                       value={ request.comment }
                       onChange={ this.handleChange }
                       label="Комментарий"
                       placeholder="Введите комментарий"
            />
          </FormColumn>
        </FormRow>


        { request.request_type === REQUEST_RENT
          ? <FormRow>
            <FormColumn sm={ 6 }>
              <PickDateField name="arrival_date"
                             type="arrival_date"
                             error={ errors.getError('arrival_date') }
                             value={ request.arrival_date }
                             onChange={ this.handleChangeDate }
                             label="Время въезда"/>
            </FormColumn>
            <FormColumn sm={ 6 }>
              <PickDateField name="departure_date"
                             type="departure_date"
                             error={ errors.getError('departure_date') }
                             value={ request.departure_date }
                             onChange={ this.handleChangeDate }
                             label="Время выезда"/>
            </FormColumn>
          </FormRow>

          : null
        }

        <FormRow>
          { request.request_type === REQUEST_RENT
            ? <FormColumn sm={ 6 }>
              <SelectRentField label="Тип аренды"
                               fullWidth
                               error={ errors.getError('rent_type') }
                               disabled={ !request.realty?.prices }
                               options={ request.realty?.prices }
                               name="rent_type"
                               handleChange={ this.handleChange }
                               value={ request.rent_type }/>
            </FormColumn>
            : null }

          <FormColumn sm={ 6 }>
            <CheckboxField style={ { width: '100%' } }
                           label={ `${ this.props.t('Создать клиента с логином') }
                             ${ reformatPhoneNumber(request.phone) }@rentabelno.com
                              ${ this.props.t('и паролем номером телефона') }` }
                           name="create_client"
                           handleChange={ this.handleChange }
                           value={ request.create_client }/>
          </FormColumn>
        </FormRow>


        <FormRow>
          <FormColumn xs="auto"
                      sm="auto"
                      style={ { marginLeft: 'auto' } }>
            <ButtonWithProgress
              loading={ this.state.loading }
              label={ 'Создать' }
              onClick={ this.handleSubmit }/>
          </FormColumn>
        </FormRow>
      </FormContainer>
    );
  }
}

export default connect(null, { openSnackbar })(withRouter(withTranslation()(BookingRequestCreateForm)));
