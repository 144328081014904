import React from 'react';
import MuiPagination from '@material-ui/lab/Pagination';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

const Pagination = ({ meta, wrapComponent: Wrapper, onChange = null, ...props }) => {
  const location = useLocation();
  const history = useHistory();

  const handlePageChange = (e, page) => {
    const search = queryString.parse(location.search);

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  if ( !meta ) return null;

  return (
    <Wrapper>
      <MuiPagination
        count={ Math.ceil(meta?.total / meta?.per_page) }
        color="primary"
        showFirstButton
        showLastButton
        page={ parseInt(meta?.current_page) }
        siblingCount={ 2 }
        onChange={ onChange ? onChange : handlePageChange }
        { ...props }
      />
    </Wrapper>

  );
};

export default Pagination;
