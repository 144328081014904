import axios from 'axios';

//tochka balance
export const fetchTochkaBalances = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/balance/tochka`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const tochkaBalanceUpdate = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/balance/tochka/update`, data)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};


//tochka payments


export const linkTochkaTransaction = (id) => {
  return new Promise((resolve, reject) => {
    axios.post(`/payments/tochka/${ id }/link`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};

export const updateTochkaPayments = () => {
  return new Promise((resolve, reject) => {
    axios.post(`/payments/tochka/update`)
      .then(response => {
        resolve(response.data.results);
      })
      .catch(error => {
        reject(error?.response?.data?.errors);
      });
  });
};
