import React, { memo, useEffect } from 'react';
import Autocomplete from './Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRealtyAutocomplete } from '../../actions/autocompleteActions';

const RealtyAutocomplete = ({ value, ...props }) => {
  const dispatch = useDispatch();
  const realty = useSelector(state => state.autocomplete.realty);

  useEffect(() => {
    dispatch(fetchRealtyAutocomplete());
  }, [dispatch]);

  if ( !realty ) {
    return null;
  }

  return (
    <React.Fragment>
      <Autocomplete{ ...props }
        // filterOptions={(options, state) => options}
                   value={ ( typeof value === 'string' ? realty.find(item => item.id.toString() === value) : value ) || null }
                   options={ realty }
                   label={ `Объект` }
                   placeholder={ `Выберите объект` }
      />
    </React.Fragment>

  );
};

export default memo(RealtyAutocomplete);


