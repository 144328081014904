import React, { useEffect, useState } from 'react';
import BalanceSelect from './BalanceSelect';
import { fetchBalances } from '../../actions/paydeskActions';

const PaydeskPaymentSelect = ({ ...props }) => {
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    fetchBalances()
      .then(balances => {
        setBalances(balances);
      });
  }, []);

  if ( !balances ) {
    return null;
  }

  return (
    <React.Fragment>
      <BalanceSelect { ...props }
                     options={ balances }/>
    </React.Fragment>
  );
};


export default PaydeskPaymentSelect;
