import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelectField from '../FilterFields/FilterSelectField';
import { withRouter } from 'react-router-dom';
import SearchField from '../SearchField';
import { ACTIVE_SELECT_OPTIONS, RENT_TYPE_OPTIONS } from '../SelectConstats';
import FilterLayout from '../FilterLayout';


const BookingFilter = ({ ...props }) => {

  return (
    <FilterLayout>
      <Grid item>
        <SearchField searchField="realty"
                     placeholder="Введите имя объекта"
                     label="Поиск по объекту"/>
      </Grid>
      <Grid item>
        <SearchField searchField="rental_agreement"
                     placeholder="Введите название договора"
                     label="Поиск по договору"/>
      </Grid>
      <Grid item>
        <FilterSelectField options={ RENT_TYPE_OPTIONS }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Тип съема"
                           type="rent_type"/>
      </Grid>
      <Grid item>
        <SearchField searchField="client"
                     placeholder="Имя / телефон / email"
                     label="Клиент"/>
      </Grid>
      <Grid item>
        <FilterSelectField options={ ACTIVE_SELECT_OPTIONS }
                           fullWidth
                           style={ { minWidth: 220 } }
                           label="Активность"
                           type="status"/>
      </Grid>

    </FilterLayout>
  );
};

export default withRouter(BookingFilter);
