import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { createPayment } from '../../actions/paydeskActions';
import FormColumn from '../FormColumn';
import Errors from '../Errors';
import SelectField from '../SelectField';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import TextField from '../TextField';
import { withRouter } from 'react-router-dom';
import PaydeskPaymentSelect from './PaydeskBalanceSelect';
import { SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';
import { INCOME_CATEGORY, INCOME_TYPE, OUTCOME_CATEGORY } from '../SelectConstats';


const initialState = {
  payment: {
    company: '',
    balance: '',
    amount: '',
    income: '',
    action: '',
    realty: '',
    comment: ''
  },
  loading: false,
  errors: new Errors()
};

class PaydeskPaymentCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }


  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      payment: {
        ...prevState.payment,
        [name]: type === 'checkbox' ? checked : value
      }
    } ));
  };


  handleChangeRealty = (e, value) => {
    this.setState(prevState => ( {
      payment: {
        ...prevState.payment,
        realty: value
      }
    } ));
  };


  handleSubmit = () => {
    const { payment } = this.state;

    this.setState({ loading: true });

    const data = {
      company_id: payment.balance.company_id,
      balance_id: payment.balance.id,
      amount: payment.amount,
      income: payment.income,
      action: payment.action,
      realty_id: payment.realty?.id,
      comment: payment.comment
    };

    createPayment(data)
      .then(() => {
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.paydeskPayment.payment.success);
        this.setState(initialState);
        this.props.loadPayments();
        this.props.loadBalances();
        this.props.location.state = { open: false };
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { payment, errors } = this.state;

    return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 6 }>
              <PaydeskPaymentSelect handleChange={ this.handleChange }
                                    name="balance"
                                    label="Баланс"
                                    fullWidth
                                    error={ errors.getError('balance_id') }
                                    value={ payment.balance }/>
            </FormColumn>
            <FormColumn sm={ 6 }>
              <SelectField options={ INCOME_TYPE }
                           error={ errors.getError('income') }
                           style={ { minWidth: 220 } }
                           fullWidth
                           onChange={ this.handleChange }
                           name="income"
                           value={ payment.income }
                           label="Приход или расход"/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }>
              <RealtyAutocomplete value={ payment.realty }
                                  onChange={ this.handleChangeRealty }/>
            </FormColumn>
            <FormColumn sm={ 6 }>
              <SelectField fullWidth
                           name="action"
                           error={ errors.getError('action') }
                           disabled={ !payment.income }
                           label="Категория платежа"
                           value={ payment.action }
                           options={ payment.income > 0 ? INCOME_CATEGORY : OUTCOME_CATEGORY }
                           onChange={ this.handleChange }/>
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn sm={ 12 }>
              <TextField
                multiline
                rows={ 3 }
                error={ errors.getError('comment') }
                name="comment"
                value={ payment.comment }
                onChange={ this.handleChange }
                label="Комментарий"
                placeholder="Введите комментарий"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField
                error={ errors.getError('amount') }
                name="amount"
                value={ payment.amount }
                onChange={ this.handleChange }
                label="Сумма"
                placeholder="Введите сумму"
              />
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn xs="auto" sm="auto" style={ { marginLeft: 'auto' } }>
              <ButtonWithProgress
                label={ 'Добавить' }
                disabled={ this.state.loading }
                onClick={ this.handleSubmit }/>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>

    );
  }
}

export default connect(null, { openSnackbar })(withRouter(PaydeskPaymentCreateForm));
