import React, { memo } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { reformatPhoneNumber } from '../Tools';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ( {
  root: {
    cursor: 'pointer',
    marginBottom: 0
  },
} ));

const SuggestPasswordNotification = ({ phone, onClose, onClick, ...props }) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Alert severity="info"
           onClose={ onClose }>
      <AlertTitle onClick={ () => onClick(reformatPhoneNumber(phone)) }
                  className={ classes.root }>
        { t('Использовать сгенерированный пароль') }<br/>
        { reformatPhoneNumber(phone) }
      </AlertTitle>
    </Alert>
  );
};

export default memo(SuggestPasswordNotification);
