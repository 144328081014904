import React from 'react';
import './CheckboxField.scss';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';


const useStyles = makeStyles({
  root: {
    width: '50%',
    marginRight: 0
  },
});

const CheckboxField = ({ label, value, icon = false, style = {}, checked, handleChange, name, ...props }) => {
  const classes = useStyles();

  let customLabel = (icon, label) => {
    return (
      <div className="custom-label">
        { icon &&
          <div className={ `custom-label__icon ${ icon }` }/>
        }
        <div className="custom-label__label">{ label }</div>
      </div>
    );
  };

  return (
    <FormControlLabel
      className={ classes.root }
      style={ style }
      control={
        <Checkbox
          name={ name }
          checked={ checked }
          onChange={ handleChange }
          value={ value }
          color="primary"
        />
      }
      label={ customLabel(icon, label) }
    />
  );
};

export default CheckboxField;
