import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import { setPageTitle } from '../../components/Tools';
import { NEW_MEETING_ROUTE } from '../../components/RouterConstants';
import SearchField from '../../components/SearchField';
import MeetingsTableRow from '../../components/Meetings/MeetingsTableRow';
import MeetingsFilter from '../../components/Meetings/MeetingsFilter';
import MeetingStatusFilterSelectField from '../../components/Meetings/MeetingStatusFilterSelectField';
import { LABEL_WIDTH_SM } from '../../components/Constants';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../../components/TableTemplate';
import { MEETINGS_HEADERS } from '../../components/TableConstants';
import { useFetch } from '../../hooks';
import { API_ROUTE } from '../../api/routes';

const MeetingsListPage = ({ ...props }) => {
  const { t } = useTranslation();
  const { data: meetings, loading, meta } = useFetch({
    url: API_ROUTE.meetings,
    hasMeta: true,
    oldResponseFormat: true
  });

  useEffect(() => {
    setPageTitle('Встречи', t);
  });


  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Встречи"
                        size="md"
                        hasBottom
                        addButton={ NEW_MEETING_ROUTE }
                        sort={ <MeetingStatusFilterSelectField/> }
                        search={ <SearchField searchField="client"
                                              label="Поиск по имени"
                                              labelWidth={ LABEL_WIDTH_SM }
                                              placeholder="Введите имя клиента"/> }
                        filter={ <MeetingsFilter/> }
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <TableTemplate data={ meetings }
                       meta={ meta }
                       loading={ loading }
                       headers={ MEETINGS_HEADERS }>
          { meetings && meetings.map(meeting => <MeetingsTableRow key={ meeting.id }
                                                                  meeting={ meeting }/>) }
        </TableTemplate>
      </Grid>

    </Grid>
  );
};

export default MeetingsListPage;
