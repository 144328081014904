import React, {PureComponent} from 'react';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import FormColumn from '../FormColumn';
import FormRow from '../FormRow';
import {fetchBookingRequest, updateBookingRequest} from '../../actions/bookingReqestsActions';
import moment from 'moment';
import {Divider} from '@material-ui/core';
import RequestStatusSelect from './RequestStatusSelect';
import TextField from '../TextField';
import PhoneInput from '../PhoneInput';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import PickDateField from '../PickDateField';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import { DATE_FORMAT, MODERATOR, SUCCESS } from '../Constants';
import ButtonWithProgress from '../ButtonWithProgress';
import BookingRequestRentTypeField from './BookingRequestRentTypeField';
import ClientCreateModal from '../Client/ClientCreateModal';
import {openCreateClientModal} from '../../actions/formDrawerActions';
import {reformatPhoneNumber} from '../Tools';


class BookingRequestEditForm extends PureComponent {
  constructor (props) {
    super(props);


    this.state = {
      request: {
        id: this.props.id,
        status: '',
        name: '',
        phone: '',
        realty: '',
        comment: '',
        rent_type: '',
        recall_time: '',
        notes: '',
        arrival_date: '',
        departure_date: '',
        text_request_type: ''
      },
      loading: false,
      errors: new Errors()
    };
  }

  componentDidMount () {
    const {request: {id}} = this.state;

    fetchBookingRequest(id).then(request => {
      this.setRequest(request);
    });

  }

  setRequest = ({
                  id, status, name, phone, realty, comment, rent_type,
                  text_request_type, recall_time, notes, arrival_date, departure_date
                }) => {
    this.setState(prevState => ({
      request: {
        ...prevState.request,
        id: id,
        status: status,
        name: name,
        phone: phone,
        realty: realty,
        comment: comment,
        rent_type: rent_type,
        recall_time: recall_time ? moment(recall_time, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY') : null,
        notes: notes,
        arrival_date: arrival_date
            ? moment(arrival_date, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY')
            : null,
        departure_date: departure_date
            ? moment(departure_date, DATE_FORMAT.DD_MM_YYYY).format('DD/MM/YYYY')
            : null,
        text_request_type: text_request_type
      },
    }));
  };

  handleCreateClient = () => {
    let clientData = {
      name: this.state.request.name,
      phone: this.state.request.phone
    };

    this.props.openCreateClientModal(clientData);
  };

  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      request: {
        ...prevState.request,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleRealtyChange = (e, value) => {
    this.setState(prevState => ({
      request: {
        ...prevState.request,
        realty: value,
      }
    }));
  };

  handleChangeDate = (key, value) => {

    this.setState(prevState => ({
      request: {
        ...prevState.request,
        [key]: value
      }
    }));
  };


  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({loading: true});

    const {id, ...request} = this.state.request;

    const data = {
      status: request.status,
      name: request.name,
      phone: reformatPhoneNumber(request.phone),
      realty_id: request.realty?.id,
      comment: request.comment,
      rent_type: request.rent_type,
      recall_time: moment(request.recall_time, 'DD/MM/YYYY').unix(),
      notes: request.notes,
      arrival_date: moment(request.arrival_date, 'DD/MM/YYYY').unix(),
      departure_date: moment(request.departure_date, 'DD/MM/YYYY').unix(),
    };

    updateBookingRequest(id, data)
        .then(request => {
          this.setRequest(request);
          this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
          this.setState({
            loading: false,
            errors: new Errors({})
          });
        })
        .catch(errors =>
            this.setState({
              loading: false,
              errors: new Errors(errors)
            })
        );
  };


  render () {
    const {request, errors, loading} = this.state;
    const {user} = this.props;

    return (
        <div style={ {paddingLeft: 24, paddingRight: 24} }>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <RequestStatusSelect value={ request.status }
                                     name="status"
                                     error={ errors.getError('status') }
                                     onChange={ this.handleChange }/>
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <TextField fullWidth
                           error={ errors.getError('text_request_type') }
                           name="text_request_type"
                           value={ request.text_request_type }
                           disabled
                           label="Тип заявки"
                           placeholder="Введите тип"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <TextField fullWidth
                           error={ errors.getError('name') }
                           name="name"
                           value={ request.name }
                           onChange={ this.handleChange }
                           label="Имя"
                           placeholder="Введите имя"/>
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <PhoneInput error={ errors.getError('phone') }
                            name="phone"
                            type="phone"
                            onChange={ this.handleChange }
                            value={ request.phone }
                            label="Телефон"
                            placeholder="Введите телефон"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 8 }
                          md={ 6 }
                          lg={ 4 }>
                <TextField fullWidth
                           error={ errors.getError('comment') }
                           name="comment"
                           value={ request.comment }
                           onChange={ this.handleChange }
                           label="Комментарий"
                           placeholder="Введите комментарий"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <RealtyAutocomplete value={ request.realty }
                                    onChange={ this.handleRealtyChange }/>
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <BookingRequestRentTypeField errors={ errors }
                                             fullWidth
                                             value={ request.rent_type }
                                             handleChange={ this.handleChange }/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <PickDateField name="recall_time"
                               type="recall_time"
                               error={ errors.getError('recall_time') }
                               value={ request.recall_time }
                    // defaultDateFrom={moment().startOf('day')}
                               onChange={ this.handleChangeDate }
                               label="Время перезвона"/>
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <TextField fullWidth
                           error={ errors.getError('notes') }
                           name="notes"
                           value={ request.notes }
                           onChange={ this.handleChange }
                           label="Заметка"
                           placeholder="Введите заметку"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <PickDateField name="arrival_date"
                               type="arrival_date"
                               error={ errors.getError('arrival_date') }
                               value={ request.arrival_date }
                    // defaultDateFrom={moment().startOf('day')}
                               onChange={ this.handleChangeDate }
                               label="Время въезда"/>
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          lg={ 2 }>
                <PickDateField name="departure_date"
                               type="departure_date"
                               error={ errors.getError('departure_date') }
                               value={ request.departure_date }
                    // defaultDateFrom={moment().add(1, 'days').startOf('day')}
                               onChange={ this.handleChangeDate }
                               label="Время выезда"/>
              </FormColumn>
            </FormRow>

            { user.userable_type !== MODERATOR &&
            <FormRow>
              <FormColumn xs={ 12 }
                          sm={ 12 }
                          style={ {textAlign: 'right'} }>
                <Divider style={ {marginBottom: 16} }/>

                <ButtonWithProgress onClick={ this.handleCreateClient }
                                    label="Создать клиента с заявки"/>

                <ButtonWithProgress onClick={ this.handleSubmit }
                                    style={ {marginLeft: 16} }
                                    loading={ loading }
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
            }
          </FormContainer>

          <ClientCreateModal/>
        </div>
    );
  }
}

let mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {openSnackbar, openCreateClientModal})(BookingRequestEditForm);
