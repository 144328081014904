import axios from 'axios';
import queryString from 'query-string';
import * as actionTypes from './actionTypes';

export const fetchStatisticGroups = (params) => {
  const query = queryString.stringify(params);

  return new Promise((resolve, reject) => {
    axios.get(`/statistics/groups?${ query }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch((error) => {
          console.log(error);
          // reject(error?.response?.data?.errors);
        });
  });

};

export const fetchStatisticCompany = (params) => dispatch => {
  const query = queryString.stringify(params);

  return new Promise((resolve, reject) => {
    axios.get(`/statistics/companies?${ query }`)
        .then(response => {
          dispatch({
            type: actionTypes.GET_STATISTIC_COMPANY,
            payload: response.data.results[0]
          });
          // resolve(response.data.results)
        })
        .catch((error) => {
          console.error(error);
          // reject(error?.response?.data?.errors);
        });
  });

};


export const fetchStatisticCompanyApartments = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`/statistics/realty?${ queryString.stringify(params) }`)
        .then((response) => {
          resolve(response.data.results);
        })
        .catch((error) => {
          console.error(error);
          // reject(error?.response?.data?.errors);
        });
  });
};
