import React from 'react';
import './AddingButton.scss';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TOOLTIP_RIGHT_START } from '../Constants';
import { useTranslation } from 'react-i18next';

const AddingButton = ({
                        className = '', icon: Icon = null, link = false, url,
                        disabled = false,
                        position = '', tooltip = '', ...props
                      }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip title={ t(tooltip) }
               arrow
               placement={ TOOLTIP_RIGHT_START }>
        { link ? <Link to={ url }>
            <Icon/>
          </Link>
          : <button className={ `adding-button ${ className }
                          ${ position ? `adding-button--${ position }` : '' }
                          ${ disabled && 'adding-button--disabled' }` }
                    { ...props }>
            <Icon/>
          </button>
        }

      </Tooltip>

    </React.Fragment>
  );
};

export default AddingButton;
