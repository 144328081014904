import MuiPaper from '../MuiPaper';
import { Box, Typography } from '@material-ui/core';
import NoResultsIcon from '../../icons/NoResultsIcon';
import { useTranslation } from 'react-i18next';

const NoResultsMessage = ({ message = 'Результатов не найдено', hasIcon = true, color }) => {
  const { t } = useTranslation();

  return (
    <MuiPaper style={ { textAlign: 'center', width: '100%', backgroundColor: color, padding: 16 } }>
      <Box display={ 'flex' }
           style={ { gap: 16 } }
           alignItems={'center'}
           flexDirection={ 'column' }>
        { hasIcon
          ? <Box>
            <NoResultsIcon/>
          </Box>
          : null }

        <Typography variant={ 'subtitle1' }>
          { t(message) }
        </Typography>
      </Box>
    </MuiPaper>
  );
};

export default NoResultsMessage;
