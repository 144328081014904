import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const SelectField = ({ label, handleChange, value, options, name, error, disabled, fullWidth, ...props }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormControl variant="outlined"
                   fullWidth={ fullWidth }
                   error={ !!error }>
        <InputLabel id={ label }>
          { t(label) }
        </InputLabel>
        <Select name={ name }
                disabled={ disabled }
                labelId={ label }
                value={ value }
                onChange={ handleChange }
                label={ label }>
          { options && options.map((option, index) => (
            <MenuItem key={ index }
                      value={ option.rent_type }>{ t(option.text_rent_type) }</MenuItem>
          )) }

        </Select>
        { error && <FormHelperText>{ error }</FormHelperText> }
      </FormControl>
    </React.Fragment>
  );
};

export default SelectField;
