import React from 'react';
import SelectField from '../SelectField';
import { NOTIFICATION_TYPES_OPTIONS } from '../SelectConstats';


const NotificationsTypeSelect = ({ value, ...props }) => {
  return (
    <SelectField options={ NOTIFICATION_TYPES_OPTIONS }
                 { ...props }
                 fullWidth
                 value={ value }
                 placehplder="Выберите тип"
                 label="Тип уведомления"/>
  );
};

export default NotificationsTypeSelect;
