import React from 'react';
import { useTranslation } from 'react-i18next';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import { ArchiveIcon, CalendarIcon, CashBoxIcon } from '../../icons';
import AddingButton from '../AddingButton';
import { archiveRealty } from '../../actions/realtyActions';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import DialogsRemove from '../DialogsRemove';
import { uuidv4 } from '../Tools';
import { BOOKINGS_ROUTE, PAYMENTS_ROUTE, REALTY_LIST_ROUTE } from '../RouterConstants';


const RealtyNavigationPart = ({ id, name, loadRealty, is_deleted, ...props }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  let handleArchive = (_, id) => {
    archiveRealty(id)
      .then(() => {
        dispatch(openSnackbar(SUCCESS, SNACKBAR_MESSAGES.realty.archived.success));
        loadRealty();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="realty-navigation">
      <div className="realty-navigation__link">
        <AddingButton link={ true }
                      url={ `${ REALTY_LIST_ROUTE }/${ id }/feed` }
                      icon={ () => <FormatListBulletedOutlinedIcon style={ { width: 20, height: 20 } }/> }
                      tooltip="Лента объекта"/>
      </div>
      {/*<div className="realty-navigation__link">*/ }
      {/*  <AddingButton link={true}*/ }
      {/*                url={`/communal/invoices?realty_id=${id}`}*/ }
      {/*                icon={() => <SettingsIcon/>}*/ }
      {/*                tooltip="Коммуналъные инвойсы"/>*/ }
      {/*</div>*/ }
      <div className="realty-navigation__link">
        <AddingButton link
                      url={ `${ BOOKINGS_ROUTE }?realty=${ name }&direction=desc&sort=arrival_date` }
                      icon={ () => <CalendarIcon/> }
                      tooltip="Заказы объекта"/>
      </div>
      <div className="realty-navigation__link">
        <AddingButton link
                      url={ `${ PAYMENTS_ROUTE }?realty_id=${ id }` }
                      icon={ () => <CashBoxIcon/> }
                      tooltip="Платежи по объекту"/>
      </div>
      { !is_deleted &&
        <div className="realty-navigation__link">
          <DialogsRemove icon={ () => <ArchiveIcon/> }
                         remover={ handleArchive }
                         negative_answer="Нет"
                         positive_answer="Да"
                         question="Объект будет заблокирован."
                         question_text={ [t('Объект уже не будет использован в системе?'),
                           <br key={ uuidv4() }/>
                           , t('(Это действие необратимо)')] }
                         tooltip={ `Архивировать объект` }
                         id={ id }/>

        </div>
      }

    </div>
  );
};

export default RealtyNavigationPart;
