import React, { PureComponent } from 'react';
import PageHeader from '../../components/PageHeader';
import { fetchAllRealty } from '../../actions/realtyActions';
import queryString from 'query-string';
import { Grid } from '@material-ui/core';
import Pagination from '../../components/Pagination';
import RealtyListItem from '../../components/Realty/RealtyListItem';
import SearchField from '../../components/SearchField';
import RealtyFilter from '../../components/Realty/RealtyFilter';
import { setPageTitle } from '../../components/Tools';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import { NOT_FOUND } from '../../components/Constants';
import { REALTY_TYPES } from '../../components/SelectConstats';
import SelectField from '../../components/SelectField';
import { NEW_REALTY_ROUTE } from '../../components/RouterConstants';
import Loader from '../../components/Loader';
import { withTranslation } from 'react-i18next';


class RealtyListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 25,
      meta: null,
      realty: [],
      loading: false
    };
  }

  componentDidMount = () => {
    setPageTitle('Объекты', this.props.t);
    this.loadRealty();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadRealty();
    }
  }

  loadRealty = () => {
    const { perPage } = this.state;
    const query = queryString.parse(this.props.location.search);

    this.setState((prev) => ( { ...prev, loading: true } ));

    fetchAllRealty({ ...query, limit: perPage })
      .then((response) => {
        this.setState({
          realty: response.data,
          meta: response.meta
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState((prev) => ( { ...prev, loading: false } ));
      });
  };


  handleChange = (e) => {
    const { name, value } = e.target;
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: 1,
        [name]: value,
      }),
    });

  };


  getParam = (key) => {
    const search = queryString.parse(this.props.location.search);

    return search[key] || '';
  };

  render() {
    const { realty, meta, loading } = this.state;
    const { t } = this.props;

    if ( loading ) return <Loader/>;

    return (
      <Grid container
            spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <PageHeader title={ `${ t('Объекты') } [${ meta?.total ?? 0 }]` }
                      size="md"
                      hasBottom
                      addButton={ NEW_REALTY_ROUTE }
                      sort={
                        <SelectField label={ 'Типы объектов' }
                                     options={ REALTY_TYPES }
                                     style={ { minWidth: 220 } }
                                     onChange={ this.handleChange }
                                     value={ this.getParam('type') }
                                     name="type"
                                     fullWidth/>
                      }
                      filter={ <RealtyFilter/> }
                      search={ <SearchField searchField="name"
                                            label="Поиск"
                                            labelWidth={ 40 }
                                            placeholder="Введите имя"/> }
          />
        </Grid>

        { realty.length &&
          <Grid item xs={ 12 }>
            { realty.map(realty => <RealtyListItem key={ realty.id }
                                                   loadRealty={ this.loadRealty }
                                                   { ...realty }/>) }
          </Grid>
        }

        { realty.length === 0 && <ErrorLoadingDataMessage title={ NOT_FOUND }/> }


        { ( meta && meta.last_page > 1 ) &&
          <Pagination meta={ meta }
                      wrapComponent={ (props) => <Grid item xs={ 12 }
                                                       style={ {
                                                         paddingTop: `24px`,
                                                         display: `flex`,
                                                         justifyContent: 'center'
                                                       } }
                                                       { ...props }/>
                      }/>
        }


      </Grid>
    );
  }
}

export default withTranslation()(RealtyListPage);
