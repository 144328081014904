import React, { PureComponent } from 'react';
import Errors from '../Errors';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Title from '../Title';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import { translateErrors } from '../Tools';
import AddingButton from '../AddingButton';
import { PlusIcon } from '../../icons';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import BookingDocumentsRow from './BookingDocumentsRow';
import { fetchBookingDocuments, removeBookingDocument, uploadBookingDocument } from '../../actions/bookingsActions';
import BookingOtherDocumentsRow from './BookingOtherDocumentsRow';
import ProgressBar from '../ProgressBar';
import {
  ACTIVE_BOOKING_DOCS,
  ALL_BOOKING_DOCS,
  BOOKING_STATUS,
  ERROR,
  ONE_HUNDRED_PERCENT,
  SNACKBAR_MESSAGES,
  SUCCESS
} from '../Constants';
import { withTranslation } from 'react-i18next';


class BookingDocumentsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: this.props.booking.id,
        name: this.props.booking.rental_agreement,
        otherDocuments: null,
        mainDocuments: null,
      },
      errors: new Errors(),
      percentLoading: null,
    };
  }


  componentDidMount() {
    this.loadDocuments();
  }


  loadDocuments = () => {
    const { booking: { id } } = this.state;

    fetchBookingDocuments(id)
      .then(documents => {
        this.transformDocuments(documents);
      })
      .catch((error) => {
        console.error(error);
      });

  };


  transformDocuments = (documents) => {
    let otherDocuments = documents.other;
    delete documents.other;

    let mainDocuments = [];

    for ( const i in documents ) {
      mainDocuments.push(...documents[i]);
    }

    this.setState(prevState => ( {
      booking: {
        ...prevState.booking,
        otherDocuments: otherDocuments,
        mainDocuments: mainDocuments
      }
    } ));

  };

  handlerErrorUpload = (errors) => {
    this.props.openSnackbar(ERROR, translateErrors(errors));
  };


  handleRemoveDocument = (_, docId) => {
    const { booking: { id } } = this.props;

    removeBookingDocument(id, docId)
      .then(() => {
        this.loadDocuments();
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.documents.remove.success);
      });
  };

  handleUploadFile = (file, type) => {
    const { booking: { id } } = this.props;
    const data = new FormData();

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(( progressEvent.loaded * ONE_HUNDRED_PERCENT ) / progressEvent.total);


        if ( percentCompleted < ONE_HUNDRED_PERCENT ) {
          this.setState({ percentLoading: percentCompleted });
        } else {
          this.setState({ percentLoading: null });
        }
      }
    };

    if ( type ) {
      data.append('type', type.replace('type_', ''));
    }

    data.append('file', file[0].src.file);

    uploadBookingDocument(id, data, config)
      .then(() => {
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.booking.documents.upload.success);
        this.loadDocuments();
      })
      .catch((errors) => {
        this.props.openSnackbar(ERROR, errors?.file || errors);
      });
  };


  render() {
    const { booking, percentLoading } = this.state;
    const { booking: { status }, t } = this.props;
    let documents;

    if ( status === BOOKING_STATUS.close ) {
      documents = ALL_BOOKING_DOCS;
    } else {
      documents = ACTIVE_BOOKING_DOCS;
    }


    return (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <Title title="Основные"
                 size="xl"/>
        </Grid>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Paper>
                <TableContainer style={ {
                  maxWidth: '100%',
                  overflow: 'auto'
                } }>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{ t('Тип') }</TableCell>
                        <TableCell>{ t('Файл') }</TableCell>
                        <TableCell>{ t('Кем загружен') }</TableCell>
                        <TableCell>{ t('Дата загрузки') }</TableCell>
                        <TableCell>{ t('Действия') }</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { documents.map((document) => (
                        <BookingDocumentsRow key={ document.type }
                                             booking_id={ booking.id }
                                             booking_name={ booking.name }
                                             loadDocuments={ this.loadDocuments }
                                             handlerErrorUpload={ this.handlerErrorUpload }
                                             handleRemoveDocument={ this.handleRemoveDocument }
                                             document={ booking.mainDocuments?.filter(x => x.type === document.type) }
                                             upload={ booking.mainDocuments?.map(x => x.type).includes(document.type) }
                                             { ...document }/>
                      )) }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Grid>


        <Grid item
              xs={ 12 }
              sm={ 4 }
              style={ {
                display: 'flex',
                alignItems: 'center'
              } }>
          <Title title="Дополнительные документы"
                 style={ {
                   marginBottom: 0,
                   marginRight: 16
                 } }
                 size="xl"/>
          <UploadFilesButton handleChange={ (file) => this.handleUploadFile(file) }
                             handleError={ this.handlerErrorUpload }
                             component={ (props) => <AddingButton { ...props }
                                                                  tooltip="Загрузить дополнительные документы"
                                                                  icon={ () => <PlusIcon/> }/> }/>
          { ( percentLoading && percentLoading < ONE_HUNDRED_PERCENT ) && (
            <ProgressBar percent={ percentLoading }
                         style={ {
                           minWidth: '75px',
                           marginLeft: 16
                         } }/>
          ) }
        </Grid>
        <Grid item xs={ 12 }>
          <Paper>
            <TableContainer style={ {
              maxWidth: '100%',
              overflow: 'auto'
            } }>
              <Table>
                <TableBody>
                  { booking.otherDocuments && booking.otherDocuments.map(document => (
                    <BookingOtherDocumentsRow key={ document.id }
                                              booking_name={ booking.name }
                                              booking_id={ booking.id }
                                              handleRemoveDocument={ this.handleRemoveDocument }
                                              document={ document }/>

                  )) }

                  { !booking.otherDocuments &&
                    <React.Fragment>
                      <TableRow>
                        <TableCell>
                          <div style={ { fontSize: 15, color: '#7D9AA5', textAlign: 'center' } }>
                            { t('Нет документов') }
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

export default connect(null, { openSnackbar })
(withTranslation()(BookingDocumentsForm));
