import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import { connect } from 'react-redux';
import { Button, Table, TableBody, TableCell, TableRow, withStyles } from '@material-ui/core';
import { openSnackbar } from '../../actions/snackbarActions';
import Errors from '../Errors';
import { cancelPayment } from '../../actions/paydeskActions';
import { ERROR, SNACKBAR_MESSAGES, SUCCESS } from '../Constants';
import { withTranslation } from 'react-i18next';
import ButtonWithProgress from '../ButtonWithProgress';


const styles = theme => ( {
  title: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 16,
  },
} );

const initialState = {
  errors: new Errors()
};

class PaydeskPaymetCancelForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }


  handleRemove = () => {
    const { payment, closeModal } = this.props;

    cancelPayment(payment.id)
      .then(res => {
        this.props.openSnackbar(SUCCESS, SNACKBAR_MESSAGES.paydeskPayment.transfer.cancel);
        this.setState(initialState);
        closeModal();
        this.props.loadPayments();
      })
      .catch(errors => {
        this.props.openSnackbar(ERROR, errors);
      });

  };


  render() {
    const { classes, payment, closeModal, t } = this.props;

    return (
      <React.Fragment>
        <div className={ classes.title }>
          { t('Отменить транзакцию') }
        </div>
        <div style={ {
          padding: 16,
          textAlign: 'center',
          backgroundColor: `rgba(255, 157, 58, 0.25)`
        } }>
          { t('Вы действительно хотите отменить транзакцию') } <strong>#{ payment.id }</strong> ?
        </div>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 12 }>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell padding="none">{ t('Операция') }</TableCell>
                    <TableCell>{ payment.action }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="none">{ t('Описание') }</TableCell>
                    <TableCell>{ payment.comment }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="none">{ t('Сумма') }</TableCell>
                    <TableCell>{ payment.amount }</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }>
              <ButtonWithProgress onClick={ this.handleRemove }
                                  label={ 'Да' }
                                  autoFocus/>

            </FormColumn>
            <FormColumn sm={ 6 }
                        style={ {
                          display: 'flex',
                          justifyContent: 'flex-end'
                        } }>
              <Button onClick={ closeModal }
                      style={ { marginLeft: 'auto' } }
                      color="secondary">
                { t('Нет') }
              </Button>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>

    );
  }
}

let mapStateToProps = (state) => ( {
  payment: state.formDrawer.paydeskCancel.payload
} );

export default connect(mapStateToProps, { openSnackbar })
(withStyles(styles)
(withTranslation()(PaydeskPaymetCancelForm)));
