import { useSelector } from 'react-redux';
import { MANAGER, MODERATOR } from '../components/Constants';

export const usePermissions = () => {
  const user = useSelector((state) => state.auth.user);

  const isModerator = () => user.userable_type === MODERATOR;

  const isManager = () => user.userable_type === MANAGER;

  const isOther = () => !(isModerator && isManager);

  return {
    isModerator,
    isManager,
    isOther
  }
}
